<div class="login-container bg-light">
  <div class="login-box">
    <div class="container">
      <div class="card col-md-6 mt-3 mx-auto">
        <div class="card-body">
          <h4 class="card-title text-center">
            <img src="../../assets/img/CFA_CSymbol_WithDots_Red_PMS.png" height="150px">
            <div class="app-name">
              <h3 translate>APP_NAME</h3>
              <h6 class="d-inline-block">v{{version}}</h6>
            </div>
          </h4>
          <button class="btn btn-danger w-100" *ngIf="!isAuthenticated" (click)="login()">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Login</span>
          </button>
          <button class="btn btn-danger w-100" *ngIf="isAuthenticated" (click)="logout()">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Logout</span>
            </button>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <div class="card-container container">
      <div class="row">
        <div class="status-header">
          <h4 class="text-danger text-start">Submission Statuses</h4>
        </div>
        <div class="date-form-container">
          <div class="date-form">
            <!-- <div class="form-inline"> -->
            <div class="row">
              <div class="col-5">
                <select
                  data-testid="submission-statuses-month"
                  class="form-control form-select form-control"
                  [(ngModel)]="statusMonth"
                  (ngModelChange)="
                    statusMonth = $event;
                    onDateSpinnerChange(statusMonth, statusYear)
                  "
                >
                  <option *ngFor="let month of monthList; let i = index">
                    {{ month }}
                  </option>
                </select>
              </div>
              <div class="col-4">
                <select
                  data-testid="submission-statuses-year"
                  class="form-control form-select form-control"
                  [(ngModel)]="statusYear"
                  (change)="onDateSpinnerChange(statusMonth, statusYear)"
                >
                  <option
                    *ngFor="let year of yearList; let i = index"
                    [selected]="year === statusYear"
                  >
                    {{ year }}
                  </option>
                </select>
              </div>
              <div class="col-3"></div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="chart-header">
        <h5 class="text-center formatted-date-text"> {{ getFormattedDate() }} </h5>
      </div>
      <div class="status-chart">
        <app-loader [isLoading]="!isDataAvail" class="text-center"></app-loader>
        <div
          class="text-center"
          id="chart"
          [hidden]="isLoading || !isDataAvail"
        ></div>
        <!-- <div class="container-60 text-center" [hidden]="!isDataAvail">
        <h5 class="no-data">No Submission Data Available</h5>
      </div> -->
      </div>
    </div>
  </div>
</div>

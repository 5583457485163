<div class="card" data-testid="ll-card">
  <div class="card-body">
    <div class="row ll-header" data-testid="ll-header">
      <div class="col-5">
        <h3 class="text-danger ll-header-label" data-testid="ll-header-label">
          Licensee Locations
        </h3>
      </div>
      <div class="col-4 form-group date-group"></div>
      <div class="col-3">
        <input
          id="typeahead-basic"
          type="text"
          class="form-control"
          [resultFormatter]="formatLocationSearchName"
          [inputFormatter]="formatLocationSearchName"
          (selectItem)="selectTypeheadItem($event)"
          [ngbTypeahead]="search"
          placeholder="Search..."
          data-testid="ll-search-input"
        />
        <button class="btn" id="filtersubmit" data-testid="ll-search-button">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-permanent filter-row row" data-testid="ll-filter-row">
        <div class="col-7">
          <div class="row">
            <i
              class="fa fa-toggle-off filter-icon col-1"
              (click)="setPermanentlyFilter()"
              *ngIf="!displayPermanentlyClosed"
              data-testid="perm-toggle-off"
            ></i>
            <i
              class="fa fa-toggle-on filter-icon col-1"
              (click)="setPermanentlyFilter()"
              *ngIf="displayPermanentlyClosed"
              data-testid="perm-toggle-on"
            ></i>
            <p
              class="text-danger permanent-label filter-text col-11"
              data-testid="perm-label"
            >
              Display Permanently Closed Locations
            </p>
          </div>
        </div>
      </div>
      <div class="filter-temporary filter-row row">
        <div class="col-7">
          <div class="row">
            <i
              class="fa fa-toggle-off filter-icon col-1"
              (click)="setTemporarilyFilter()"
              *ngIf="!displayTemporarilyClosed"
              data-testid="temp-toggle-off"
            ></i>
            <i
              class="fa fa-toggle-on filter-icon col-1"
              (click)="setTemporarilyFilter()"
              *ngIf="displayTemporarilyClosed"
              data-testid="temp-toggle-on"
            ></i>
            <p
              class="text-danger permanent-label filter-text col-11"
              data-testid="temp-label"
            >
              Display Temporarily Closed Locations
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="row ll-table-header" data-testid="ll-table-header">
        <div class="col-3">
          <span class="table-label" (click)="this.sortReports('name')">
            Location Name</span
          >
        </div>
        <div class="col-1">
          <span class="table-label" (click)="this.sortReports('number')">
            Location Number</span
          >
        </div>
        <div class="col-2">
          <span
            class="table-label date-label"
            (click)="this.sortReports('date')"
          >
            Date Submitted</span
          >
        </div>
        <div class="col-2">
          <span class="table-label" (click)="this.sortReports('status')">
            Status</span
          >
        </div>
        <div class="col-2">
          <span class="table-label closure-label">Temp. Closure Reason</span>
        </div>
        <div class="col-2">
          <span class="table-label view-label">View</span>
        </div>
      </div>
      <div class="border-line"></div>
      <div class="ll-table-container">
        <table
          class="table table-hover borderless ll-table"
          [hidden]="!isDataAvail"
          data-testid="ll-table"
        >
          <tr
            *ngFor="let company of filteredSubmissionReports; let i = index"
            class="row ll-table-row"
            data-testid="ll-table-row"
          >
            <td
              class="ll-table-cell col-3 name-cell"
              data-testid="ll-name-cell"
            >
              {{ company.licensee.cfaLocationName }}
            </td>
            <td
              class="ll-table-cell col-1 location-cell"
              data-testid="ll-num-cell"
            >
              {{ company.licensee.cfaLocationNumber }}
            </td>
            <td
              class="ll-table-cell col-2 date-cell"
              data-testid="ll-date-cell"
            >
              {{
                company.reportingperiod.dateSubmitted
                  ? formatDate(company.reportingperiod.dateSubmitted)
                  : "N/A"
              }}
            </td>
            <td
              class="{{
                getStatusClass(company.reportingperiod.statusID)
              }} ll-status-cell col-2"
              data-testid="ll-status-cell"
            >
              <img
                src="{{ this.getImagePath(company.reportingperiod.statusID) }}"
                alt="status"
                class="{{
                  getImageClass(company.reportingperiod.statusID)
                }} status-img"
                *ngIf="company.reportingperiod.statusID != 1"
              />
              <i
                class="fas fa-edit draft-icon"
                *ngIf="company.reportingperiod.statusID == 1"
              ></i>
              {{ this.getStatus(company.reportingperiod.statusID) }}
            </td>
            <td class="ll-closure-cell col-2" data-testid="ll-closed-cell">
              <span *ngIf="company.licensee.tempCloseReason">
                {{ company.licensee.tempCloseReason }}
              </span>
            </td>
            <td class="ll-view-cell col-2" data-testid="ll-view-cell">
              <button
                class="view-button"
                (click)="
                  impersonateLicensee(
                    company.licensee.licenseeId,
                    company.licensee.cfaLocationName,
                    company.licensee.cfaLocationNumber,
                    company.licensee.countryCode,
                    company.licensee.catering,
                    company.licensee.giftCards,
                    company.licensee.allowsAdjustments
                  )
                "
              >
                <img
                  src="/assets/img/icons/eye.png"
                  alt="view"
                  class="eye-icon"
                />View
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div
        class="no-locations-container"
        [hidden]="isDataAvail"
        data-testid="no-locations-message"
      >
        <h5 class="no-locations-text">No Locations To Review</h5>
      </div>
    </div>
  </div>
</div>

import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { Logger } from '../logger.service';
import { AuthenticationService } from './authentication.service';

import { OktaAuthService } from '@okta/okta-angular';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate, OnInit {
isAuthenticated: boolean;
  constructor(
    private oktaAuth: OktaAuthService,
    private router: Router) {
      //this.isAuthenticated = true;
      this.getAuthState(oktaAuth);
      this.oktaAuth.$authenticationState.subscribe(
        (isAuthenticated: boolean)  => this.isAuthenticated = isAuthenticated
      );
    }
async getAuthState(oktaAuth: OktaAuthService) {
  this.isAuthenticated = await oktaAuth.isAuthenticated();
}
    async ngOnInit() {
    }
  async canActivate() {
    console.error('7474747474747 ' + this.isAuthenticated);
    if (this.isAuthenticated === true) {
      console.log('Authenticated');
      return true;
    }

    log.debug('Not authenticated, redirecting...');
     this.router.navigate(['/login'], { replaceUrl: true });
    return false;
  }

}

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataValidationLog } from "@app/model/DataValidationLog.model";
import { LicenseeSearch } from "@app/model/LicenseeSearch.model";
import { ShortWarningLog } from "@app/model/ShortWarningLog.model";
import { SubmissionReport } from "@app/model/SubmissionReport.model";
import { Summary } from "@app/model/Summary.model";
import { monthList } from "@app/model/monthList";
import { LicenseeService } from "@app/services/licensee.service";
import { cloneDeep } from "lodash";
import { debounceTime, lastValueFrom, Subject, SubscriptionLike } from "rxjs";
import { CurrentDate } from "@app/model/CurrentDate.model";

@Component({
  selector: "app-cfa-pending",
  templateUrl: "./cfa-pending.component.html",
  styleUrls: ["./cfa-pending.component.scss"],
})
export class CFAPendingComponent implements OnInit, OnDestroy {
  @Input()
  isLoading: boolean;
  @Input()
  isReadOnlyAdmin: boolean;
  isDataAvail: boolean = false;
  isModalActive: boolean = false;
  private selectedSubmissionReportSubscription: SubscriptionLike;
  private dataValidationLogSubcription: SubscriptionLike;
  private submissionReportDateSubscription: SubscriptionLike;

  errorMessage: string;
  successMessage: string;
  private _success = new Subject<string>();
  private _error = new Subject<string>();

  submissionReports: Array<SubmissionReport>;
  warningSubmissionReports: Array<SubmissionReport>;
  shortWarningLogs: Array<ShortWarningLog> = [];
  licenseeSearch = new LicenseeSearch();
  licenseeSummaryData: Summary;

  selectedReport: SubmissionReport;
  selectedSubmission: SubmissionReport;
  selectedWarnings: Array<ShortWarningLog> = [];
  submissionLogs: Array<DataValidationLog>;
  status: string = "";
  salesDocuments: Array<any> = [];
  adjustmentDetails: any;
  monthlySales = "";
  denialReason: number;
  currency = {};
  currentDate: any;
  denialModalBackdrop: HTMLElement;

  defaultWarningDetails = {
    licenseeName: "",
    licenseeNumber: "",
    currentDate: "",
    amount: "",
    originalAmount: "",
    newAmount: "",
    contactName: "",
    contactEmail: "",
    reason: "",
    warningList: [""]
  };
  warningDetails: any = this.defaultWarningDetails;

  reasonsList = [
    "Incomplete Sales",
    "Miscalculated Sales",
    "POS Report vs. Portal Difference",
    "Other",
  ];

  approvalModalDiv: HTMLElement;
  reasonModalDiv: HTMLElement;
  approvalModal2: HTMLElement;
  modalBackdrop: HTMLElement;

  formModal: any;

  constructor(
    private licenseeService: LicenseeService,
    private router: Router
  ) {}

  ngOnInit() {
    this._success.subscribe((message) => (this.successMessage = message));
    this._success
      .pipe(debounceTime(3000))
      .subscribe(() => (this.successMessage = null));

    this._error.subscribe((message) => (this.errorMessage = message));
    this._error
      .pipe(debounceTime(3000))
      .subscribe(() => (this.errorMessage = null));
    this.isDataAvail = false;

    this.submissionReportDateSubscription =
      this.licenseeService.submissionReportDateObservable.subscribe(
        (dateData) => {
          this.currentDate = dateData;
            this.selectedSubmissionReportSubscription =
            this.licenseeService.selectedSubmissionReportObservable.subscribe(
              (payload) => {
                this.shortWarningLogs = [];
                if (payload === null) {
                } else {
                  this.submissionReports = payload;
                  this.warningSubmissionReports = cloneDeep(payload).filter(
                    (company) => {
                      return company.reportingperiod.warnings > 0;
                    }
                  );
                  this.warningSubmissionReports.forEach((report) => {
                    if (report.reportingperiod.statusID != 1 && report.log.length) {
                        let reportRuleList: string[] = [];
                        report.log.forEach((log) => {
                          if (!reportRuleList.includes(log.rule)) {
                          const newShortLog: ShortWarningLog = {
                            cfaLocationNumber: report.licensee.cfaLocationNumber,
                            dateSubmitted: report.reportingperiod.dateSubmitted,
                            logRule: log.rule,
                            ruleId: log.ruleId,
                          };
                          this.shortWarningLogs.push(newShortLog);
                          reportRuleList.push(log.rule);
                        }
                        });
                  }
                  });

                  this.sortByDate();
                }
                this.isDataAvail = true;
              }
            );
        }
      );

    if (this.submissionReports.length) {
      this.isDataAvail = !!this.submissionReports.length;
    }

    this.approvalModalDiv = document.getElementById("approvalModal");
    this.reasonModalDiv = document.getElementById("reasonModal");
    this.denialModalBackdrop = document.getElementById("denialModalBackdrop");
    this.denialModalBackdrop.style.display = "none";

    this.currency = this.licenseeService.currency;

    this.dataValidationLogSubcription =
      this.licenseeService.dataValidationLogObservable.subscribe((payload) => {
        if (payload === null) {
          this.selectedSubmission = new SubmissionReport();
        } else {
          this.selectedSubmission = payload;
          this.submissionLogs = payload.log;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.dataValidationLogSubcription !== undefined) {
      this.dataValidationLogSubcription.unsubscribe();
    }
    if (this.selectedSubmissionReportSubscription !== undefined) {
      this.selectedSubmissionReportSubscription.unsubscribe();
    }
    if (this.dataValidationLogSubcription !== undefined) {
      this.dataValidationLogSubcription.unsubscribe();
    }
  }

  formatDate(date: string) {
    const dateArray = date.split("-");
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  }

  getWarningClass(ruleId: number) {
    if (ruleId == 7) {
      return "blue-text";
    } else {
      return "red-text";
    }
  }

  sortByDate() {
    // separate logs with no date
    let logsNoDate: Array<ShortWarningLog> = [];
    let logsWithDate: Array<ShortWarningLog> = [];
    this.shortWarningLogs.forEach((log) => {
      if (isNaN(Date.parse(log.dateSubmitted))) {
        logsNoDate.push(log);
      } else {
        logsWithDate.push(log);
      }
    });
    // sort with most recent first
    logsWithDate.sort(
      (a, b) => Date.parse(a.dateSubmitted) - Date.parse(b.dateSubmitted)
    );
    this.shortWarningLogs = logsWithDate.concat(logsNoDate);
  }

  // Approval Modal

  openApprovalModal(log: ShortWarningLog) {
    if (this.approvalModalDiv != null) {
      this.approvalModalDiv.style.display = "block";
      this.licenseeService.setIsModalActive(true);
    }

    this.selectedReport = this.warningSubmissionReports.find(
      (report) => report.licensee.cfaLocationNumber == log.cfaLocationNumber
    );

    if (this.selectedReport) {
      this.getAdjustmentDetails().then((data) => {});

      this.getSummary();
      if (log.ruleId == 7) {
        this.getAdjustmentDetails();
      }
      this.setWarningDetails(log.ruleId);
    }
  }

  closeApprovalModal() {
    if (this.approvalModalDiv != null) {
      this.approvalModalDiv.style.display = "none";
      this.licenseeService.setIsModalActive(false);
    }
  }

  setWarningDetails(ruleId: number) {
    this.warningDetails.licenseeName =
      this.selectedReport.licensee.cfaLocationName;
    this.warningDetails.licenseeNumber =
      this.selectedReport.licensee.cfaLocationNumber;
    this.warningDetails.currentDate =
      monthList[this.selectedReport.reportingperiod.calendarMonth - 1] +
      " " +
      this.selectedReport.reportingperiod.year;
    if (ruleId == 7) {
      if (this.selectedReport.reportingperiod.contactEmail) {
        this.warningDetails.contactEmail =
          this.selectedReport.reportingperiod.contactEmail.valueOf();
      }
      if (this.selectedReport.reportingperiod.contactName) {
        this.warningDetails.contactName =
          this.selectedReport.reportingperiod.contactName.valueOf();
      }
      if (this.selectedReport.reportingperiod.reasonForAdjustment) {
        this.warningDetails.reason =
          this.selectedReport.reportingperiod.reasonForAdjustment;
      }
    }
  }

  setLicenseeSearch() {
    this.licenseeSearch.licenseeid = this.selectedReport.licensee.licenseeId;
    this.licenseeSearch.month = this.licenseeService.currentDate.month;
    this.licenseeSearch.year = this.licenseeService.currentDate.year;
  }

  async getAdjustmentDetails() {
    const reportingPeriodId =
      this.selectedReport.reportingperiod.reportingPeriodId;
    const locationNumber = Number(
      this.selectedReport.licensee.cfaLocationNumber
    );
    this.selectedWarnings = this.shortWarningLogs.filter(
      (log) => log.cfaLocationNumber == locationNumber.toString()
    );
    this.licenseeService
      .getAdjustmentDetails(reportingPeriodId, locationNumber)
      .subscribe((payload) => {
        if (payload[0]) {
          const adjustmentDetails = payload[0];
          this.warningDetails.originalAmount =
            adjustmentDetails.previousSalesTotal.toLocaleString("en-US");
          this.warningDetails.newAmount =
            adjustmentDetails.currentSalesTotal.toLocaleString("en-US");
          this.warningDetails.contactName =
            adjustmentDetails.adjustmentContactName;
          this.warningDetails.contactEmail =
            adjustmentDetails.adjustmentContactEmail;
          this.warningDetails.reason = adjustmentDetails.reasonForAdjustment;
        }
      });

    this.licenseeService
      .downloadAttachment({
        licenseeid: this.selectedReport.licensee.licenseeId,
        month: this.selectedReport.reportingperiod.calendarMonth,
        year: this.selectedReport.reportingperiod.year,
        statusid: 1,
      })
      .subscribe({
        next: (payload: any[]) => {
          this.salesDocuments = payload;
        },
        error: (e) => {
          console.log(e);
        },
      });
  }

  getSummary() {
    this.setLicenseeSearch();

    this.licenseeService
      .getSummaries(this.licenseeSearch)
      .subscribe((results: Summary) => {
        this.isLoading = false;
        this.licenseeSummaryData = results;
        this.licenseeService.setLicenseeSummariesData(results);
        this.warningDetails.amount =
          results.monthlySales.toLocaleString("en-US");
      });
  }

  updateSubmissionStatus(status?: number, reason?: number) {

    const licenseeSearch = new LicenseeSearch();
    licenseeSearch.licenseeid = this.selectedReport.licensee.licenseeId;
    licenseeSearch.month = this.selectedReport.reportingperiod.calendarMonth;
    licenseeSearch.year = this.selectedReport.reportingperiod.year;
    if (reason) {
      licenseeSearch.denialReason = reason;
    }
    if (status) {
      licenseeSearch.statusid = status;
    }

    this.licenseeService.postSubmissionStatus(licenseeSearch).subscribe(
      (res) => {
        if (res) {
          this.changeSuccessMessage("Submission status updated.");
          this.licenseeService
            .getSubmissionCounts(this.licenseeService.currentDate)
            .subscribe((res: Array<SubmissionReport>) => {
              this.licenseeService.selectedStatus = "";
              this.warningDetails = this.defaultWarningDetails;
              this.status = "";
              this.selectedSubmission = new SubmissionReport();
              this.selectedReport = new SubmissionReport();
              this.selectedWarnings = new Array<ShortWarningLog>();
              this.licenseeService.setSubmissionReport(res);
            });
        } else {
          this.changeErrorMessage(
            "There was an issue updating the status. Please refresh and try again."
          );
        }
      },
      (err) => {
        console.log(err);
        this.changeErrorMessage(
          "There was an issue updating the status. Please refresh and try again."
        );
      }
    );

    const currentDate = new CurrentDate();
    currentDate.month = this.licenseeSearch.month;
    currentDate.year = this.licenseeSearch.year;
    this.licenseeService.setSubmissionReportDate(currentDate);
    this.closeApprovalModal();
  }

  async downloadDocument(document: any) {
    if (document.isDownloading) {
      return;
    }
    try {
      document.isDownloading = true;
      const downloadFileURL = await this.getDownloadFileUrl(document);
      this.licenseeService.downloadAttachmentFromS3(
        downloadFileURL,
        document.fileName
      );
    } catch (e) {
      /* do nothing */
    }
    document.isDownloading = false;
  }

  async viewDocument(document: any) {
    if (document.isViewing) {
      return;
    }
    try {
      document.isViewing = true;
      const downloadFileURL = await this.getDownloadFileUrl(document);
      window.open(downloadFileURL);
    } catch (e) {
      /* do nothing */
    }
    document.isViewing = false;
  }

  async getDownloadFileUrl(document: any): Promise<string> {
    return (await lastValueFrom(
      this.licenseeService.getDownloadUrl(
        document.uniqueKey,
        this.selectedReport.licensee.licenseeId
      )
    )) as string;
  }

  getFormattedDate() {
    this.warningDetails.currentDate =
      monthList[this.selectedReport.reportingperiod.calendarMonth - 1] +
      " " +
      this.selectedReport.reportingperiod.year;
  }

  changeSuccessMessage(message: string) {
    this._success.next(`${message}`);
  }

  changeErrorMessage(message: string) {
    this._error.next(`${message}`);
  }

  // Reason Modal

  handleDeny() {
    if (this.selectedReport.log.some((logItem) => logItem.ruleId == 7)) {
      if (this.reasonModalDiv != null) {
        this.reasonModalDiv.style.display = "block";
        this.denialModalBackdrop.style.display = "block";
      }
    } else {
      this.updateSubmissionStatus(1);
    }
  }

  closeReasonModal() {
    if (this.reasonModalDiv != null) {
      this.reasonModalDiv.style.display = "none";
      this.denialModalBackdrop.style.display = "none";
    }
  }

  submitDenialReason() {
    this.updateSubmissionStatus(1, Number(this.denialReason));
    this.closeReasonModal();
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { LicenseeService } from "../services/licensee.service";
import {saveFile} from '@app/helpers/file-download-helper';
import {lastValueFrom} from 'rxjs';
import {MessageService} from 'primeng/api';

@Component({
  selector: "app-cfa-oracle",
  templateUrl: "./cfa-oracle.component.html",
  styleUrls: ["./cfa-oracle.component.scss"]
})
export class CfaOracleComponent implements OnInit {
  //Retrieving variables from Licensee parent component
  @Input()
  isLoading: boolean; //Boolean to see if data has been returned

  isExportComplete: boolean = false;
  isRevisedComplete: boolean = false;
  isDailyComplete: boolean = false;
  rangeDates: Date[];

  constructor(private licenseeService: LicenseeService, private messageService: MessageService) {}

  ngOnInit() {
    this.isExportComplete = true;
    this.isRevisedComplete = true;
    this.isDailyComplete = true;
  }

  downloadExportFile() {
    this.isExportComplete = false;
    this.getExportFormat();
  }

  downloadAdjFile(){
    this.isRevisedComplete = false;
    this.getExportRevised();
  }

  downloadDailyFile() {
    this.isDailyComplete = false;
    this.getDailyReport();
  }

  async getExportFormat() {
    try{
      const res = (await lastValueFrom(this.licenseeService.downlaodExportFormat(this.licenseeService.currentDate))) as Blob;
      saveFile(res, 'MonthlySales.xlsx');
    } catch(e) {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'There is an error in downloading in the file.'});
    }
    this.isExportComplete = true;
  }

  getExportRevised() {
    if (this.rangeDates !== undefined && this.rangeDates.length === 2
        && this.rangeDates[0] != null && this.rangeDates[1] != null) {
        this.licenseeService.downloadRevisedFormat(this.rangeDates[0], this.rangeDates[1]).subscribe((res: any) => {
          saveFile(res, 'AdjustedSales.xlsx');
          this.isRevisedComplete = true;
        });
    }
  }

  getDailyReport() {
    this.licenseeService.downloadDailyReport().subscribe((res: any) => {
      const date = new Date();
      saveFile(res, `daily_report_${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}.xlsx`);
        this.isDailyComplete = true;
      },
        error => {
          this.isDailyComplete = true;
    });
  }
}

<div class='card'>
    <div class='card-body'>
        <div class='text-center'>
            <h5 data-testid="txt-fs-director" class='text-danger'>Food Service Director</h5>
        </div>

        <div class='container-90 text-center'>
            <p class="small">Please enter the name and email of the Food Service Director.</p>
        </div>

        <app-loader [isLoading]="isLoading" class="text-center"></app-loader>
        <div [hidden]="isLoading" class='director-body text-center'>
            <div class="form-group row">
                <div class="col-sm-2 label-container">
                    <label class="col-sm-form-label">Name:</label>
                </div>
                <div class="col-sm-9 input-container">
                    <input data-testid="input-fs-name" class="form-control {{ nameError && nameTouched ? 'input-error' : '' }} directorName"
                           type="text" maxlength="50" [(ngModel)]="name" (input)="updateDirectorInfo()" (focus)="onFocus(1)"
                           pattern="/^[a-zA-Z- ]*$/" required [disabled]=isReportingPeriodLocked>
                </div>
                <div class="col-sm-1 validation-check text-left">
                    <i *ngIf="!nameError" data-testid="chk-fs-name-success" class="fas fa-check-circle text-success"></i>
                    <i *ngIf="nameError && nameTouched" data-testid="chk-fs-name-missing" class="fas fa-times-circle text-danger"></i>
                </div>
            </div>

            <div class="form-group row">
                    <div class="col-sm-2 label-container">
                        <label class="col-sm-form-label">Email:</label>
                    </div>
                    <div class="col-sm-9 input-container">
                        <input data-testid="input-fs-email" class="form-control {{ emailError && emailTouched ? 'input-error' : '' }} directorEmail" type="text" maxlength="50" [(ngModel)]="email" (input)="updateDirectorInfo()"
                            (focus)="onFocus(2)" pattern="[a-zA-Z.\-_0-9]+@[a-zA-Z.\-0-9]+[a-zA-Z0-9]+\.[a-zA-Z]{1,9}$" required [disabled]=isReportingPeriodLocked>
                    </div>
                    <div class="col-sm-1 validation-check text-left">
                        <i *ngIf="!emailError" data-testid="chk-fs-email-success" class="fas fa-check-circle text-success"></i>
                        <i *ngIf="emailError && emailTouched" data-testid="chk-fs-email-missing" class="fas fa-times-circle text-danger"></i>
                    </div>
                </div>
        </div>

    </div>
</div>

import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { LicenseeService } from "../services/licensee.service";
import { Guid } from "guid-typescript";
import {lastValueFrom, SubscriptionLike} from 'rxjs';
import { environment } from "@env/environment";
import {cloneDeep, remove} from 'lodash';
import {AuthenticationService} from '@app/core';
import {LicenseeSearch} from '@app/model/LicenseeSearch.model';

const env = environment as any;

@Component({
  selector: "app-licensee-upload",
  templateUrl: "./licensee-upload.component.html",
  styleUrls: ["./licensee-upload.component.scss"]
})
export class LicenseeUploadComponent implements OnInit, OnDestroy {
  private licenseeAttachmentSubscription: SubscriptionLike;
  private licenseeSearchSubscription: SubscriptionLike;
  licenseeSearch = new LicenseeSearch();
  uploadedAttachments: any;
  isReadOnlyAdmin: Boolean = false
  UPLOAD_STATUSES = {
    ERROR:'ERROR',
    SUCCESS:'SUCCESS',
    WARNING:'WARNING',
    PENDING:'PENDING',
    UPLOADING:'UPLOADING'
  };
  uploadStatus: string = this.UPLOAD_STATUSES.PENDING
  s3URL = env.s3Url;
  MAX_FILE_SIZE = 250000000;

  @ViewChild("fileInput", { static: true })
  fileInput: ElementRef;
  isLoading: boolean = false;
  message: string = '';
  pendingAttachments: Array<any> = [];

  constructor(public licenseeService: LicenseeService,  public authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.licenseeService.uploadComplete = false;
    this.licenseeService.uploadGoodToSubmit = true;

    // Subscription to LicenseeAttachment Observable in parent
    this.licenseeAttachmentSubscription = this.licenseeService.licenseeAttachmentObservable
      .subscribe(attachments => {
        this.licenseeSearch.licenseeid = this.licenseeService.licenseeSearch.licenseeid;
        this.licenseeSearch.month = this.licenseeService.currentDate.month;
        this.licenseeSearch.year = this.licenseeService.currentDate.year;
        if (!attachments || !attachments.length) {
            this.uploadedAttachments = [];
            this.clearData();
        } else {
          this.uploadedAttachments = cloneDeep(attachments);
          this.licenseeService.uploadComplete = true;
          this.licenseeService.uploadGoodToSubmit = true;
        }
        this.uploadStatus = this.UPLOAD_STATUSES.PENDING;
      });
      this.licenseeService.adminReadOnlyObservable.subscribe(payload => {
        this.isReadOnlyAdmin = payload;
      })
  }

  ngOnDestroy() {
    if (this.licenseeAttachmentSubscription !== undefined) {
      this.licenseeAttachmentSubscription.unsubscribe();
    }
  }

  clearData() {
    this.licenseeService.clearUploadData();
    this.uploadStatus = this.UPLOAD_STATUSES.PENDING;
    this.licenseeService.uploadGoodToSubmit = false;
  }

  generateGUID() {
    return Guid.raw();
  }

  updateFileObj(file: any){
    file.fileName = file.name;
    file.reportingPeriodId = this.licenseeService.reportingPeriod.reportingPeriodId;
    file.uniqueKey = this.generateGUID();
    file.url = this.s3URL + file.uniqueKey;
    file.isPending = true;
  }

  async deleteAttachment(attachment: any){
    if(attachment.isPending){
      remove(this.pendingAttachments, ({uniqueKey})=>uniqueKey === attachment.uniqueKey);
    } else {
      try{
        attachment.isLoading = true;
        await lastValueFrom(this.licenseeService.deleteOneAttachment(attachment, this.licenseeService.licenseeSearch.licenseeid));
        await this.getUploadedFiles();
        this.setSuccess(`File - ${attachment.fileName} deleted successfully.`);
      } catch(e){
        this.setError(`Error in deleting the file - ${attachment.fileName}.`);
      }
      attachment.isLoading = false;
    }
  }

  previewFile() {
    if (this.licenseeService.reportingPeriod.reportingPeriodId === 0) {
      this.clearData();
      this.setWarning("Please save your data before attempting a file upload.");
    } else {
      const files = [...this.fileInput.nativeElement.files];
      if (!this.validateFile(files)) {
        return;
      }
      for(const file of files){
        this.updateFileObj(file);
        const isFileExistsInList = this.pendingAttachments.find(({fileName})=>fileName === file.fileName);
        if(!isFileExistsInList){
          this.pendingAttachments.push(file);
        }
        this.uploadStatus = this.UPLOAD_STATUSES.PENDING;
      }
    }
  }

  getIsReportingPeriodDisabled(){
    return (this.licenseeService.reportingPeriod != null &&
        this.licenseeService.reportingPeriod.exempt) || this.licenseeService.reportingPeriodLocked;
  }

  getIsUploadInputDisabled(){
    return this.getIsReportingPeriodDisabled() || this.uploadStatus === this.UPLOAD_STATUSES.UPLOADING || this.isReadOnlyAdmin;
  }

  setError(errorMessage: string){
    this.uploadStatus = this.UPLOAD_STATUSES.ERROR;
    this.message = errorMessage;
    this.isLoading = false;
    this.licenseeService.uploadGoodToSubmit = false;
  }

  setSuccess(successMsg: string){
    this.uploadStatus = this.UPLOAD_STATUSES.SUCCESS;
    this.message = successMsg;
    this.isLoading = false;
    this.licenseeService.uploadComplete = true;
    this.licenseeService.uploadGoodToSubmit = true;
    this.licenseeService.adminDeleteAttachment = false;
  }

  setWarning(warningMsg: string){
    this.uploadStatus = this.UPLOAD_STATUSES.WARNING;
    this.message = warningMsg;
  }

  validateFile(files: Array<any>) {
    if(!files || !files.length){
      return false;
    }
    const filesWithWarning = [];
    for(const file of files){
      if(file.size > this.MAX_FILE_SIZE){
        filesWithWarning.push(file);
      }
    }

    if(filesWithWarning.length){
      const fileNames = filesWithWarning.map(({name})=>name).toString();
      this.uploadStatus = this.UPLOAD_STATUSES.WARNING;
      this.setWarning(`File${filesWithWarning.length > 1 ? 's':''} ${fileNames} cannot be exceed 250 MB in size.`);
      return false;
    }
    return true;
  }

  async uploadFilesToDB(){
    try{
      const attachments = JSON.parse(JSON.stringify(this.pendingAttachments));
      await lastValueFrom(this.licenseeService.postAttachments(attachments));
    } catch(e) {
      throw new Error(`Error in updating files in DB.`);
    }
  }

  async getS3UploadURLs(){
    try {
      const uploadFileUniqueKeys = this.pendingAttachments
          .map(({uniqueKey}) => uniqueKey)
          .toString();
      const response: any = await lastValueFrom(this.licenseeService.getUploadUrl(
          uploadFileUniqueKeys, this.licenseeService.licenseeSearch.licenseeid
      ));
      return response;
    } catch(e){
      throw new Error("There was an error in getting s3 upload url.");
    }
  }

  async uploadFilesToS3(response: any){
    for(const fileResponse of response){
      const {signedUrl, fileName: uniqueKeyFromResponse } = fileResponse;
      const file = this.pendingAttachments.find(({uniqueKey})=>uniqueKeyFromResponse === uniqueKey);
      try {
        await lastValueFrom(this.licenseeService.uploadAttachmentToS3(signedUrl, file));
      } catch(e){
        throw new Error(`fileName: ${file.fileName}`);
      }
    }
  }

  async getUploadedFiles(){
    const attachments: any = await lastValueFrom(
        this.licenseeService.downloadAttachment(this.licenseeSearch)
    );
    const value = attachments && attachments.length ? attachments : [];
    this.licenseeService.setLicenseeAttachment(value);
  }

  async postLicenseeAttachment() {
    try {
      this.uploadStatus = this.UPLOAD_STATUSES.UPLOADING;
      this.isLoading = true;
      await this.uploadFilesToDB();
      const response = await this.getS3UploadURLs();
      await this.uploadFilesToS3(response);
      await this.getUploadedFiles();
      this.setSuccess(
        this.pendingAttachments.length > 1 ?
        'The files were uploaded.':'The file was uploaded.'
      );
      this.pendingAttachments = [];
    } catch(e){
      const message = e.message || '';
      let fileName = '';
      if(message.indexOf('fileName: ') > -1){
        fileName = message.replace('fileName: ', '');
      }
      this.setError(`There was an error uploading your file${!fileName ? 's': ` - ${fileName}`}. Please save and try again.`);
      throw e;
    }
  }

  async downloadFile(attachment: any) {
    if(attachment.isPending){
      return false;
    }
    attachment.isLoading = true;
    try{
      const downloadFileURL = await lastValueFrom(this.licenseeService.getDownloadUrl(
          attachment.uniqueKey,
          this.licenseeService.licenseeSearch.licenseeid
      ));
      this.licenseeService.downloadAttachmentFromS3(downloadFileURL, attachment.fileName);
    } catch(e){
      this.setError(`Error in downloading the file ${attachment.fileName}`);
    }
    attachment.isLoading = false;
  }

  getDeleteButtonEnabled() {
    if (this.authenticationService.credentials.isAdmin) {
      return true;
    } else if (this.licenseeService.reportingPeriod.statusID == 1 || this.licenseeService.reportingPeriod.statusID == 0) {
      return true;
    }
    return false;
  }
}

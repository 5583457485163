export class ThirdPartySales{

    thirdPartySaleId: number;
    licenseeId: number;
    value: number;
    productId: number;
    reportingPeriodId: number;
    productName: string;

    constructor(licenseeId:number,productId:number) {
        this.thirdPartySaleId = null;
        this.licenseeId = licenseeId;
        this.value = null;
        this.productId = productId;
        this.reportingPeriodId = null;
        this.productName = '';
    }
}



<div data-testid="card-sales-summary" class="card">
  <div class="card-body">

    <div class="summary-chart">
      <h5 class="text-danger text-center">Sales Summary</h5>
      <app-loader [isLoading]="isLoading" class="text-center"></app-loader>
      <div class="text-center" id="chart" [hidden]="isLoading"></div>
    </div>

    <div class="summary-info container">
      <h5 data-testid="txt-summary-info-sales" class="text-danger text-center">Sales</h5>
      <p class="small text-center">All totals include POS Daily Sales + Third Party Mobile Sales</p>
      <div class="row text-center yearly">
        <div class="col-sm-6">
          <h6>Year to Date:</h6>
          <span data-testid="txt-ytd-sales">{{licenseeSummaryData.yearToDateTotal | currency:currency.iso }} </span>
        </div>
        <div class="col-sm-6">
          <h6>Quarterly Sales:</h6>
          <span data-testid="txt-qtr-sales">{{licenseeSummaryData.quarterlySales | currency:currency.iso }} </span>
        </div>
      </div>

      <h5 class="text-center">Current</h5>

      <div class="row current">
        <div class="col-sm-6 text-end current-label">
          <span>POS Daily Sales:</span>
        </div>
        <div class="col-sm-6 text-end current-amount">
          <span data-testid="txt-cur-pos-daily">{{(licenseeSummaryData.monthlySales - licenseeSummaryData.thirdPartySales) | currency:currency.iso }}</span>
        </div>

        <div class="col-sm-6 text-end current-label">
          <span>Third Party Sales:</span>
        </div>
        <div class="col-sm-6 text-end current-amount">
          <span data-testid="txt-cur-third-party">{{licenseeSummaryData.thirdPartySales | currency:currency.iso }}</span>
        </div>

        <div class="col-sm-12 text-center">
          <div class="line"></div>
        </div>

        <div class="col-sm-6 text-end current-label">
          <span>Total:</span>
        </div>
        <div class="col-sm-6 text-end current-amount">
          <span data-testid="txt-cur-total">{{licenseeSummaryData.monthlySales | currency:currency.iso }}</span>
        </div>

        <div *ngIf="!isInternational" class="col-sm-6 text-end current-label">
          <span>Fee Due:</span>
        </div>

        <div *ngIf="!isInternational" class="col-sm-6 text-end current-amount">
          <span class="text-danger"><strong data-testid="txt-cur-fee-due">{{licenseeSummaryData.fiscalFee | currency:currency.iso }}</strong></span>
        </div>
      </div>
    </div>
    <div class="food-service-provider text-center">
      <span data-testid="txt-service-provider">Current Food Service Provider: {{ foodServiceProvider ? foodServiceProvider : "N/A" }} </span>
    </div>
    <div class="status text-center">
      <strong>Current Status: </strong>
      <span data-testid="txt-cur-status">{{getStatus()}}</span>
      <i class="fas" [ngClass]="getIconClass()"></i>
    </div>
  </div>
</div>

<div class="success-message-modal-container">
  <div class="close-button-container">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>
  <div class="title-container">
    <h1>{{title}}</h1>
  </div>
  <div class="message-container">
    {{message}}
  </div>
  <div class="success-icon-container">
    <i class="fas fa-check-circle"></i>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { LicenseeService } from "@app/services/licensee.service";
import { ReportingPeriod } from "@app/model/ReportingPeriod.model";
import { LicenseeSearch } from "@app/model/LicenseeSearch.model";

@Component({
  selector: 'app-licensee-admin-control',
  templateUrl: './licensee-admin-control.component.html',
  styleUrls: ['./licensee-admin-control.component.scss']
})

export class LicenseeAdminControlComponent implements OnInit {
  exempt = false;
  isLoading = true;

	constructor(private licenseeService: LicenseeService) { }

	ngOnInit() {
		this.licenseeService.reportingPeriodObservable.subscribe(payload => {
      this.exempt = false;
			
			if(payload !== null) {
        this.exempt = payload.exempt;
        this.updateExemptStatus();
        this.isLoading = false;
      }
    });
  }


  updateExemptStatus(event?: any) {
    if(event !== undefined) {
      this.exempt = event;
    }
    this.licenseeService.reportingPeriod.exempt = this.exempt;
  }


  // Reset the statusID to Draft, all dailySales and thirdPartySales to zero
  resetReportingPeriod() {
    this.licenseeService.reportingPeriod.statusID = 1;
    
    if(this.licenseeService.reportingPeriod.dailySales && this.licenseeService.reportingPeriod.dailySales.length > 0) {
      this.licenseeService.reportingPeriod.dailySales.forEach(ds => {
        ds.salesAmount = 0;
      });
    }

    if(this.licenseeService.reportingPeriod.thirdPartySales && this.licenseeService.reportingPeriod.thirdPartySales.length > 0) {
      this.licenseeService.reportingPeriod.thirdPartySales.forEach(tps => {
        tps.value = 0;
      });
    }

    this.licenseeService.clearUploadData();
    this.licenseeService.adminDeleteAttachment = true;
  }
}

import { Component, OnInit } from '@angular/core';
import { LicenseeService } from "../services/licensee.service";
import { ReportingPeriod } from "@app/model/ReportingPeriod.model";
import { LicenseeSearch } from "@app/model/LicenseeSearch.model";

@Component({
  selector: 'app-licensee-contact',
  templateUrl: './licensee-contact.component.html',
  styleUrls: ['./licensee-contact.component.scss']
})

export class LicenseeContactComponent implements OnInit {
  name: String;
  email: String;
  nameError: boolean = true;
  emailError: boolean = true;
  nameTouched: boolean = false;
  emailTouched: boolean = false;
  isLoading: boolean = true;

  constructor(private licenseeService: LicenseeService) { }

  ngOnInit() {
    //Subscription to ReportingPeriod Observable in parent
    this.licenseeService.reportingPeriodObservable.subscribe(payload => {
      this.name = "";
      this.email = "";
      this.nameTouched = false;
      this.emailTouched = false;

      if(payload !== null) {
        if(payload.contactName && payload.contactName != null && payload.contactName.length > 0) {
          this.name = payload.contactName;
        }
        if(payload.contactEmail && payload.contactEmail != null && payload.contactEmail.length > 0) {
          this.email = payload.contactEmail;
        }
        this.updateContactInfo();

        // if name and email are still empty, get previous month's reporting period and try to grab that Contact name/email
        if(this.name.length < 1 && this.email.length < 1) {
          console.log('getting previous months contact details')
          let licenseeSearch = new LicenseeSearch();
          licenseeSearch.licenseeid = this.licenseeService.licenseeInfo.licenseeId;
          if(this.licenseeService.currentDate.month == 1) { // if January..
            licenseeSearch.month = 12; // december
            licenseeSearch.year = this.licenseeService.currentDate.year - 1; // previous year
          } else {
            licenseeSearch.month = this.licenseeService.currentDate.month - 1; //previous month
            licenseeSearch.year = this.licenseeService.currentDate.year;
          }

          this.licenseeService.getData(licenseeSearch).subscribe((payload: ReportingPeriod) => {
            if(payload !== null) {
              if(payload.contactName && payload.contactName != null && payload.contactName.length > 0) {
                this.name = payload.contactName;
              }
              if(payload.contactEmail && payload.contactEmail != null && payload.contactEmail.length > 0) {
                this.email = payload.contactEmail;
              }
              this.updateContactInfo();
            }
          });
        }
      }
      this.isLoading = false;
    });
  }

  updateContactInfo() {
    let nameRegex = /^[a-zA-Z- ]*$/;
    let emailRegex = /^[a-zA-Z.\-_0-9]+@[a-zA-Z.\-0-9]+[a-zA-Z0-9]+\.[a-zA-Z]{1,9}$/;

    if(this.name && this.name != null && this.name.length > 0) {
      if(this.name.match(nameRegex) != null) { // check name pattern
        this.nameError = false;
      } else {
        this.nameError = true;
      }
    } else {
      this.nameError = true;
    }
    
    if(this.email && this.email != null && this.email.length > 0) {
      if(this.email.match(emailRegex) != null) { // check email pattern
        this.emailError = false;
      } else {
        this.emailError = true;
      }
    } else {
      this.emailError = true;
    }

    if(!this.nameError && !this.emailError) {
      this.licenseeService.reportingPeriod.contactName = this.name;
      this.licenseeService.reportingPeriod.contactEmail = this.email;
      this.licenseeService.contactGoodToSubmit = true;
    } else {
      this.licenseeService.contactGoodToSubmit = false;
    }

    console.log("contactGoodToSubmit:", this.licenseeService.contactGoodToSubmit);
  }

  onFocus(a: number) {
    switch(a) {
      case 1: this.nameTouched = true; break;
      case 2: this.emailTouched = true; break;
    }
  }

  get isReportingPeriodLocked(): boolean {
    return this.licenseeService.reportingPeriodLocked;
  }
}

import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChildren,
  QueryList,
  ElementRef,
} from "@angular/core";
import { LicenseeService } from "../services/licensee.service";
import {
  AdditionalSalesChannelDataInsertParams,
  defaultSalesChannelData,
} from "@app/model/AdditionalSalesChannelDataInsertParams.model";
import { ReportingPeriod } from "@app/model/ReportingPeriod.model";
import { SubscriptionLike } from "rxjs";
import {
  AdditionalSalesChannel,
  defaultSalesChannel,
} from "@app/model/AdditionalSalesChannel.model";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap/popover/popover";
import { cloneDeep } from "lodash";
const MAX_50K_VALUE = 50000;

@Component({
  selector: "app-licensee-additional",
  templateUrl: "./licensee-additional.component.html",
  styleUrls: ["./licensee-additional.component.scss"],
})
export class LicenseeAdditionalComponent implements OnInit, OnDestroy {
  @Input()
  isLoading: boolean;
  currency = { iso: "USD", symbol: "$" };
  isInternational: boolean = false;

  @ViewChildren("warningPopup") warningPopups: QueryList<NgbPopover>;
  WARNING_50K_STATES = {
    WARNING_NOT_TRIGGERED: "WARNING_NOT_TRIGGERED",
    MORE_THAN_50K: "MORE_THAN_50K",
    DECLINED_50K: "DECLINED_50K",
    IGNORE_WARNING: "IGNORE_WARNING",
  };
  warning50kState: Array<string> = [
    this.WARNING_50K_STATES.WARNING_NOT_TRIGGERED,
  ];
  additionalSalesValidationSymbolClass = "";
  currentWarningIndex: number;

  _ID: number = this.licenseeService.licenseeInfo.licenseeId;
  validationFails: number;

  private reportingPeriodSubscription: SubscriptionLike;
  private licenseeSearchSubscription: SubscriptionLike;

  // local variables
  salesChannel: AdditionalSalesChannel;
  selectedSalesChannels: Array<AdditionalSalesChannelDataInsertParams> = [
    { ...defaultSalesChannelData },
  ];
  channelsToDelete: Array<boolean> = [false];
  numChannelsFromServer: number = 0;
  channelList: Array<AdditionalSalesChannel>;
  channelLists: Array<Array<AdditionalSalesChannel>>;
  warningMessage: boolean = false;
  previousPosition = 9999;
  isActiveUpdate: boolean = false;
  reportingPeriodData: ReportingPeriod;

  salesChannelError: boolean = false;
  salesChannelMessage: string;

  constructor(public licenseeService: LicenseeService) {}

  ngOnInit() {
    this.validationFails = 0;
    if (
      this.licenseeService.licenseeInfo.licenseeId !== 0 &&
      this.licenseeService.licenseeInfo.licenseeId !== undefined
    ) {
      this.currency = this.licenseeService.currency;
    }

    this.licenseeService
      .getAdditionalSalesChannels()
      .subscribe((payload: Array<AdditionalSalesChannel>) => {
        payload.sort((a, b) => (a.id > b.id) ? 1 : -1);
        this.channelList = [defaultSalesChannel, ...payload].filter(
          (channel) => channel.isActive == true
        );
      });

    this.getReportingPeriodData();

    this.reset50kWarning(0);
    this.warningMessage = false;
  }

  ngOnDestroy(): void {
    if (this.reportingPeriodSubscription !== undefined) {
      this.reportingPeriodSubscription.unsubscribe();
    }
  }

  getReportingPeriodData() {
    this.reportingPeriodSubscription =
      this.licenseeService.reportingPeriodObservable.subscribe((payload) => {
        this.reportingPeriodData = cloneDeep(payload);
        this.selectedSalesChannels = [];
        this.channelsToDelete = [];
        this.numChannelsFromServer = 0;

        if (payload.additionalSalesChannelData.length) {
          this.reportingPeriodData.additionalSalesChannelData.forEach(
            (channel) => {
              if (channel.salesAmount) {
                let newChan = {
                  additionalSalesChannelId: channel.additionalSalesChannelId,
                  salesAmount: channel.salesAmount,
                };
                this.selectedSalesChannels.push(newChan);
                this.channelsToDelete.push(false);
                this.numChannelsFromServer++;
              }
            }
          );
        }
        if (this.selectedSalesChannels.length == 0) {
          this.selectedSalesChannels = [{ ...defaultSalesChannelData }];
        }
      });
  }

  onBlur(event: any, index: number) {
    let numValue = this.licenseeService.getCurrencyNumber(event.target.value);
    let strValue = this.licenseeService.getCurrencyNumberString(
      event.target.value
    );

    this.selectedSalesChannels[index].salesAmount = numValue;
    event.target.value = strValue;

    this.updateAdditionalSalesChannelData(null, null);
    return numValue;
  }

  addItemLine() {
    this.selectedSalesChannels.push({ ...defaultSalesChannelData });
    this.warning50kState.push(this.WARNING_50K_STATES.WARNING_NOT_TRIGGERED);
    this.channelsToDelete.push(false);
    this.updateAdditionalSalesChannelData();
  }

  removeItemLine(index: number) {
    // New Selected Sales Channels from current session
    if (index >= this.numChannelsFromServer) {
      if (this.selectedSalesChannels.length == 1 && index == 0) {
        this.selectedSalesChannels[0] = { ...defaultSalesChannelData };
        this.warning50kState = [this.WARNING_50K_STATES.WARNING_NOT_TRIGGERED];
      } else {
        this.selectedSalesChannels.splice(index, 1);
        this.warning50kState.splice(index, 1);
        this.channelsToDelete.splice(index, 1);
      }
    } else {
      // Selected Sales Channels from server
      this.selectedSalesChannels[index].salesAmount = 0;
      this.channelsToDelete[index] = true;
      this.warning50kState[index] =
        this.WARNING_50K_STATES.WARNING_NOT_TRIGGERED;
      if (!this.channelsToDelete.includes(false)) {
        this.addItemLine();
      }
      this.updateAdditionalSalesChannelData();
    }
  }

  updateAdditionalSalesChannelData(event?: any, index?: number) {
    // Make sure channel properties are numbers, and filter out new selected sales channels no salesAmount
    let formattedList = this.selectedSalesChannels
      .map((channel) => {
        channel.salesAmount = Number(channel.salesAmount);
        channel.additionalSalesChannelId = Number(
          channel.additionalSalesChannelId
        );
        return channel;
      })
      .filter((channel, index) => {
        return index < this.numChannelsFromServer || channel.salesAmount != 0;
      });
    // If duplicate sales channels, Sum the salesAmounts
    let uniqueFormattedList: Array<AdditionalSalesChannelDataInsertParams> = [];
    formattedList.forEach(function (chan) {
      if (!this[chan.additionalSalesChannelId]) {
        this[chan.additionalSalesChannelId] = {
          additionalSalesChannelId: chan.additionalSalesChannelId,
          salesAmount: 0,
        };
        uniqueFormattedList.push(this[chan.additionalSalesChannelId]);
      }
      this[chan.additionalSalesChannelId].salesAmount += chan.salesAmount;
    }, Object.create(null));

    // Send the list to the current reporting period
    this.licenseeService.reportingPeriod.additionalSalesChannelDataInsertParams =
      uniqueFormattedList;
  }

  set50kWarning(event: any, index: number) {
    let numValue = this.licenseeService.getCurrencyNumber(event);

    if (this.warning50kState[index] === this.WARNING_50K_STATES.DECLINED_50K) {
      this.reset50kWarning(index);
    }

    if (
      this.warning50kState[index] === this.WARNING_50K_STATES.IGNORE_WARNING &&
      numValue < MAX_50K_VALUE
    ) {
      this.warning50kState[index] =
        this.WARNING_50K_STATES.WARNING_NOT_TRIGGERED;
    }

    if (
      this.warning50kState[index] ===
        this.WARNING_50K_STATES.WARNING_NOT_TRIGGERED &&
      numValue > MAX_50K_VALUE
    ) {
      this.warning50kState[index] = this.WARNING_50K_STATES.MORE_THAN_50K;
      this.licenseeService.isAdditionalSale50kWarning = true;
      this.currentWarningIndex = index;
      this.warningPopups.toArray()[index].open();
    }
  }

  getValidate50kDeclinedError(index: number) {
    if (this.warning50kState[index] === this.WARNING_50K_STATES.DECLINED_50K) {
      this.salesChannelMessage = "Please enter value less then 50k";
      this.salesChannelError = true;
      return true;
    }
  }

  reset50kWarning(index: number) {
    this.warning50kState[index] = this.WARNING_50K_STATES.WARNING_NOT_TRIGGERED;
    this.licenseeService.isAdditionalSale50kWarning = false;
    this.currentWarningIndex = null;
  }

  closeWarningPopupAndReset(index: number) {
    this.closeWarningPopup(index);
    this.selectedSalesChannels[index].salesAmount = 0;
    this.warning50kState[index] = this.WARNING_50K_STATES.DECLINED_50K;
    this.licenseeService.isAdditionalSale50kWarning = true;
    this.currentWarningIndex = null;
  }

  closeWarningPopupAndIgnore(index: number) {
    this.closeWarningPopup(index);
    this.warning50kState[index] = this.WARNING_50K_STATES.IGNORE_WARNING;
    this.licenseeService.isAdditionalSale50kWarning = false;
    this.currentWarningIndex = null;
  }

  closeWarningPopup(index: number) {
    this.warningPopups.toArray()[index].close();
  }

  getValidationClass(amount: number, id: number) {
    const errorClass = "fa-times-circle text-danger";
    if (this.getValidate50kDeclinedError(id)) {
      return errorClass;
    } else if (
      amount === 0 ||
      amount === undefined ||
      id === 0 ||
      id === undefined
    ) {
      return "";
    } else {
      return "fa-check-circle text-success";
    }
  }

  getAdditionalSalesChannelList() {
    this.licenseeService
      .getAdditionalSalesChannels()
      .subscribe((results: Array<AdditionalSalesChannel>) => {
        this.licenseeService.setAdditionalSalesChannels(results);
      });
  }

  setCorrectCursorPlacement(event: any) {
    // records cursor position in input box
    let pos = event.target.selectionStart;

    let strValue = this.licenseeService.getCurrencyNumberString(
      event.target.value
    );

    // finds position of change in new string
    let pos2 = 9999;
    if (event.target.value.length != strValue.length) {
      for (
        let i = 0;
        i < event.target.value.length && i < strValue.length;
        i++
      ) {
        if (strValue[i] != event.target.value[i]) {
          pos2 = i;
          break;
        }
      }
    }

    // if previousPosition > pos && we're at a dot/comma, go 1 position back.
    if (
      this.previousPosition > pos &&
      (event.target.value[pos - 1] == "." || event.target.value[pos - 1] == ",")
    ) {
      pos--;
    }

    if (
      this.previousPosition < pos &&
      (event.target.value[pos - 1] == "." || event.target.value[pos - 1] == ",")
    ) {
      pos += 1;
    }

    // finds the correct new cursor position
    if (pos2 < pos && event.target.value.length < strValue.length) {
      pos += 1;
    } else if (pos2 < pos && event.target.value.length > strValue.length) {
      pos -= 1;
    }

    // set input box value
    event.target.value = strValue;

    // set previous position
    this.previousPosition = pos;

    // sets cursor position in input (have to account for an additional comma every 3 numbers)
    event.target.selectionStart = pos;
    event.target.selectionEnd = pos;
    // return strValue;
  }

  onKeyDown(event: any, index: number) {
    if (event.key === "Enter") {
      this.addItemLine();
    }
  }
}

import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { LicenseeService } from "@app/services/licensee.service";
import { DailySales } from "@app/model/DailySales.model";
import { CurrentDate } from "@app/model/CurrentDate.model";
import { LicenseeSearch } from "@app/model/LicenseeSearch.model";
import { Subject, SubscriptionLike } from "rxjs";

@Component({
  selector: "app-licensee-daily",
  templateUrl: "./licensee-daily.component.html",
  styleUrls: ["./licensee-daily.component.scss"]
})
export class LicenseeDailyComponent implements OnInit, OnDestroy {
  //Retrieving variables from Licensee parent component
  @Input()
  isLoading: boolean; //Boolean to see if data has been returned
  MAX_DAILY_SALE_LIMIT: number = 60000;

  private reportingPeriodSubscription: SubscriptionLike;
  private _warning = new Subject<string>();

  //Local variables
  currency = {};
  previousPosition = 9999;
  currentDate = new CurrentDate();
  presentDate = new CurrentDate();
  currentYear = this.currentDate.year;
  dailySales = new Array<DailySales>();
  licenseeSearchData = new LicenseeSearch();
  _ID: number = this.licenseeService.licenseeInfo.licenseeId;
  warningMessage: boolean = false;
  allowsAdjustments : boolean = this.licenseeService.licenseeInfo.allowsAdjustments;

  currentDateForm: UntypedFormGroup;
  dailySalesForm: UntypedFormGroup;
  salesYear: number;
  salesMonth: string;
  salesMonthNum: number;
  numberOfDaysInCurrentMonth: number;
  invalidMessage: string;
  invalidReason:  string = "Adjustment Reason must be at least 10 characters";
  yearList: any;
  monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]; //List of months to build drop down

  adjustmentReason: string = '';
  adjustmentReasonError: boolean = true;
  adjustmentReasonTouched: boolean = false;

  constructor(public licenseeService: LicenseeService) {}

  // Empty out input field if '$0.00' is present.
  onFocus(event: any, salesDate: string, licenseeID: number):string {
    let dailysale = this.dailySales.find(obj => (obj.salesDate == salesDate && obj.licenseeId == licenseeID))
    if(event.target.value == "$0.00") {
      this.dailySales[this.dailySales.indexOf(dailysale)].salesAmount = Number(0.00);
      event.target.value = "";
    }
    return event;
  }

  updateAdjustmentReason() {
    this.adjustmentReasonError = this.adjustmentReason.length < 10;
    if (!this.adjustmentReasonError) {
      this.licenseeService.reportingPeriod.reasonForAdjustment = this.adjustmentReason;
      this.licenseeService.adJustmentGoodToSubmit = true;
    } else {
      this.licenseeService.adJustmentGoodToSubmit = false;
    }
  }

  onAdjustmentReasonFocus() {
    this.adjustmentReasonTouched = true;
  }

  onBlur(event: any, salesDate: string, licenseeID: number) {
    let dailysale = this.dailySales.find(obj => (obj.salesDate == salesDate && obj.licenseeId == licenseeID));
    let numValue = this.licenseeService.getCurrencyNumber(event.target.value);
    let strValue = this.licenseeService.getCurrencyNumberString(event.target.value);

    // Add numValue to the dailySales model and strValue to the event.target.value
    this.dailySales[this.dailySales.indexOf(dailysale)].salesAmount = numValue;
    event.target.value = strValue;

    return numValue;
  }

  setCorrectCursorPlacement(event: any) {
    // records cursor position in input box
    let pos = event.target.selectionStart;

    let strValue = this.licenseeService.getCurrencyNumberString(event.target.value);

    // finds position of change in new string
    let pos2 = 9999;
    if(event.target.value.length != strValue.length) {
      for(let i = 0; (i < event.target.value.length && i < strValue.length); i++) {
        if(strValue[i] != event.target.value[i]) {
          pos2 = i;
          break;
        }
      }
    }

    // if previousPosition > pos && we're at a dot/comma, go 1 position back.
    if(this.previousPosition > pos && (event.target.value[pos-1] == '.' || event.target.value[pos-1] == ',')) {
      pos--;
    }

    if(this.previousPosition < pos && (event.target.value[pos-1] == '.' || event.target.value[pos-1] == ',')) {
      pos+=1;
    }

    // finds the correct new cursor position
    if((pos2 < pos) && (event.target.value.length < strValue.length)) {
      pos += 1;
    } else if((pos2 < pos) && (event.target.value.length > strValue.length)) {
      pos -= 1;
    }

    // set input box value
    event.target.value = strValue;

    // set previous position
    this.previousPosition = pos;

    // sets cursor position in input (have to account for an additional comma every 3 numbers)
    event.target.selectionStart = pos;
    event.target.selectionEnd = pos;
   // return strValue;
  }

  ngOnInit() {
    if (this.licenseeService.licenseeInfo.licenseeId !== 0 &&
      this.licenseeService.licenseeInfo.licenseeId !== undefined) {

      // set currency
      this.currency = this.licenseeService.currency;

      //Set current date on init
      this.warningMessage = false;
      this.setCurrentDate(this.licenseeService.currentDate.month, this.licenseeService.currentDate.year);

      this.presentDate = {...this.currentDate};
      this.yearList = this.getListOfYears();

      //ngModel Variables
      // this.updateNgModels(this.currentDate);

      this.createEmptyMonthOfSales();

      //Subscription to ReportingPeriod Observable in parent
      this.reportingPeriodSubscription = this.licenseeService.reportingPeriodObservable.subscribe(payload => {
        this.warningMessage = false;
        if (payload !== null) {
          if (payload.dailySales.length === 0) {
            this.createEmptyMonthOfSales();
          } else {
            this.setCurrentDate(this.licenseeService.currentDate.month, this.licenseeService.currentDate.year);
            this.dailySales = this.getSalesDatesWithSundays(payload.dailySales);
          }
          this.updateSalesData();
        }
      });
    }
  }

  getSalesDatesWithSundays(sales: Array<DailySales>){
    const selectedMonth = this.currentDate.month;
    const selectedYear = this.currentDate.year;
    return sales.map((dailySale, day)=>{
      const date = new Date(selectedYear, selectedMonth - 1, day+1);
      const isSunday = date.getDay() === 0;
      return {
        ...dailySale,
        salesAmount: isSunday && !dailySale.salesAmount ? 0 : dailySale.salesAmount,
        isSunday: dailySale.salesAmount ? false: isSunday
      };
    });
  }

  createEmptyMonthOfSales() {
    const dailySales = [];
    for (let i = 1; i <= this.numberOfDaysInCurrentMonth; i++) {
      var salesDate = this.salesYear + "-" + this.salesMonthNum + "-" + i;
      dailySales.push(new DailySales(this._ID, salesDate));
    }
    this.dailySales = this.getSalesDatesWithSundays(dailySales);
  }

  //Destroy Subscription to prevent memory leak
  ngOnDestroy() {
    if (this.reportingPeriodSubscription !== undefined) {
      this.reportingPeriodSubscription.unsubscribe();
    }
  }

  updateNgModels(currentDate:CurrentDate){
    //ngModel Variables
    this.salesMonth = this.monthNumToName(currentDate.month);
    this.salesMonthNum = currentDate.month;
    this.salesYear = currentDate.year;
  }

  //Get years
  getListOfYears() {
    this.licenseeService.getYears().subscribe(res => {
      this.yearList = res;
      this.licenseeService.reportingPeriodYears = this.yearList;
    });
  }

  //Set the current date
  setCurrentDate(month: number, year: number) {
    //Get current month and date for initial load
    this.currentDate.year = year;
    this.currentDate.month = month;

    this.licenseeService.currentDate.month = month;
    this.licenseeService.currentDate.year = year;
    //Get number of days in the month
    this.getNumberOfDays(month, year);
    this.updateNgModels(this.currentDate);
  }

  //Calculating the number of days in the given month
  getNumberOfDays(month: number, year: number) {
    let date = new Date(year, month, 0).getDate();
    this.numberOfDaysInCurrentMonth = date;
  }

  //Putting the number days into a date array used to iterate over
  dateArray(n: number): any[] {
    return Array(n);
  }

  monthNumToName(monthNum: number) {
    return this.monthList[monthNum - 1] || "";
  }

  monthNameToNum(monthName: string) {
    var month = this.monthList.indexOf(monthName);
    return month + 1;
  }

  //Submitting month/year date form to call to DB for new data
  onDateSpinnerChange(month: string, year: number) {
   month = month.trim();
    var monthNumber = this.monthNameToNum(month);
    var yearNumber: number;

    if (typeof year === "string") {
      yearNumber = parseInt(year);
    } else {
      yearNumber = year;
    }

    this.setCurrentDate(monthNumber, yearNumber);
    this.dailySales = new Array<DailySales>();

    this.licenseeSearchData.licenseeid = this.licenseeService.licenseeSearch.licenseeid;
    this.licenseeSearchData.month = this.currentDate.month;
    this.licenseeSearchData.year = this.currentDate.year;
    this.licenseeService.setLicenseeSearch(this.licenseeSearchData);
    this.licenseeService.reportingPeriod.isAdjustment = false;
    this.adjustmentReasonError = false;
    this.adjustmentReason = '';
  }

  updateSalesData() {
    var numFails = this.validationFailsCount();
    this.licenseeService.reportingPeriod.warnings = this.validationWarningCount();

    if (numFails > 0) {
      this.licenseeService.dailyGoodToSubmit = false;
    } else {
      this.warningMessage = false;
      this.licenseeService.dailyGoodToSubmit = true;
    }

    this.licenseeService.reportingPeriod.dailySales = this.dailySales;
    console.log("dailyGoodToSubmit", this.licenseeService.dailyGoodToSubmit);
  }

  vaildationCheck(salesAmount: number) {
    if (salesAmount < this.MAX_DAILY_SALE_LIMIT) {
      return true;
    } else {
      return false;
    }
  }

  validationFailsCount() {
    var fails = 0;
    for (var i = 0; i < this.dailySales.length; i++) {
      if (this.dailySales[i].salesAmount !== null) {
        continue;
      } else {
        fails += 1;
      }
    }
    return fails;
  }

  validationWarningCount() {
    var warnings = 0;
    for (var i = 0; i < this.dailySales.length; i++) {
      if (this.vaildationCheck(this.dailySales[i].salesAmount)) {
        continue;
      } else {
        warnings += 1;
      }
    }
    return warnings;
  }

  getIconClass(daySales: any) {
    if (
      daySales === undefined ||
      daySales.salesAmount === "" ||
      daySales.salesAmount === null ||
      daySales.salesAmount === undefined
    ) {
      return "";
    }
    if (this.vaildationCheck(daySales.salesAmount)) {
      // this.warningMessage = false;
      return "fa-check-circle text-success";
    } else {
      this.invalidMessage = "Amount entered exceeds $60,000 limit.";
      this.warningMessage = true;
      return "fa-exclamation-circle text-warning";
    }
  }

  getInputClass(amount: number) {
    if (!this.vaildationCheck(amount)) {
      return "ng-invalid warnings";
    } else {
      return "";
    }
  }

  onModelChange(event: string) {

    return event.toString().trim();
  }

  setAdjustment(){
    this.licenseeService.reportingPeriod.isAdjustment = true;
    this.licenseeService.reportingPeriodLocked = false;
  }

  isPastDateSelected(): boolean {

    if(this.currentDate.year < this.presentDate.year) {
      return true;
    } 
    if (this.currentDate.year == this.presentDate.year && this.currentDate.month < this.licenseeService.todaysDate.getMonth() + 1) {
      return true;
    }
    else {
      return false;
    }
  }

  get isEditDisabled(): boolean {
    return this.licenseeService.reportingPeriodLocked && !this.isPastDateSelected();
  }

  get isReportingPeriodLocked(): boolean {
    return this.licenseeService.reportingPeriodLocked;

  }
}

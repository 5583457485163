<div *isIE class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12 daily-header">
        Error
      </div>
      <div>Incompatible Browser Detected. Please Use <a href="https://www.google.com/chrome/browser/">Chrome</a> or <a href="https://www.mozilla.org/firefox">Firefox</a>.</div>
      </div>
  </div>
</div>

<div *ngIf="!licenseeService.MAINTENANCE">
  <div class='container-95'>
    <ngb-alert class="success-alert" *ngIf="successMessage" type="success" (close)="successMessage = null"><strong>Success!</strong>
      {{ successMessage }}</ngb-alert>
    <ngb-alert class="error-alert" *ngIf="errorMessage" type="danger" (close)="errorMessage = null"><strong>Error!</strong>
      {{ errorMessage }}</ngb-alert>

    <div class='row'>
      <div class='col-sm-4 section-col'>
        <app-licensee-summary [isLoading]="isLoading"></app-licensee-summary>
        <app-licensee-admin-control *ngIf="authenticationService.credentials.isAdmin"></app-licensee-admin-control>
        <app-licensee-previous></app-licensee-previous>
        <app-licensee-director></app-licensee-director>
      </div>

      <div class='col-sm-3 section-col'>
        <app-licensee-daily [isLoading]="isLoading"></app-licensee-daily>
      </div>

      <div class='col-sm-4 section-col'>
        <app-licensee-additional *ngIf="showAdditionalSalesChannels()"></app-licensee-additional>
        <app-licensee-monthly *ngIf="!showAdditionalSalesChannels()"></app-licensee-monthly>
        <app-licensee-upload></app-licensee-upload>
        <app-licensee-contact></app-licensee-contact>
        <app-licensee-confirmation></app-licensee-confirmation>

        <div class='text-center'>
          <button data-testid="btn-save-all" class="btn btn-primary text-white btn-save" (click)="saveReportingPeriod()" [disabled]="getButtonSaveStatus() || isBtnLoading || licenseeService.reportingPeriodLocked || isReadOnlyAdmin">
            <app-btn-loader [isLoading]="isBtnLoading"></app-btn-loader>
            <i class="far fa-save" [hidden]="isBtnLoading"></i>
            <span>SAVE</span>
          </button>
          <button data-testid="btn-submit-all" class="btn btn-danger text-white btn-submit" (click)="submitReportingPeriod()" [disabled]="isSubmitButtonDisabled()">
            <app-btn-loader [isLoading]="isBtnLoading"></app-btn-loader>
            <i class="fas fa-paper-plane" [hidden]="isBtnLoading"></i>
            <span>SUBMIT</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</div>

<div *ngIf="licenseeService.MAINTENANCE" class='container-95'>
  <div class='row'>
    <h1 class='col-sm-12 text-center'>WEBSITE MAINTENANCE IN PROGRESS</h1>
  </div>
</div>

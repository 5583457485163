import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutingModule } from "@app/app-routing.module";
import { CurrentDate } from "@app/model/CurrentDate.model";
import { DataValidationLog } from "@app/model/DataValidationLog.model";
import { Licensee } from "@app/model/Licensee.model";
import { LicenseeSearch } from "@app/model/LicenseeSearch.model";
import { SubmissionReport } from "@app/model/SubmissionReport.model";
import { LicenseeService } from "@app/services/licensee.service";
import { cloneDeep } from "lodash";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  Subject,
  SubscriptionLike,
} from "rxjs";

@Component({
  selector: "app-cfa-locations",
  templateUrl: "./cfa-locations.component.html",
  styleUrls: ["./cfa-locations.component.scss"],
})
export class CfaLocationsComponent implements OnInit, OnDestroy {
  @Input()
  isLoading: boolean; //Boolean to see if data has been returned
  isDataAvail: boolean = false;
  private selectedSubmissionReportSubscription: SubscriptionLike;
  private submissionReportDateSubscription: SubscriptionLike;
  private SubmissionReportSubscription: SubscriptionLike;
  private dataValidationLogSubcription: SubscriptionLike;
  private _success = new Subject<string>();
  private _error = new Subject<string>();

  submissionReports: Array<SubmissionReport>;
  filteredSubmissionReports: Array<SubmissionReport>;
  licenseeSearch = new LicenseeSearch();
  reportLogs: DataValidationLog;
  selectedStatus: string = "";
  displayPermanentlyClosed: boolean = false;
  displayTemporarilyClosed: boolean = false;

  currentDate = new CurrentDate();

  nameSortedAsc: boolean = false;
  numberSortedAsc: boolean = true;
  dateSortedAsc: boolean = false;
  statusSortedAsc: boolean = false;

  selectedSubmission = new SubmissionReport();
  logs: Array<DataValidationLog>;
  status: string = "";

  constructor(
    private licenseeService: LicenseeService,
    private router: Router
  ) {}

  ngOnInit(): void {

    this.submissionReports = [];

    this.submissionReportDateSubscription =
      this.licenseeService.submissionReportDateObservable.subscribe(
        (dateData) => {
          this.currentDate = dateData;
          this.selectedSubmissionReportSubscription =
            this.licenseeService.selectedSubmissionReportObservable.subscribe(
              (payload) => {
                if (payload === null) {
                  this.submissionReports = [];
                } else {
                  this.selectedStatus = this.licenseeService.selectedStatus;
                  this.submissionReports = payload;
                  this.filteredSubmissionReports = cloneDeep(payload);
                  this.licenseeService.selectedSubmissionStatus =
                    this.submissionReports;
                  this.sortReports("number");
                }
                if (this.submissionReports.length) {
                  this.isDataAvail = !!this.submissionReports.length;
                }
              }
            );
        }
      );
  }

  ngOnDestroy(): void {
    if (this.selectedSubmissionReportSubscription !== undefined) {
      this.selectedSubmissionReportSubscription.unsubscribe();
    }
    if (this.submissionReportDateSubscription !== undefined) {
      this.submissionReportDateSubscription.unsubscribe();
    }
  }

  formatLocationSearchName(item: SubmissionReport) {
    const {
      licensee: { cfaLocationName, cfaLocationNumber },
    } = item;
    return `${cfaLocationName} - ${cfaLocationNumber}`;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((searchQuery) => {
        if (!searchQuery || searchQuery.length < 2) {
          this.filteredSubmissionReports = this.submissionReports;
          return [];
        }
        this.filteredSubmissionReports = this.submissionReports.filter(
          ({
            licensee: {
              cfaLocationName: locationName,
              cfaLocationNumber: locationNumber,
            },
          }) =>
            locationName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
              -1 ||
            String(locationNumber)
              .toLowerCase()
              .indexOf(searchQuery.toLowerCase()) > -1
        );
        return this.filteredSubmissionReports.slice(0, 10);
      })
    );

  selectTypeheadItem({ item }: { item: SubmissionReport }) {
    this.filteredSubmissionReports = this.submissionReports.filter(
      ({ licensee: { cfaLocationName, cfaLocationNumber } }) =>
        cfaLocationName === item.licensee.cfaLocationName &&
        cfaLocationNumber === item.licensee.cfaLocationNumber
    );
  }

  getStatus(status: number) {
    switch (status) {
      case 0:
        return "Not Started";
      case 1:
        return "Draft";
      case 2:
        return "In Review";
      case 3:
        return "Approved";
    }
  }

  getStatusClass(status: number) {
    switch (status) {
      case 0:
        return "not-started";
      case 1:
        return "draft";
      case 2:
        return "in-review";
      case 3:
        return "approved";
    }
  }

  getImagePath(status: number) {
    switch (status) {
      case 0:
        return "/assets/img/icons/x.png";
      case 1:
        return "/assets/img/icons/x.png";
      case 2:
        return "/assets/img/icons/clock.png";
      case 3:
        return "/assets/img/icons/thumbs-up.png";
    }
  }

  getImageClass(status: number) {
    switch (status) {
      case 0:
        return "not-approved-img";
      case 1:
        return "not-approved-img";
      case 2:
        return "pending-img";
      case 3:
        return "approved-img";
    }
  }

  sortReports(value: string) {
    switch (value) {
      case "name":
        if (this.numberSortedAsc) {
          this.numberSortedAsc = false;
          this.filteredSubmissionReports.sort((a, b) => {
            const nameA = a.licensee.cfaLocationName.toUpperCase();
            const nameB = b.licensee.cfaLocationName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        } else {
          this.numberSortedAsc = true;
          this.filteredSubmissionReports.sort((a, b) => {
            const nameA = a.licensee.cfaLocationName.toUpperCase();
            const nameB = b.licensee.cfaLocationName.toUpperCase();
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0;
          });
        }
        return;

      case "number":
        if (!this.numberSortedAsc) {
          this.numberSortedAsc = true;
          this.filteredSubmissionReports.sort(
            (a, b) =>
              Number(a.licensee.cfaLocationNumber) -
              Number(b.licensee.cfaLocationNumber)
          );
        } else {
          this.numberSortedAsc = false;
          this.filteredSubmissionReports.sort(
            (a, b) =>
              Number(b.licensee.cfaLocationNumber) -
              Number(a.licensee.cfaLocationNumber)
          );
        }
        return;

      case "date":
        let reportsNoDate: Array<SubmissionReport> = [];
        let reportsWithDate: Array<SubmissionReport> = [];
        this.filteredSubmissionReports.forEach((report) => {
          if (isNaN(Date.parse(report.reportingperiod.dateSubmitted))) {
            reportsNoDate.push(report);
          } else {
            reportsWithDate.push(report);
          }
        });
        if (!this.dateSortedAsc) {
          this.dateSortedAsc = true;
          reportsWithDate.sort(
            (a, b) =>
              Date.parse(a.reportingperiod.dateSubmitted) -
              Date.parse(b.reportingperiod.dateSubmitted)
          );
        } else {
          this.dateSortedAsc = false;
          reportsWithDate.sort(
            (a, b) =>
              Date.parse(b.reportingperiod.dateSubmitted) -
              Date.parse(a.reportingperiod.dateSubmitted)
          );
        }
        this.filteredSubmissionReports = reportsWithDate.concat(reportsNoDate);
        return;

      case "status":
        if (this.filteredSubmissionReports) {
          var approvedReports: Array<SubmissionReport>;
          var draftReports: Array<SubmissionReport>;
          var notStartedReports: Array<SubmissionReport>;
          var inReviewReports: Array<SubmissionReport>;
          approvedReports = [];
          draftReports = [];
          notStartedReports = [];
          inReviewReports = [];
          this.filteredSubmissionReports.forEach((report) => {
            if (report.reportingperiod.statusID == 3) {
              approvedReports.push(report);
            }
            if (report.reportingperiod.statusID == 1) {
              draftReports.push(report);
            }
            if (report.reportingperiod.statusID == 0) {
              notStartedReports.push(report);
            }
            if (report.reportingperiod.statusID == 2) {
              inReviewReports.push(report);
            }
          });

          if (!this.statusSortedAsc) {
            this.statusSortedAsc = true;
            this.filteredSubmissionReports = [];
            this.filteredSubmissionReports = approvedReports
              .concat(draftReports)
              .concat(notStartedReports)
              .concat(inReviewReports);
          } else {
            this.statusSortedAsc = false;
            this.filteredSubmissionReports = [];
            this.filteredSubmissionReports = inReviewReports
              .concat(notStartedReports)
              .concat(draftReports)
              .concat(approvedReports);
          }
          return;
        }
    }
  }

  formatDate(date: string) {
    const dateArray = date.split("-");
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  }

  setPermanentlyFilter() {
    if (!this.displayPermanentlyClosed) {
      this.filteredSubmissionReports = this.submissionReports.filter(
        (location) => {
          return location.licensee.status == "closed";
        }
      );
    }
    if (this.displayPermanentlyClosed) {
      this.filteredSubmissionReports = this.submissionReports;
    }
    this.displayPermanentlyClosed = !this.displayPermanentlyClosed;
    this.isDataAvail = !!this.filteredSubmissionReports.length;
  }

  setTemporarilyFilter() {
    if (!this.displayTemporarilyClosed) {
      this.filteredSubmissionReports = this.submissionReports.filter(
        (location) => {
          return location.licensee.tempShutdown == true;
        }
      );
    }
    if (this.displayTemporarilyClosed) {
      this.filteredSubmissionReports = this.submissionReports;
    }
    this.displayTemporarilyClosed = !this.displayTemporarilyClosed;
    this.isDataAvail = !!this.filteredSubmissionReports.length;
  }

  impersonateLicensee(
    id: number,
    name: string,
    locationNumber: string,
    countryCode: string,
    catering: boolean,
    giftCards: boolean,
    adjustments: boolean
  ) {
    const licensee = new Licensee();
    licensee.cfaLocationNumber = locationNumber;
    licensee.cfaLocationName = name;
    licensee.licenseeId = id;
    licensee.countryCode = countryCode;
    licensee.catering = catering;
    licensee.giftCards = giftCards;
    licensee.allowsAdjustments = adjustments;

    this.licenseeService.setLicenseeInfo(licensee);

    this.licenseeSearch.licenseeid = id;
    this.licenseeSearch.month = this.licenseeService.currentDate.month;
    this.licenseeSearch.year = this.licenseeService.currentDate.year;
    this.router.navigate(["licensee"]);
    this.licenseeService.setLicenseeSearch(this.licenseeSearch);
  }
}

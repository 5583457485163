<div class='card'>
	<div class='card-body'>
      	<div class='header text-center'>
          	<h5 class='text-danger'>Admin Control Panel</h5>
      	</div>

		<hr>

		<app-loader [isLoading]="isLoading" class="text-center"></app-loader>

		<div [hidden]="isLoading" class='admin-control-body text-center'>
			<div class="form-group row mb-3">
				<div class="col-sm-12 label-container">
					<label class="col-sm-12 form-label">
						<input class="exempt-checkbox" [ngModel]="exempt" (ngModelChange)="updateExemptStatus($event)" type="checkbox"/>Exempt from reporting
					</label>
				</div>
			</div>
			<div class="form-group row mb-2">
				<div class="col-sm-12 label-container">
					<label (click)="resetReportingPeriod()">
						<i class="fa fa-undo"></i>Reset Current Month
					</label>
				</div>
			</div>
		</div>

	</div>
</div>

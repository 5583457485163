import {Component} from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: "app-cfa-success-message-modal",
    templateUrl: "./cfa-success-message-modal.component.html",
    styleUrls: ["./cfa-success-message-modal.component.scss"]
})
export class CfaSuccessMessageModalComponent{
    title: string;
    message: string;
    constructor(public activeModal: NgbActiveModal) {}
}

import {Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef} from '@angular/core';
import { LicenseeService } from "../services/licensee.service";
import { ThirdPartySales } from "@app/model/ThirdPartySales.model";
import { Subject, SubscriptionLike } from "rxjs";
const MAX_75K_VALUE = 75000;

@Component({
  selector: "app-licensee-monthly",
  templateUrl: "./licensee-monthly.component.html",
  styleUrls: ["./licensee-monthly.component.scss"]
})
export class LicenseeMonthlyComponent implements OnInit, OnDestroy {
  @Input()
  isLoading: boolean;
  previousPosition = 9999;
  currency = {};
  isInternational: boolean = false;
  @ViewChild('warningPopup') warningPopup:any;
  WARNING_75K_STATES = {
    WARNING_NOT_TRIGGERED: 'WARNING_NOT_TRIGGERED',
    MORE_THAN_75K: 'MORE_THAN_75K',
    DECLINED_75K: 'DECLINED_75K',
    IGNORE_WARNING: 'IGNORE_WARNING',
  }
  warning75kState: string = this.WARNING_75K_STATES.WARNING_NOT_TRIGGERED;
  mobileSalesValidationSymbolClass = '';

  private reportingPeriodSubscription: SubscriptionLike;
  private licenseeSummariesSubscription: SubscriptionLike;
  private _errorThirdParty = new Subject<string>();
  private _errorGiftCard = new Subject<string>();

  thirdPartySalesData = this.initializeArray();
  _ID: number = this.licenseeService.licenseeInfo.licenseeId;
  validationFails: number;
  licenseeSummaryData: any;

  giftCardError: boolean = false;
  giftCardMessage: string;

  thirdPartyError: boolean = false;
  thirdPartyMessage: string;

  constructor(public licenseeService: LicenseeService) {}

  ngOnInit() {
    this.validationFails = 0;
    this.giftCardError = false;
    this.thirdPartyError = false;
    // this.thirdPartySalesData = this.initializeArray();
    this.currency = this.licenseeService.currency;
    this.isInternational = this.licenseeService.isInternational;


    this.licenseeSummariesSubscription = this.licenseeService.licenseeSummariesDataObservable.subscribe(payload => {
      if (payload != null) {
        this.updateThirdPartySalesNoEvent();
      }
    });
  

    //Subscription to ReportingPeriod Observable in parent
    this.reportingPeriodSubscription = this.licenseeService.reportingPeriodObservable.subscribe(payload => {
      this.reset75KWarning();
      this.thirdPartySalesData = this.initializeArray();
      this.giftCardError = false;
      this.thirdPartyError = false;
      if (payload !== null) {
        if (payload.thirdPartySales.length > 0) {
          payload.thirdPartySales.forEach((data: any) => {
            if (data.productId === 1) {
              this.thirdPartySalesData[0] = data;
            } else if (data.productId === 2) {
              this.thirdPartySalesData[1] = data;
            } else if (data.productId === 3) {
              this.thirdPartySalesData[2] = data;
            } else if (data.productId === 4) {
              this.thirdPartySalesData[3] = data;
            } else if (data.productId === 5) {
              this.thirdPartySalesData[4] = data;
            }
          });
          // this.validationFails = 0;
          this.updateThirdPartySalesNoEvent();
        }
      }
    });
  }

  // Empty out input field if '$0.00' is present.
  onFocus(event: any, licenseeId: number, productId: number):string {
    let thirdPartySale = this.thirdPartySalesData.find(obj => (obj.licenseeId == licenseeId && obj.productId == productId))
    if(event.target.value == "$0.00") {
      this.thirdPartySalesData[this.thirdPartySalesData.indexOf(thirdPartySale)].value = Number(0.00);
      event.target.value = "";
    }
    return event;
  }

  reset75KWarning(){
    this.warning75kState = this.WARNING_75K_STATES.WARNING_NOT_TRIGGERED;
    this.licenseeService.isMonthlySale75kWarning = false;
  }

  setCorrectCursorPlacement(event: any) {
    // records cursor position in input box
    let pos = event.target.selectionStart;

    let strValue = this.licenseeService.getCurrencyNumberString(event.target.value);

    // finds position of change in new string
    let pos2 = 9999;
    if(event.target.value.length != strValue.length) {
      for(let i = 0; (i < event.target.value.length && i < strValue.length); i++) {
        if(strValue[i] != event.target.value[i]) {
          pos2 = i;
          break;
        }
      }
    }

    // if previousPosition > pos && we're at a dot/comma, go 1 position back.
    if(this.previousPosition > pos && (event.target.value[pos-1] == '.' || event.target.value[pos-1] == ',')) {
      pos--;
    }

    if(this.previousPosition < pos && (event.target.value[pos-1] == '.' || event.target.value[pos-1] == ',')) {
      pos+=1;
    }

    // finds the correct new cursor position
    if((pos2 < pos) && (event.target.value.length < strValue.length)) {
      pos += 1;
    } else if((pos2 < pos) && (event.target.value.length > strValue.length)) {
      pos -= 1;
    }

    // set input box value
    event.target.value = strValue;

    // set previous position
    this.previousPosition = pos;

    // sets cursor position in input (have to account for an additional comma every 3 numbers)
    event.target.selectionStart = pos;
    event.target.selectionEnd = pos;
   // return strValue;
  }

  updateThirdPartySales(event: any, licenseeId: number, productId: number) {
    let numValue;
    let strValue;

      const thirdPartySale = this.thirdPartySalesData.find(obj =>
        (obj.licenseeId === licenseeId && obj.productId === productId));
      // if the event is dispatched from ngmodelchange, the value is stored in the event itself.
      // Otherwise it is in event.target.value
      if (event.target === undefined) {
       numValue = this.licenseeService.getCurrencyNumber(event);
       strValue = this.licenseeService.getCurrencyNumberString(event);
       event = strValue;
      } else {
       numValue = this.licenseeService.getCurrencyNumber(event.target.value);
       strValue = this.licenseeService.getCurrencyNumberString(event.target.value);
       event.target.value = strValue;
      }

    if(this.warning75kState === this.WARNING_75K_STATES.DECLINED_75K){
      this.reset75KWarning();
    }

    if(this.warning75kState === this.WARNING_75K_STATES.IGNORE_WARNING &&
        numValue < MAX_75K_VALUE){
      this.warning75kState = this.WARNING_75K_STATES.WARNING_NOT_TRIGGERED;
    }

    if(this.warning75kState === this.WARNING_75K_STATES.WARNING_NOT_TRIGGERED &&
        numValue > MAX_75K_VALUE){
      this.warning75kState = this.WARNING_75K_STATES.MORE_THAN_75K;
      this.licenseeService.isMonthlySale75kWarning = true;
      this.warningPopup.open()
    }

      // // Add numValue to the thirdPartySales model and strValue to the event.target.value
      this.thirdPartySalesData[this.thirdPartySalesData.indexOf(thirdPartySale)].value = numValue;

    let exceptions = 0;
    for (let i = 0; i < this.thirdPartySalesData.length; i++) {
      if (
        this.vaildationCheck(this.thirdPartySalesData[i].value, this.thirdPartySalesData[i].productId) &&
        this.thirdPartySalesData[i].value !== null
      ) {
        continue;
      } else {
        exceptions += 1;
      }
    }

    if (exceptions > 0) {
      this.licenseeService.monthlyGoodToSubmit = false;
    } else {
      this.licenseeService.monthlyGoodToSubmit = true;
    }
    this.licenseeService.reportingPeriod.thirdPartySales = this.thirdPartySalesData;
    this.licenseeService.reportingPeriod.exceptions = exceptions;
  }

  updateThirdPartySalesNoEvent() {
    let exceptions = 0;

    for (let i = 0; i < this.thirdPartySalesData.length; i++) {
      if (
        this.vaildationCheck(this.thirdPartySalesData[i].value, this.thirdPartySalesData[i].productId) &&
        this.thirdPartySalesData[i].value !== null
      ) {
        continue;
      } else {
        exceptions += 1;
      }
    }

    if (exceptions > 0 && this.licenseeService) {
      this.licenseeService.monthlyGoodToSubmit = false;
    }
    if (exceptions == 0 && this.licenseeService.uploadComplete){
      console.log("Upload complete")
      this.licenseeService.monthlyGoodToSubmit = true;
    }
    if(this.licenseeService.reportingPeriod != null) {
      this.licenseeService.reportingPeriod.thirdPartySales = this.thirdPartySalesData;
      this.licenseeService.reportingPeriod.exceptions = exceptions;
    } else {
      console.warn("licensee ReportingPeriod is null");
    }
  }

  initializeArray() {
    return new Array<ThirdPartySales>(
      new ThirdPartySales(this._ID, 1),
      new ThirdPartySales(this._ID, 2),
      new ThirdPartySales(this._ID, 3),
      new ThirdPartySales(this._ID, 4),
      new ThirdPartySales(this._ID, 5)
    );
  }
  //Destroy Subscription to prevent memory leak
  ngOnDestroy() {
    if (this.reportingPeriodSubscription !== undefined) {
      this.reportingPeriodSubscription.unsubscribe();
    }
  }

  sumDailySales() {
    // if (this.licenseeService.reportingPeriod.dailySales.length !== undefined) {
    var total = 0;
    for (var i = 0; i < this.licenseeService.reportingPeriod.dailySales.length; i++) {
      total += this.licenseeService.reportingPeriod.dailySales[i].salesAmount;
    }
    return total;
    // }
  }

  vaildationCheck(salesAmount: number, id: number) {
    if (this.licenseeService.licenseeSummaryData !== undefined &&
      this.licenseeService.licenseeSummaryData !== null &&
       salesAmount !== undefined && salesAmount !== null) {
      if (id === 1) {
          // this.thirdPartyError = false;
          return true;
      } else if (id === 2) {
        //Third Party + Catering < DailySales
        var dailySalesTotal = this.sumDailySales();

        if (salesAmount > dailySalesTotal) {
          this.thirdPartyMessage =
            "Total Catering sales must be less than or equal to the Current Monthly Sales.";
          this.thirdPartyError = true;
          console.log("id " + id + " has an error");
          return false;
        } else {
          this.thirdPartyError = false;
          return true;
        }
      } else if (id === 3) {
        //Gift Cards redeemed must be less than daily sales
         var monthlySalesTotal = this.licenseeService.licenseeSummaryData.monthlySales;

         if (salesAmount > monthlySalesTotal && monthlySalesTotal > 0) {
          this.giftCardMessage = 'Total Gift Card sales must be less than or equal to the Current Monthly Sales.';
          this.giftCardError = true;
          console.log("id " + id + " has an error");
           return false;
          } else {
        return true;
         }
      } else if (id === 4) {
        // Quantity redeemed must be whole number
        if (salesAmount.toString().indexOf('.') !== -1) {
          this.giftCardMessage = 'Quantity Redeemed must be a whole number.';
          this.giftCardError = true;
          console.log("id " + id + " has an error");
          return false;
        } else {
          this.giftCardError = false;
          return true;
        }
      } else if (id === 5) {
        // Gift Cards manually redeemed must be less than the total amount redeemed
         const totalAmountRedeemed = this.thirdPartySalesData[2].value;
         if (salesAmount > totalAmountRedeemed) {
          this.giftCardMessage = "Gift Card manually redeemed amount must be less than or equal to the Total Amount Redeemed.";
          this.giftCardError = true;
          console.log("id " + id + " has an error");
           return false;
         } else {
            return true;
         }
      }
    } else {
      if (id === 1 || id === 2) {
        this.thirdPartyError = false;
      } else {
        this.giftCardError = false;
      }
    }
  }

  getValidate75DeclinedError(){
    if(this.warning75kState === this.WARNING_75K_STATES.DECLINED_75K){
      this.thirdPartyMessage = "Please enter value less then 75K.";
      this.thirdPartyError = true;
      return true;
    }
  }

  getValidationClass(amount: number, id: number) {
    const errorClass = 'fa-times-circle text-danger';
    if(this.getValidate75DeclinedError()){
      return errorClass;
    } else if (amount === null || amount === undefined) {
      return "";
    } else {
      const className = this.vaildationCheck(amount, id) ? 'fa-check-circle text-success' : errorClass;
      this.mobileSalesValidationSymbolClass = className;
      return className;
    }
  }

  getSalesInputClass(amount: number, id: number) {
    const errorClass = 'fails';
    if(this.getValidate75DeclinedError()){
      return errorClass;
    }else if (amount === null || amount === undefined) {
      return "";
    } else {
      if (!this.vaildationCheck(amount, id)) {
        return errorClass;
      } else {
        return "";
      }
    }
  }

  get isReportingPeriodLocked(): boolean {
    return this.licenseeService.reportingPeriodLocked;
  }

  openWarningPopup(){
    this.warningPopup.open();
  }

  closeWarningPopupAndReset(licenseeId: any, productId: any) {
    this.closeWarningPopup();
    this.thirdPartySalesData = this.thirdPartySalesData.map((record)=>{
      return (record.licenseeId === licenseeId && record.productId === productId)  ? {
        ...record,
        value: null
      }: record;
    })
    this.warning75kState = this.WARNING_75K_STATES.DECLINED_75K;
    this.licenseeService.isMonthlySale75kWarning = true;
  }

  closeWarningPopupAndIgnore() {
    this.closeWarningPopup();
    this.warning75kState = this.WARNING_75K_STATES.IGNORE_WARNING;
    this.licenseeService.isMonthlySale75kWarning = false;
  }

  closeWarningPopup() {
    this.warningPopup.close();
  }
}

<div data-testid="card-upload-report" class='card'>
  <div class='card-body'>
    <div class='text-center'>
      <h5 class='text-danger'>Upload Monthly Sales Report</h5>
    </div>

    <div class="alert-container">
      <ngb-alert [hidden]="uploadStatus!==UPLOAD_STATUSES.ERROR" type="danger" [dismissible]="false">
        {{ message }}</ngb-alert>
      <ngb-alert [hidden]="uploadStatus!==UPLOAD_STATUSES.SUCCESS" type="success" [dismissible]="false">
        <strong>Success!</strong>
        {{ message }}</ngb-alert>
      <ngb-alert [hidden]="uploadStatus!==UPLOAD_STATUSES.WARNING" type="warning" [dismissible]="false">
        {{ message }}</ngb-alert>
    </div>

    <div class='container-90 text-center upload-text'>
      <p class="small">
        Please upload your monthly Point of Sale (POS) Summary Report(s) to document and validate all daily
        sales and/or
        third-party mobile sales for the selected month. This documentation is required for a successful
        submission and
        will be used to validate your transmitted total monthly sales. Any discrepancies between your POS
        Reports and
        transmitted total sales should be clearly explained to avoid a location sales audit.
        <br/><br/>
        <b>NOTE: Multiple files can be uploaded.</b>
      </p>
    </div>
    <ng-template #attachmentItem let-attachment="attachment">
      <div class="d-flex file-item" [ngClass]="{'pending':attachment.isPending}">
        <div class="file-download" [hidden]="attachment.isLoading">
          <h6 class="fileName" (click)="downloadFile(attachment)">
            <a>{{attachment.fileName || attachment.name}}</a>
          </h6>
        </div>
        <div class="loading" [hidden]="!attachment.isLoading">
          <app-btn-loader [isLoading]="attachment.isLoading"></app-btn-loader>
        </div>
        <button class="btn btn-sm delete-btn" *ngIf="getDeleteButtonEnabled()"
                (click)="deleteAttachment(attachment)"
                [disabled]="attachment.isLoading || uploadStatus === UPLOAD_STATUSES.UPLOADING">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </ng-template>

    <div class="container">
      <div class="row file-list">
        <div class="col-sm-6" *ngFor="let attachment of uploadedAttachments">
          <ng-template *ngTemplateOutlet="attachmentItem; context: {attachment: attachment}"></ng-template>
        </div>
        <div class="col-sm-6" *ngFor="let attachment of pendingAttachments">
          <ng-template *ngTemplateOutlet="attachmentItem; context: {attachment: attachment}"></ng-template>
        </div>
      </div>
      <div class="row upload-form">
        <div class='col-lg input-container'>
          <input class="form-control" id="file-upload" type="file" (change)="previewFile()" #fileInput
                 multiple="multiple" [disabled]="getIsUploadInputDisabled()"/>
          <div class='file-path-wrapper'>
            <input class='file-path form-control' type='text' [(ngModel)]="pendingAttachments[pendingAttachments.length - 1] && pendingAttachments[pendingAttachments.length - 1].fileName"
                   placeholder='Select a file' readonly [disabled]="getIsUploadInputDisabled()"/>
          </div>
        </div>
        <div class='col-lg button-container'>
          <button data-testid="btn-upload-report" class="btn btn-success text-white btn-upload upload-button" (click)="postLicenseeAttachment()"
                  [disabled]="!pendingAttachments.length || isLoading || getIsReportingPeriodDisabled()">
            <app-btn-loader [isLoading]="isLoading"></app-btn-loader>
            <i class="fas fa-upload" [hidden]="isLoading"></i>
            <span>Upload</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { LicenseeService } from "../services/licensee.service";

@Component({
  selector: 'app-licensee-confirmation',
  templateUrl: './licensee-confirmation.component.html',
  styleUrls: ['./licensee-confirmation.component.scss']
})
export class LicenseeConfirmationComponent implements OnInit {
  month: string;
  year: string;
  dateConfirmation: boolean = false;
  partnerConfirmation: boolean = false;

  constructor(private licenseeService: LicenseeService) { }

  ngOnInit() {
    //Subscription to ReportingPeriod Observable in parent
    this.licenseeService.reportingPeriodObservable.subscribe(payload => { 
      this.month = '';
      this.year = '';

      if(payload !== null) {
        const date = new Date(payload.year, payload.calendarMonth - 1, 1);
        this.month = date.toLocaleString('default', { month: 'long' });
        this.year = payload.year.toString();
      }
    });
  }

  dateConfirmationClicked(event: any) {
    this.dateConfirmation = event;
    this.updateConfirmationStatus();
  }

  partnerConfirmationClicked(event: any) {
    this.partnerConfirmation = event;
    this.updateConfirmationStatus();
  }

  updateConfirmationStatus() {
    if(this.dateConfirmation && this.partnerConfirmation) {
      this.licenseeService.confirmationGoodToSubmit = true;
    } else {
      this.licenseeService.confirmationGoodToSubmit = false;
    }
  }

}

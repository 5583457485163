<div class="card">
  <div class="card-body">
    <ngb-alert
      class="success-alert pending-alert-message"
      *ngIf="successMessage"
      type="success"
      (close)="successMessage = null"
      data-testid="pending-success-message"
      ><strong>Success!</strong> {{ successMessage }}</ngb-alert
    >
    <ngb-alert
      class="error-alert pending-alert-message"
      *ngIf="errorMessage"
      type="danger"
      (close)="errorMessage = null"
      data-testid="pending-error-message"
      ><strong>Error!</strong> {{ errorMessage }}</ngb-alert
    >
    <div class="container header-container">
      <div class="row pending-header" data-testid="pending-header">
        <h4 class="text-danger">Pending Submissions Review</h4>
      </div>
    </div>
    <app-loader [isLoading]="!isDataAvail" class="text-center"></app-loader>
    <div class="container table-container" [hidden]="isLoading || !isDataAvail">
      <div class="row pending-table-header" data-testid="pending-table-header">
        <div class="col-5">
          <div class="row">
            <div class="col-6">
              <span class="table-label"> Location Number </span>
            </div>
            <div class="col-6">
              <span class="table-label"> Date Submitted </span>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="row">
            <div class="col-6">
              <span class="table-label"> Warning Data </span>
            </div>
            <div class="col-6">
              <span class="table-label"> View </span>
            </div>
          </div>
        </div>
      </div>
      <div class="border-line"></div>
      <div class="pending-table-container">
        <table class="table borderless pending-table">
          <tr
            *ngFor="let log of shortWarningLogs; let i = index"
            class="row pending-table-row"
            data-testid="pending-table-row"
          >
            <div class="col-5">
              <div class="row">
                <td class="pending-table-cell col-6 pending-location-cell" data-testid="pending-location-cell">
                  {{ log.cfaLocationNumber }}
                </td>
                <td class="pending-table-cell col-6 pending-date-cell" data-testid="pending-date-cell">
                  {{ formatDate(log.dateSubmitted) }}
                </td>
              </div>
            </div>
            <div class="col-7">
              <div class="row">
                <td
                  class="pending-table-cell col-6 pending-warning-cell {{
                    getWarningClass(log.ruleId)
                  }}"
                  data-testid="pending-warning-cell"
                >
                  {{ log.logRule }}
                </td>
                <td
                  class="pending-view-cell col-6"
                  data-testid="pending-view-cell"
                >
                  <button
                    class="pending-view-button"
                    (click)="openApprovalModal(log)"
                    data-testid="pending-view-button"
                  >
                    <img
                      src="/assets/img/icons/white-eye.png"
                      alt="view"
                      class="pending-eye-icon"
                    />
                    View
                  </button>
                </td>
              </div>
            </div>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Approval Modal -->
<div
  class="modal"
  id="approvalModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="approvalModalLabel"
  aria-hidden="true"
  data-testid="approval-modal"
>
  <div class="modalBackdrop hidden" id="denialModalBackdrop"></div>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content approval-modal-content" data-testid="approval-modal-content">
      <div class="approval-modal-header" data-testid="approval-modal-header">
        <div class="button-container">
          <button
            type="button"
            class="approval-close-btn"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeApprovalModal()"
            data-testid="approval-modal-close-button"
          >
            <span aria-hidden="true">
              <i class="fas fa-times approval-close-icon"></i>
            </span>
          </button>
        </div>
        <h3 class="approval-modal-title">
          Request to Edit Total Monthly Sales
        </h3>
        <span class="approval-modal-subtitle">
          Licensee has requested to update the total amount of the monthly
          sales.
        </span>
      </div>
      <div class="modal-body">
        <!-- <div class="approval-location-info modal-info-block"></div> -->
        <div class="approval-location-info modal-info-block row">
          <div class="approval-location-info-text col-12">
            <h3
              class="approval-location-name"
              data-testid="approval-modal-licensee-name"
            >
              {{ warningDetails.licenseeName }}
            </h3>
            <span
              class="modal-text-gray"
              data-testid="approval-modal-licensee-number"
              >{{ warningDetails.licenseeNumber }}</span
            >
          </div>
        </div>
        <div class="approval-monthly-sales modal-info-block"
        *ngIf="warningDetails.newAmount">
          <h4
            class="text-danger monthly-sales-title"
            data-testid="approval-modal-monthly-sales"
          >
            Total Monthly Sales - {{ warningDetails.currentDate }}
          </h4>
          <div class="monthly-sales-info row">
            <div
              class="approval-amount modal-text-gray col-5"
              *ngIf="warningDetails.originalAmount"
              data-testid="approval-modal-original-amount"
            >
              Original Amount:
              <span class="modal-text-black pad-left">{{
                currency.symbol + warningDetails.originalAmount
              }}</span>
            </div>
            <div
              class="approval-amount modal-text-gray col-7"
              *ngIf="warningDetails.newAmount"
              data-testid="approval-modal-new-amount"
            >
              New Amount:
              <span class="modal-text-red pad-left">{{
                currency.symbol + warningDetails.newAmount
              }}</span>
            </div>
          </div>
        </div>
        <div class="approval-contact-info modal-info-block" *ngIf="warningDetails.contactName">
          <h4 class="text-danger contact-info-title">Contact Information</h4>
          <div class="contact-info-info row">
            <div
              class="contact-info-name modal-text-gray col-5"
              data-testid="approval-modal-contact-name"
            >
              Name:
              <span class="modal-text-black pad-left">{{
                warningDetails.contactName ? warningDetails.contactName : "N/A"
              }}</span>
            </div>
            <div
              class="contact-info-email modal-text-gray col-7"
              data-testid="approval-modal-contact-email"
            >
              Email:
              <span class="modal-text-black pad-left">{{
                warningDetails.contactEmail
                  ? warningDetails.contactEmail
                  : "N/A"
              }}</span>
            </div>
          </div>
          <div
            class="contact-info-reason row modal-text-gray"
            *ngIf="warningDetails.reason"
          >
            <span class="col-1">Reason:</span>
            <div
              class="modal-text-black col-11 shift-right"
              data-testid="approval-modal-reason"
            >
              {{ warningDetails.reason }}
            </div>
          </div>
        </div>
        <div
          class="approval-sales-document modal-info-block"
          *ngIf="salesDocuments.length"
          data-testid="approval-modal-sales-document-block"
        >
          <h4 class="text-danger sales-document-title">
            Monthly Sales Documents
          </h4>
          <div
            class="download-document-row row"
            *ngFor="let salesDocument of salesDocuments"
            data-testid="approval-modal-sales-document"
          >
            <div class="col-9">
              <div
                class="approval-document-link"
                (click)="viewDocument(salesDocument)"
                data-testid="approval-modal-document-filename"
              >
                {{ salesDocument.fileName }}
              </div>
            </div>
            <div class="col-3">
              <div class="approval-file-buttons row">
                <button
                  class="approval-modal-button approval-view-button col-6"
                  (click)="viewDocument(salesDocument)"
                  data-testid="approval-modal-view-button"
                >
                  <img
                    src="/assets/img/icons/eye.png"
                    alt="view"
                    class="eye-icon"
                    *ngIf="!salesDocument.isViewing"
                  />
                  <app-btn-loader
                    class="approval-modal-loader"
                    [isLoading]="salesDocument.isViewing"
                  ></app-btn-loader>
                  <span>View</span>
                </button>
                <button
                  class="approval-modal-button approval-download-button col-6"
                  (click)="downloadDocument(salesDocument)"
                  data-testid="approval-modal-download-button"
                >
                  <img
                    src="/assets/img/icons/download.png"
                    alt="download"
                    class="download-icon"
                    *ngIf="!salesDocument.isDownloading"
                  />
                  <app-btn-loader
                    class="approval-modal-loader"
                    [isLoading]="salesDocument.isDownloading"
                  ></app-btn-loader>
                  <span>Download</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="warning-info modal-info-block"
          *ngIf="selectedWarnings.length"
          data-testid="approval-modal-warning-info"
        >
          <div
            class="warning-info-text-container"
            *ngFor="let warning of selectedWarnings; let warningIndex = index"
            data-testid="approval-modal-warning"
          >
            <div class="divider-line"></div>
            <h4
              class="text-danger warning-info-text"
              [ngClass]="{ 'blue-text': warning.ruleId == 7 }"
            >
              {{ warning.logRule }}
            </h4>
          </div>
          <div class="divider-line"></div>
        </div>
        <div class="approve-deny-buttons row">
          <div class="col-5"></div>
          <div class="col-7 buttons-container">
            <button
              class="approve-button a-d-button"
              (click)="updateSubmissionStatus(3)"
              data-testid="approval-modal-approve-button"
              [disabled]="isReadOnlyAdmin"
            >
              <i class="fas fa-check a-d-icon"></i>
              Approve
            </button>
            <button
              class="deny-button a-d-button"
              (click)="handleDeny()"
              data-testid="approval-modal-deny-button"
              [disabled]="isReadOnlyAdmin"
            >
              <i class="fas fa-times a-d-icon"></i>
              Deny
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Reason Modal -->
<div
  class="modal"
  id="reasonModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="approvalModalLabel"
  aria-hidden="true"
  data-testid="reason-modal"
>
  <div class="modal-dialog modal-lg reason-modal-dialog" role="document">
    <div class="modal-content approval-modal-content" data-testid="reason-modal-content">
      <div class="approval-modal-header">
        <div class="button-container">
          <button
            type="button"
            class="approval-close-btn"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeReasonModal()"
            data-testid="reason-modal-close-button"
          >
            <span aria-hidden="true">
              <i class="fas fa-times approval-close-icon"></i>
            </span>
          </button>
        </div>
        <h3 class="approval-modal-title">Reason for Adjustment Denial</h3>
      </div>
      <div class="modal-body">
        <div class="reason-select-block reason-modal-info-block row">
          <div class="col-3"></div>
          <div class="col-6">
            <select
              data-testid="denial-reason-select"
              class="form-control form-select"
              [(ngModel)]="denialReason"
            >
              <option
                *ngFor="let reason of reasonsList; let i = index"
                [value]="i"
              >
                {{ reason }}
              </option>
            </select>
          </div>
          <div class="col-3"></div>
        </div>
        <div class="reason-submit reason-modal-info-block text-center">
          <button class="approve-button a-d-button" (click)="submitDenialReason()" data-testid="denial-reason-submit-button" >Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthenticationService } from "../../authentication/authentication.service";
import { I18nService } from "../../i18n.service";
import { SubscriptionLike, timer } from "rxjs";
import { LicenseeService } from "@app/services/licensee.service";
import { OktaAuthService } from '@okta/okta-angular';


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuHidden = true;
  isAdmin: boolean;
  isReadOnlyAdmin: Boolean = false;
  cfaLocationNumber: string;
  cfaLocationName: string;
  cfaRoute: boolean;

  private licenseeInfoSubscription: SubscriptionLike;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private i18nService: I18nService,
    private licenseeService: LicenseeService,
    private oktaAuth: OktaAuthService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === "/licensee") {
          this.cfaRoute = false;
        } else {
          this.cfaRoute = true;
        }
      }
    });
  }

  ngOnInit() {
    //Subscription to ReportingPeriod Observable in parent
    this.licenseeInfoSubscription = this.licenseeService.licenseeObservable.subscribe(
      payload => {
        if (payload !== null) {
          this.cfaLocationNumber = payload.cfaLocationNumber;
          this.cfaLocationName = payload.cfaLocationName;
        }
      }
    );
    this.licenseeService.adminReadOnlyObservable.subscribe(payload => {
      if(payload != null) {
        this.isReadOnlyAdmin = payload;
      } else {
        this.isReadOnlyAdmin = false;
      }
    })
  }

  ngOnDestroy() {
    if (this.licenseeInfoSubscription !== undefined) {
      this.licenseeInfoSubscription.unsubscribe();
    }
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.oktaAuth.logout();
    const timerObject = timer(600).subscribe(() => {
      this.oktaAuth.loginRedirect();
    });
  }

  login() {
    this.oktaAuth.loginRedirect();
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string | null {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.username : null;
  }
}

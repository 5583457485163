export class Summary {
    thirdPartySales: number = 0;
    giftCardSales: number = 0;
    cateringSales: number = 0;
    monthlySales: number = 0;
    fiscalFee: number = 0;
    quarterlySales: number = 0;
    yearToDateTotal: number = 0;


}

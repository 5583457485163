import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { LicenseeService } from "../services/licensee.service";
import { CurrentDate } from "@app/model/CurrentDate.model";
import { SubscriptionLike } from "rxjs";
import { AuthenticationService } from "@app/core/authentication/authentication.service";
import { OktaAuthService } from '@okta/okta-angular';
import jwtDecode from 'jwt-decode';
import { Guid } from "guid-typescript";
import { Licensee } from "@app/model/Licensee.model";
import { HttpHeaders } from '@angular/common/http';
import {AccessToken} from '@app/model/AccessToken.model';
import { environment } from '@env/environment';

@Component({
  selector: "app-cfa",
  templateUrl: "./cfa.component.html",
  styleUrls: ["./cfa.component.scss"]
})
export class CfaComponent implements OnInit, OnDestroy {
  private submissionReportDateSubscription: SubscriptionLike;
  isLoading = false;
  isAdmin = false;
  isReadOnlyAdmin = false;
  initialLoading: boolean;
  currentDate = new CurrentDate();
  modalBackdrop: HTMLElement;
  redirectMessage: string;

  constructor(
    public licenseeService: LicenseeService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private oktaAuth: OktaAuthService
  ) {}

  initRedirectMessage(countDownSeconds: number) {
    const timer = setInterval(() => {
      if (countDownSeconds > 0) {
        this.redirectMessage = `The Licensee Sales Portal has moved! Please wait while you’re redirected...<br>
        Haven’t been redirected yet? Click <a href=${environment.lspReactAppUrl}>here</a>. ${countDownSeconds}s`;
        countDownSeconds--;
      }
      else {
        clearInterval(timer);
        window.location.replace(environment.lspReactAppUrl)
      }
    }, 1000);
  }

  ngOnInit() {
    this.initRedirectMessage(7);
    // this.initialLoading = true;
    // this.checkUserRole().then(() => {
    //   // Subscription to ReportingPeriod Observable in parent
    //   this.submissionReportDateSubscription = this.licenseeService.submissionReportDateObservable.subscribe(payload => {
    //     if (payload === null) {
    //       this.currentDate.month = this.licenseeService.getCurrentMonth();
    //       this.currentDate.year = this.licenseeService.getCurrentYear();
    //     } else {
    //       this.currentDate = payload;
    //     }
    //     this.getSubmissionReport(this.currentDate);
    //   });
    // });
    //
    //
    //
    // this.modalBackdrop = document.getElementById("modalBackdrop");
    // this.modalBackdrop.style.display = "none";
    //
    // this.licenseeService.getIsModalActive().subscribe(value => {
    //   if (value == true) {
    //     this.modalBackdrop.style.display = "block";
    //   } else {
    //     this.modalBackdrop.style.display = "none";
    //   }
    // })
  }

  // Destroy Subscription to prevent memory leak
  ngOnDestroy() {
    if (this.submissionReportDateSubscription !== undefined) {
      this.submissionReportDateSubscription.unsubscribe();
    }
  }
// potential to call this recursively. If the licenseeservice does not have a licensee data saved,
// call the init function. which passes this function in. If it does, just
  async checkUserRole() {
    const accessToken = await this.oktaAuth.getAccessToken();
    const idToken = await this.oktaAuth.getIdToken();

    const decodedAccessToken = jwtDecode(accessToken) as AccessToken;
    const decodedIdToken = jwtDecode(idToken) as any;
    const uniqueId = decodedAccessToken.cfa_guid as Guid;

    const licensee = new Licensee();

    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      }),
      reportProgress: true
    };

    this.licenseeService.httpOptions = httpOptions;
    this.licenseeService.token =decodedAccessToken;
    this.licenseeService.checkTokenExpiration();

    // check if passed-in guid is in the DB licensee table.
    // if yes, redirect to the licensee page
    // if NO, check if passed-in email exists in the adminUsers table
    // if yes, don't redirect anywhere
    // if NO, redirect to CFA HOME!!!


    if(uniqueId != undefined && uniqueId != null) {
      // console.warn('uniqueid NOT NULL!')
      this.licenseeService.getLicensee(uniqueId).subscribe((result: any) => {
        licensee.licenseeId = result.licenseeId;
        if (licensee.licenseeId != 0) {
          this.initialLoading = false;
          this.authenticationService.credentials.isAdmin = false;
          this.licenseeService.licenseeInfo.licenseeId = licensee.licenseeId;
          this.isAdmin = this.authenticationService.credentials.isAdmin;
          this.authenticationService.credentials.username = decodedIdToken.cfa_name;
          this.authenticationService.credentials.token = idToken;
          this.authenticationService.observableCredentials.next(this.authenticationService.credentials);
          if (!this.authenticationService.credentials.isAdmin) {
            licensee.licenseeId = result.licenseeId;
            licensee.companyId = result.companyId;
            licensee.accountNumber = result.accountNumber;
            licensee.cfaLocationName = result.cfaLocationName;
            licensee.cfaLocationNumber = result.cfaLocationNumber;
            licensee.emailAddress = result.emailAddress;
            licensee.percentage = result.percentage;
            licensee.hasthirdpartysales = result.offersThirdPartySales;
            licensee.countryCode = result.countryCode;
            licensee.catering = result.catering;
            licensee.giftCards = result.giftCards;
            licensee.tempShutdown = result.tempShutdown;
            licensee.allowsAdjustments = result.allowsAdjustments;
            this.licenseeService.setLicenseeInfo(licensee);
            this.router.navigate(['/licensee'], { replaceUrl: true });
          }
        } else {
          // console.warn("Licensee ID returned as 0");
          if(this.licenseeService.checkIfAdmin(decodedAccessToken)) {
              this.initialLoading = false;
              this.authenticationService.credentials.isAdmin = true;
              this.authenticationService.credentials.username = decodedIdToken.firstName;
              this.isAdmin = true;
              this.isReadOnlyAdmin = this.licenseeService.checkIfReadOnlyAdmin(decodedAccessToken);
              this.licenseeService.setReadOnlyAdmin(this.isReadOnlyAdmin);
              // get this info to header. 
            } else {
              console.error('User Authentication Error1! redirecting to CFA Home...');
              window.location.href = 'http://www.cfahome.com';
            }
        }
      });
    } else {
      // console.warn("Unique ID is either null or undefined! Checking if admin...");
      if(this.licenseeService.checkIfAdmin(decodedAccessToken)) {
          this.initialLoading = false;
          this.authenticationService.credentials.isAdmin = true;
          this.authenticationService.credentials.username = decodedIdToken.firstName;
          this.isAdmin = true;
          this.isReadOnlyAdmin = this.licenseeService.checkIfReadOnlyAdmin(decodedAccessToken);
        } else {
          console.error('User Authentication Error2! redirecting to CFA Home...');
          window.location.href = 'http://www.cfahome.com';
        }
    }
  }


  async getSubmissionReport(date: CurrentDate) {
    const accessToken = await this.oktaAuth.getAccessToken();
    const decodedAccessToken = jwtDecode(accessToken) as AccessToken;
    if(!this.licenseeService.checkIfAdmin(decodedAccessToken)){
      this.licenseeService.allSubmissionReports = [];
      return this.licenseeService.setSubmissionReport([]);
    }
    this.licenseeService.getSubmissionCounts(date).subscribe((submissions: any) => {
      this.licenseeService.allSubmissionReports = submissions;
      this.licenseeService.setSubmissionReport(submissions);
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder, Validators, CheckboxControlValueAccessor } from "@angular/forms";
import { finalize } from "rxjs/operators";

import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService } from '@app/core';
import { LicenseeService } from '../services/licensee.service';
import { OktaAuthService } from '@okta/okta-angular';


const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  version: string = environment.version;
  error: string;
  loginForm: UntypedFormGroup;
  isLoading = false;
  isAuthenticated: boolean;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private authenticationService: AuthenticationService,
    private licenseeService: LicenseeService,
    private oktaAuth: OktaAuthService
  ) {
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean)  => this.isAuthenticated = true
    );

  }

  async ngOnInit() {
    const token = await this.oktaAuth.getIdToken();
    const acctoken = await this.oktaAuth.getAccessToken();
    this.isAuthenticated =  await this.oktaAuth.isAuthenticated();

    console.log(acctoken);

    console.log(this.isAuthenticated);

  }

  login() {
    this.oktaAuth.loginRedirect('/cfa');
  }

  logout() {
    this.oktaAuth.logout('/login');
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }
}

import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { LicenseeService } from '../services/licensee.service';
import { SubscriptionLike } from 'rxjs';
import * as c3 from 'c3';
import { Summary } from '../model/Summary.model';

@Component({
  selector: 'app-licensee-summary',
  templateUrl: './licensee-summary.component.html',
  styleUrls: ['./licensee-summary.component.scss']
})
export class LicenseeSummaryComponent implements OnInit, OnDestroy {
  @Input()
  isLoading: boolean;
  @ViewChild('donutChart')
  private chartRef: any;

  private licenseeSummariesSubscription: SubscriptionLike;
  private licenseeReportingPeriodSubscription: SubscriptionLike;
  private parentNativeElement: any;

  isInternational = false;
  currency = {iso: ''};
  licenseeSummaryData = new Summary();
  dailySales: number;
  donutChartData: Array<number>;
  donutChartLabels = ['Daily POS', 'Third Party Mobile', 'Catering'];
  donutChartColors = ['#3EB1C8', '#994878', '#FFB549'];
  donutChartTitle = '';
  foodServiceProvider: string ="";

  reportStatus: number;

  constructor(private licenseeService: LicenseeService, element: ElementRef) {
    this.parentNativeElement = element.nativeElement;
  }

  ngOnInit() {
    this.reportStatus = null;
    this.currency = this.licenseeService.currency;
    this.isInternational = this.licenseeService.isInternational;

  // Subscription to ReportingPeriod Observable in parent
    this.licenseeSummariesSubscription = this.licenseeService.licenseeSummariesDataObservable.subscribe(payload => {
      if (payload !== null) {
        this.licenseeSummaryData = payload;
        console.log("payload", payload);
        if (payload.monthlySales === 0 && payload.thirdPartySales === 0 && payload.cateringSales === 0) {
          this.donutChartTitle = 'EAT MOR CHIKIN';
          this.donutChartData = [];
        } else {
          this.donutChartTitle = ' ';
          this.dailySales =
            Math.round((payload.monthlySales - payload.thirdPartySales - payload.cateringSales) * 100) / 100;
          this.donutChartData = [this.dailySales, payload.thirdPartySales, payload.cateringSales];
        }
        this.createChart(this.donutChartLabels, this.donutChartData, this.donutChartColors, this.donutChartTitle);
      }
    });
    this.licenseeReportingPeriodSubscription = this.licenseeService.reportingPeriodObservable.subscribe(payload => {
      console.log("new load", payload);
      if (payload.foodServiceProvider) {
        this.foodServiceProvider = payload.foodServiceProvider; 
      } else {
        this.foodServiceProvider = "";
      }
      
    })
  }

  // Destroy Subscription to prevent memory leak
  ngOnDestroy() {
    if (this.licenseeSummariesSubscription !== undefined) {
      this.licenseeSummariesSubscription.unsubscribe();
    }
  }

  createChart(legend: Array<string>, data: Array<number>, colors: Array<string>, title: string) {
    const chart = c3.generate({
      bindto: '#chart',
      data: {
        rows: [legend, data],
        type: 'donut'
      },
      donut: {
        title: title,
        label: {
          show: false
        }
      },
      size: {
        height: 250
      },
      color: {
        pattern: colors
      }
    });
  }

  getStatus() {
    if (this.licenseeService.reportingPeriod !== null) {
      switch (this.licenseeService.reportingPeriod.statusID) {
        case 0:
          return 'Not started';
        case 1:
          return 'Draft';
        case 2:
          return 'In Review';
        case 3:
          return 'Approved';
      }
    } else {
      return '';
    }
  }

  getIconClass() {
    if (this.licenseeService.reportingPeriod !== null) {
      switch (this.licenseeService.reportingPeriod.statusID) {
        case 0:
        return '';
        case 1:
          return 'fa-pencil-alt text-primary';
        case 2:
          return 'fa-hourglass-end text-warning';
        case 3:
          return 'fa-thumbs-up text-success';
      }
    } else {
      return '';
    }
  }
}

<div data-testid="card-thrid-party" class='card'>
  <div class='card-body'>
    <div class="third-party">
        <h5 class='text-center text-danger third-party'>Monthly Third Party Mobile Sales</h5>
        <ng-template #monthlyThirdPartyMobileMoreDetails>
          <button type="button" (click)="monthlyMobilePopover.close()" class="closeButton">X</button>
          <i class="fas fa-info-circle"></i>
          <b>Monthly Third-Party Mobile App Sales</b>
          <ul>
            <li>(This will likely exist in situations where you are approved to sell via mobile platforms that do not directly integrate with your Restaurant POS system.)</li>
            <li><b>This amount will be added to your POS Daily Sales</b> to determine your License Fee owed to Chick-fil-A for the given month.</li>
            <li><b>These sales must be supported by uploaded documentation</b> in addition to your POS Sales Reports.</li>
          </ul>
        </ng-template>
        <p class="small text-center">
          Enter the total of any <span class="bold">monthly</span> third-party mobile app sales that are
          <span class="bold">NOT already captured in your “POS Daily Sales.”</span>
          If you do not leverage non-integrated mobile ordering platforms, enter $0.00.
          <i data-testid="dtl-third-party" class="fas fa-info-circle" [ngbPopover]="monthlyThirdPartyMobileMoreDetails" [autoClose]="false"
             triggers="manual" #monthlyMobilePopover="ngbPopover" (click)="monthlyMobilePopover.open()"
             popoverClass="detail-popover" placement="bottom-right"
             [ngbPopover]="monthlyThirdPartyMobileMoreDetails"></i>
        </p>
        <div class="monthly-sales">
          <app-loader [isLoading]="isLoading" class="text-center"></app-loader>
          <div *ngFor="let item of thirdPartySalesData; let r = index">
            <div [hidden]="isLoading">
              <div class="form-group row" *ngIf="thirdPartySalesData[r].productId === 1">
                <div class="col-sm-5 label-container">
                  <label class="col-sm-form-label">Third Party Mobile Sales:</label>
                </div>
                <div class="col-sm-6 input-container ">
                  <input class="form-control" type="text" data-testid="input-third-party-sales"
                         [ngModel]="thirdPartySalesData[r].value | currency:currency.iso:'symbol-narrow':'1.2-2'"
                    (ngModelChange)="updateThirdPartySales($event, thirdPartySalesData[r].licenseeId, thirdPartySalesData[r].productId);"
                    [ngClass]="getSalesInputClass(thirdPartySalesData[r].value,thirdPartySalesData[r].productId)"
                    (focus)="onFocus($event, thirdPartySalesData[r].licenseeId, thirdPartySalesData[r].productId)"
                    (blur)="updateThirdPartySales($event, thirdPartySalesData[r].licenseeId, thirdPartySalesData[r].productId)"
                    (input)="setCorrectCursorPlacement($event); updateThirdPartySales($event, thirdPartySalesData[r].licenseeId, thirdPartySalesData[r].productId);"
                    [disabled]="(licenseeService.reportingPeriod != null &&
                        licenseeService.reportingPeriod.exempt)
                      || isReportingPeriodLocked
                      || warning75kState === WARNING_75K_STATES.MORE_THAN_75K
                    "
                    required ng-pattern="/^-?[0-9][^\.]*$/" >
                    <button class="input-warning-indicator small"
                            triggers="manual" #warningPopup="ngbPopover"
                            [autoClose]="false"
                            (click)="openWarningPopup()"
                            [ngClass]="{'d-none': warning75kState !== WARNING_75K_STATES.MORE_THAN_75K}"
                            placement="top" [ngbPopover]="warningMessage"
                            popoverClass="warning-75k-popover"
                            container="body">
                      <i class="fas fa-exclamation-triangle"></i> Warning
                      <ng-template #warningMessage>
                        <div class="warning-75k small">
                          <div class="message-container">
                            <div class="message">
                              <i class="fas fa-exclamation-triangle"></i>
                              Sales amount exceeds over $75k, are you sure you want to proceed?
                              <div class="action-button-container">
                                <button class="action-button no"  (click)="closeWarningPopupAndReset(thirdPartySalesData[r].licenseeId, thirdPartySalesData[r].productId)">
                                  <i class="fas fa-times"></i> NO
                                </button>
                                <button class="action-button yes" (click)="closeWarningPopupAndIgnore()">
                                  <i class="fas fa-check"></i> YES
                                </button>
                              </div>
                            </div>
                            <button class="close-button">
                              <i class="fas fa-times" (click)="closeWarningPopup()"></i>
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </button>
                </div>
                <div class="col-sm-1 validation-check text-left">
                  <i data-testid="chk-third-party-success" [ngClass]="getValidationClass(thirdPartySalesData[r].value,thirdPartySalesData[r].productId)" class="fas"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>

<div data-testid="card-catering-sales" *ngIf="licenseeService.licenseeInfo.catering || licenseeService.licenseeInfo.giftCards" class='card'>
  <div class='card-body'>

    <h5 *ngIf="licenseeService.licenseeInfo.catering" class='text-center text-danger'>Monthly Catering Sales</h5>
    <p *ngIf="licenseeService.licenseeInfo.catering" class="small text-center">
      If you are approved to cater Chick-fil-A products,
      please record your total monthly catering sales in this section.
      If you do not have catering sales in the given month, enter $0.00.
      <i data-testid="dtl-catering-sales" class="fas fa-info-circle" [ngbPopover]="monthlyCateringSalesDetails" [autoClose]="false"
         triggers="manual" #monthlyCateringPopover="ngbPopover" (click)="monthlyCateringPopover.open()" placement="top-right"
         popoverClass="detail-popover"
      ></i>
      <ng-template #monthlyCateringSalesDetails>
        <button type="button" (click)="monthlyCateringPopover.close()" class="closeButton">X</button>
        <i class="fas fa-info-circle"></i>
        <b>Monthly Catering Sales</b>
        <ul>
          <li>
            <b>
              This amount will NOT be added to your POS Daily Sales
            </b>
            to determine your License Fee owed to Chick-fil-A for the given month.
          </li>
          <li>
            <b>
              These sales should have already been included in your POS Daily
            </b>
            Sales amounts and have been recognized as sales through your restaurant POS system at the date of the sale for purposes of License Fee Calculation.
          </li>
        </ul>
      </ng-template>
    </p>

    <div *ngIf="licenseeService.licenseeInfo.catering" class="monthly-sales">
      <div class="alert-container">
        <ngb-alert class="error" [hidden]="!thirdPartyError" type="danger" [dismissible]="false"><strong>Wait!</strong>
        {{ thirdPartyMessage }}</ngb-alert>
      </div>
      <app-loader [isLoading]="isLoading" class="text-center"></app-loader>
      <div *ngFor="let item of thirdPartySalesData; let j = index">
        <div [hidden]="isLoading">
          <div class="form-group row" *ngIf="thirdPartySalesData[j].productId === 2">
            <div class="col-sm-5 label-container">
              <label class="col-sm-form-label">Total Catering Sales:</label>
            </div>
            <div class="col-sm-6 input-container ">
              <input data-testid="input-catering-sales" class="form-control" type="text" [ngModel]="thirdPartySalesData[j].value | currency:currency.iso:'symbol-narrow':'1.2-2'"
                (ngModelChange)="updateThirdPartySales($event, thirdPartySalesData[j].licenseeId, thirdPartySalesData[j].productId);;" [ngClass]="getSalesInputClass(thirdPartySalesData[j].value,thirdPartySalesData[j].productId)"
                (focus)="onFocus($event, thirdPartySalesData[j].licenseeId, thirdPartySalesData[j].productId)" (blur)="updateThirdPartySales($event, thirdPartySalesData[j].licenseeId, thirdPartySalesData[j].productId)"
                (input)="setCorrectCursorPlacement($event); updateThirdPartySales($event, thirdPartySalesData[j].licenseeId, thirdPartySalesData[j].productId);"
                [disabled]="(licenseeService.reportingPeriod != null && licenseeService.reportingPeriod.exempt) || isReportingPeriodLocked"
                required ng-pattern="/^-?[0-9][^\.]*$/">
            </div>
            <div class="col-sm-1 validation-check text-left">
              <i data-testid="chk-catering-success" [ngClass]="getValidationClass(thirdPartySalesData[j].value,thirdPartySalesData[j].productId)" class="fas"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h5 *ngIf="!isInternational && licenseeService.licenseeInfo.giftCards" class='text-center text-danger'>Monthly Gift Card Sales</h5>
    <div *ngIf="!isInternational && licenseeService.licenseeInfo.giftCards" class="giftcard-sales">
        <p class="small text-center">Applies to locations that are accepting Chick-fil-A gift cards. Enter required information below. If you do not redeem Gift Cards, enter “0” in each field.</p>
      <div class="alert-container">
        <ngb-alert class="error" [hidden]="!giftCardError" type="danger" [dismissible]="false"><strong>Wait!</strong> {{
        giftCardMessage }}</ngb-alert>
      </div>
      <app-loader [isLoading]="isLoading" class="text-center"></app-loader>
      <div *ngFor="let item of thirdPartySalesData; let i = index">
        <div [hidden]="isLoading">
          <div class="form-group row" *ngIf="thirdPartySalesData[i].productId === 3">
            <div class="col-sm-5 label-container">
              <label class="col-sm-form-label">Total Amount Redeemed:</label>
            </div>
            <div class="col-sm-6 input-container">
              <input class="form-control" type="text" [ngModel]="thirdPartySalesData[i].value | currency:currency.iso:'symbol-narrow':'1.2-2'"
                (ngModelChange)="updateThirdPartySales($event, thirdPartySalesData[i].licenseeId, thirdPartySalesData[i].productId);" [ngClass]="getSalesInputClass(thirdPartySalesData[i].value,thirdPartySalesData[i].productId)"
                (focus)="onFocus($event, thirdPartySalesData[i].licenseeId, thirdPartySalesData[i].productId)" (blur)="updateThirdPartySales($event, thirdPartySalesData[i].licenseeId, thirdPartySalesData[i].productId)"
                (input)="setCorrectCursorPlacement($event); updateThirdPartySales($event, thirdPartySalesData[i].licenseeId, thirdPartySalesData[i].productId);"
                     [disabled]="(licenseeService.reportingPeriod != null && licenseeService.reportingPeriod.exempt) || isReportingPeriodLocked"
                required ng-pattern="/^-?[0-9][^\.]*$/">
            </div>
            <div class="col-sm-1 validation-check text-left">
              <i [ngClass]="getValidationClass(thirdPartySalesData[i].value,thirdPartySalesData[i].productId)" class="fas"></i>
            </div>
          </div>
          <div class="form-group row" *ngIf="thirdPartySalesData[i].productId === 4">
            <div class="col-sm-5 label-container">
              <label class="col-sm-form-label">Quantity Redeemed:</label>
            </div>
            <div class="col-sm-6 input-container">
              <input class="form-control" type="number" min="0" [(ngModel)]="thirdPartySalesData[i].value"
                (ngModelChange)="thirdPartySalesData[i].value = $event; updateThirdPartySalesNoEvent();" required ng-pattern="/^[0-9]*$/"
                     [disabled]="(licenseeService.reportingPeriod != null && licenseeService.reportingPeriod.exempt) || isReportingPeriodLocked"
                [ngClass]="getSalesInputClass(thirdPartySalesData[i].value,thirdPartySalesData[i].productId)">
            </div>
            <div class="col-sm-1 validation-check text-left">
              <i [ngClass]="getValidationClass(thirdPartySalesData[i].value,thirdPartySalesData[i].productId)" class="fas"></i>
            </div>
          </div>
          <div class="form-group row" *ngIf="thirdPartySalesData[i].productId === 5">
            <div class="col-sm-5 label-container">
              <label class="col-sm-form-label">Manually Redeemed:</label>
            </div>
            <div class="col-sm-6 input-container">
              <input class="form-control" type="text" [ngModel]="thirdPartySalesData[i].value | currency:currency.iso:'symbol-narrow':'1.2-2'"
                (ngModelChange)="thirdPartySalesData[i].value; updateThirdPartySales($event, thirdPartySalesData[i].licenseeId, thirdPartySalesData[i].productId);;" [ngClass]="getSalesInputClass(thirdPartySalesData[i].value,thirdPartySalesData[i].productId)"
                (focus)="onFocus($event, thirdPartySalesData[i].licenseeId, thirdPartySalesData[i].productId)" (blur)="updateThirdPartySales($event, thirdPartySalesData[i].licenseeId, thirdPartySalesData[i].productId)"
                (input)="setCorrectCursorPlacement($event); updateThirdPartySales($event, thirdPartySalesData[i].licenseeId, thirdPartySalesData[i].productId);"
                     [disabled]="(licenseeService.reportingPeriod != null && licenseeService.reportingPeriod.exempt) || isReportingPeriodLocked"
                required ng-pattern="/^-?[0-9][^\.]*$/">
            </div>
            <div class="col-sm-1 validation-check text-left">
              <i [ngClass]="getValidationClass(thirdPartySalesData[i].value,thirdPartySalesData[i].productId)" class="fas"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

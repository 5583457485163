export class DailySales{
    licenseeId: number;
    salesDate: string;
    salesAmount: number;
    isSunday: boolean;

    constructor(licenseeId: number,salesDate:string) {
        this.licenseeId = licenseeId;
        this.salesDate = salesDate;
        this.salesAmount = null;
    }
}

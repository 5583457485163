<div *ngIf="!licenseeService.MAINTENANCE" class='container-98'>
	<div class="loaderr" *ngIf="false">
		<app-loader [isLoading]="initialLoading" class="text-center"></app-loader>
	</div>
	<div [innerHTML]="redirectMessage"></div>
    <div *ngIf="false">
    	<div class='row' *ngIf="isAdmin">
      		<div class='col-sm-4 section-col'>
        		<app-cfa-status [isLoading]="isLoading"></app-cfa-status>
      		</div>
					<div class="col-sm-7 section-col">
						<app-cfa-pending [isLoading]="isLoading" [isReadOnlyAdmin]="isReadOnlyAdmin"></app-cfa-pending>
					</div>
					<!-- <div class='col-sm-6 section-col'>
        		<app-cfa-approval [isLoading]="isLoading"></app-cfa-approval>
      		</div>
					<div class='col-sm-6 section-col'>
        		<app-cfa-review [isLoading]="isLoading"></app-cfa-review>
      		</div> -->
					<div class="col-sm-11">
						<app-cfa-locations [isLoading]="isLoading"></app-cfa-locations>
					</div>
					<div class='col-sm-6 section-col'>
        		<app-cfa-channels [isLoading]="isLoading" [isReadOnlyAdmin]="isReadOnlyAdmin"></app-cfa-channels>
      		</div>
					<div class='col-sm-5 section-col'>
        		<app-cfa-oracle [isLoading]="isLoading"></app-cfa-oracle>
      		</div>

    	</div>
	</div>
</div>

<div *ngIf="licenseeService.MAINTENANCE" class='container-95'>
	<div class='row'>
		<h1 class='col-sm-12 text-center'>WEBSITE MAINTENANCE IN PROGRESS</h1>
	</div>
</div>

<div class="modalBackdrop hidden" id="modalBackdrop"></div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { ReportingPeriod } from "@app/model/ReportingPeriod.model";
import { SubmissionReport } from "@app/model/SubmissionReport.model";
import { CurrentDate } from "@app/model/CurrentDate.model";
import { environment } from "@env/environment";
import { LicenseeSearch } from "@app/model/LicenseeSearch.model";
import { Summary } from "@app/model/Summary.model";
import { Attachments } from "@app/model/Attachments.model";
import { Licensee } from "@app/model/Licensee.model";
import { ThirdPartySales } from "@app/model/ThirdPartySales.model";
import {getFileNameFromResponseContentDisposition, saveFile} from 'app/helpers/file-download-helper';
import { Guid } from "guid-typescript";
import {AccessToken} from '@app/model/AccessToken.model';
import {SubmissionStatusMessage} from '@app/model/SubmissionStatusMessage.model';
import { ConditionalExpr } from '@angular/compiler';
import { AdditionalSalesChannel } from '@app/model/AdditionalSalesChannel.model';

const env = environment as any;
const apiUrl = env.apiUrl;
type RequestOptions = { body: any, url: string }

@Injectable()
export class LicenseeService {
  MAINTENANCE = false;

  init = false;

  //Licensee Previously stored Data
  private reportingPeriodSubject = new BehaviorSubject<ReportingPeriod>(null);
  reportingPeriodObservable = this.reportingPeriodSubject.asObservable();
  //Licensee Summary Data
  private licenseeSummariesDataSubject = new BehaviorSubject<Summary>(null);
  licenseeSummariesDataObservable = this.licenseeSummariesDataSubject.asObservable();
  //Licensee Search data used to update/reload form
  private licenseeSearchSubject = new BehaviorSubject<LicenseeSearch>(null);
  licenseeSearchObservable = this.licenseeSearchSubject.asObservable();
  //Licensee Previously stored attachments
  private licenseeAttachmentSubject = new BehaviorSubject<any>(null);
  licenseeAttachmentObservable = this.licenseeAttachmentSubject.asObservable();
  //Licensee Previous submission status
  private licenseeMessagesSubject = new BehaviorSubject<Array<SubmissionStatusMessage>>(null);
  licenseeMessagesObservable = this.licenseeMessagesSubject.asObservable();
  //CFA submission report
  private submissionReportSubject = new BehaviorSubject<Array<SubmissionReport>>(null);
  submissionReportObservable = this.submissionReportSubject.asObservable();
  //CFA Submission report date
  private submissionReportDateSubject = new BehaviorSubject<CurrentDate>(null);
  submissionReportDateObservable = this.submissionReportDateSubject.asObservable();
  //CFA selected submission report (ie. approved reports, unsubmitted, etc.)
  private selectedSubmissionReportSubject = new BehaviorSubject<Array<SubmissionReport>>(null);
  selectedSubmissionReportObservable = this.selectedSubmissionReportSubject.asObservable();
  //CFA individual selected report for warning logs
  private dataValidationLogSubject = new BehaviorSubject<SubmissionReport>(null);
  dataValidationLogObservable = this.dataValidationLogSubject.asObservable();
  //CFA administrator is read only
  private adminReadOnlySubject = new BehaviorSubject<Boolean>(false);
  adminReadOnlyObservable = this.adminReadOnlySubject.asObservable();
  //Additional Sales Channel list
  private additionalSalesChannelListSubject = new BehaviorSubject<Array<AdditionalSalesChannel>>(null)
  additionalSalesChannelListObservable = this.additionalSalesChannelListSubject.asObservable();
  //Modal Active State
  private isModalActiveSubject = new BehaviorSubject<Boolean>(false);
  isModalActiveObservable = this.isModalActiveSubject.asObservable();

  //Licensee info data
  private licenseeSubject = new BehaviorSubject<Licensee>(null);
  licenseeObservable = this.licenseeSubject.asObservable();
  isMonthlySale75kWarning: boolean = false;
  isAdditionalSale50kWarning: boolean = false;

  //Licensee models
  reportingPeriod: ReportingPeriod;
  licenseeSearch: LicenseeSearch;
  licenseeSummaryData: Summary;
  licenseeMessages: Array<SubmissionStatusMessage>;
  licenseeInfo: Licensee;

  //Upload
  uploadComplete: boolean = false;
  uploadBtnDisabled: boolean = true;
  adminDeleteAttachment = false;

  //CFA models
  allSubmissionReports: Array<SubmissionReport>;
  selectedSubmissionStatus: Array<SubmissionReport>;
  selectedStatus: string;
  isReadOnlyAdmin: boolean;

  //Pass or Fail for licensee components
  fiscalGoodToSubmit: boolean = false;
  dailyGoodToSubmit: boolean = false;
  monthlyGoodToSubmit: boolean = false;
  uploadGoodToSubmit: boolean = false;
  contactGoodToSubmit: boolean = false;
  directorGoodToSubmit: boolean = false;
  confirmationGoodToSubmit: boolean = false;
  adJustmentGoodToSubmit: boolean = false;

  //Additional Sales Channels
  additionalSalesChannels: Array<AdditionalSalesChannel>;
  showAdditionalSalesChannels: boolean = false;

  isModalActive: Boolean = false;

  reportingPeriodLocked: boolean = false;

  todaysDate = new Date();
  currentDate: CurrentDate;

  LICENSEE_ID: number = 1;
  reportingPeriodYears: any;

  isInternational: boolean;
  currency: { iso: string, symbol: string };

  httpOptions = {
    headers: new HttpHeaders({
      'x-api-key': env.apiKey,
      'Authorization': ''
    }),
    reportProgress: true
  };

  token = {iat: 0, exp: 0};

  constructor(private http: HttpClient) {}

  checkTokenExpiration() {
    setInterval(() => {
      if(this.token.iat > 0 && this.token.exp > 0) {
        if(this.token.exp < Math.floor(Date.now() / 1000)) {
          location.reload();
        } else {
        }
      }
    }, 10000);
  }

  /*************** General ***************/
  initialize() {
    const licensee = new Licensee();

    this.setLicenseeInfo(licensee);
    //Licensee
    this.setReportingPeriod(null);
    this.setLicenseeSearch(null);
    this.setLicenseeSummariesData(null);
    this.setLicenseeAttachment(null);
    this.setLicenseeMessages(null);
    //CFA
    this.setSubmissionReport(null);
    this.setSelectedSubmissionReport(null);
    this.setDataValidationLog(null);
    this.setReadOnlyAdmin(null);
    //Global variables
    this.fiscalGoodToSubmit = false;
    this.dailyGoodToSubmit = false;
    this.monthlyGoodToSubmit = false;
    this.uploadGoodToSubmit = false;
    this.selectedStatus = "";

    if(!this.currentDate || !this.currentDate.month) {
        this.currentDate = new CurrentDate();
        this.currentDate.month = this.getCurrentMonth();
        this.currentDate.year = this.getCurrentYear();
    }

    this.setInternational(null);
    this.isInternational = false;

    this.init = true;
  }
    checkIfAdmin(accessToken: AccessToken): boolean {
      const defaultAdminLocation = '00000';
        if (accessToken && accessToken.cfa_perms && accessToken.cfa_perms.LIC_SALESPORTAL
            && accessToken.cfa_perms.LIC_SALESPORTAL.ADMIN) {
           if (accessToken.cfa_perms.LIC_SALESPORTAL.ADMIN.includes(defaultAdminLocation)) {
               return true;
           }
        }
        return false;
    }

    checkIfReadOnlyAdmin(accessToken: AccessToken): boolean {
      const defaultReadWriteLocation = '00000';
      if(this.checkIfAdmin(accessToken)) {
        if (accessToken && accessToken.cfa_perms && accessToken.cfa_perms.LIC_SALESPORTAL
            && accessToken.cfa_perms.LIC_SALESPORTAL.ADMIN_READ_WRITE) {
              if (accessToken.cfa_perms.LIC_SALESPORTAL.ADMIN_READ_WRITE.includes(defaultReadWriteLocation)){
                return false;
              }
            }
        return true;
      }
      return false;
    }
  // Returns a string representation of the date in the format YYYY-MM-DD
  dateToString(date: Date) {
    return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
  }

  dateToShortString(date: Date) {
    return date.getMonth() + 1 + "/" + date.getDate();
  }

  stringToDate(dateString: string) {
    let parts = dateString.split("-");
    let date = new Date(+parts[0], +parts[1] - 1, +parts[2]);
    return date;
  }

  getCurrentMonth() {
    var month = this.todaysDate.getMonth() + 1;
    return month;
  }

  getCurrentYear() {
    var year = this.todaysDate.getFullYear();
    return year;
  }

  async getListOfYears() {
    await this.getYears().subscribe(res => {
      this.reportingPeriodYears = res;
    });
  }

  /*************** Currency Conversions for inputs ***************/

 /**
   * Converts a string number with syntax mistakes into a number object
   * @param numString String version of a number
   * @returns number. ex: 1234.56
   */
  getCurrencyNumber(numString: any): number {
    return Number(this.getStrValue(numString));
  }

  /**
   * Convers a string number syntax mistakes into a currency string with a dollar symbol.
   * @param numString string version of a number
   * @returns currency string. ex: '$1,234.56'
   */
  getCurrencyNumberString(numString: any): string {
    let strValue = this.getStrValue(numString);

    // Format strValue - add '$' and commas
    strValue = this.currency.symbol + (strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","));

    return strValue;
  }

  private getStrValue(numString: any): string {
    // round to 2 decimal points
    let numValue = 0;
    numValue = numString.replace(/[^0-9.]+/g, '');
    if(numValue.toString().includes('.')) {
      let splitValues = numValue.toString().split('.');
      let decimals = "";
      for(let i = 1; (i < splitValues.length && i < 10); i++) {
        decimals = decimals.concat(splitValues[i]);
      }
      numValue = Number(splitValues[0].concat('.',decimals));
    }
    numValue = Math.round(numValue * 100) / 100;

    //convert to string
    let strValue = numValue.toString();

    //check if strValue has a decimal AND two decimal points afterwards. Add necessary 0's at the end.
    if(!strValue.includes('.')) {
      strValue = strValue + ".00";
    } else {
      //split the string at the dot.
      let splitValues = strValue.split(".");
      strValue = splitValues[0];
      let decimals = splitValues[1];

      while(decimals.length < 2) {
        decimals = decimals + "0";
      }
      strValue = strValue.concat('.', decimals.substring(0, 2));
    }

    return strValue;
  }

  /*************** LicenseeInfo ***************/

  // Returns all of Licensee's previous store data
  getData(data: LicenseeSearch) {
    return this.http.get(
      `${apiUrl}/licensee?licenseeid=${data.licenseeid}&calendarMonth=${data.month}&year=${data.year}`,
      this.httpOptions
    );
  }
  // Sets Licensee's previously stored data to the observable
  setReportingPeriod(data: ReportingPeriod) {
    this.reportingPeriod = data;
    this.reportingPeriodSubject.next(data);
  }

  checkReportingPeriodLicenseeId(): boolean {
    console.log('checking ReportingPeriodLicenseeID...');

    if(this.reportingPeriod != null) {
      if(this.reportingPeriod.dailySales.length > 0 && this.reportingPeriod.licenseeId < 1 && this.reportingPeriod.dailySales[0].licenseeId < 1) {
        console.log(' %c Both reportingPeriod AND dailySales licenseeID = 0', 'background: #222; color: #bada55');
        return false;
      }
      else if (this.reportingPeriod.dailySales.length > 0 && this.reportingPeriod.licenseeId < 1 && this.reportingPeriod.dailySales[0].licenseeId >= 1) {
        let dailySalesLicenseeID = this.reportingPeriod.dailySales[0].licenseeId;
        let dailySalesLicenseeIDIsGood = true;

        this.reportingPeriod.dailySales.forEach(dailySale => {
          if(dailySale.licenseeId != dailySalesLicenseeID) {
            dailySalesLicenseeIDIsGood = false;
          }
        });

        if(dailySalesLicenseeIDIsGood) {
          console.log("setting reportingPeriod.licenseeId to dailySalesLicenseeID...");
          this.reportingPeriod.licenseeId = dailySalesLicenseeID;
          return true;
        } else {
          console.log('%c dailySalesLicenseeIDs were not all the same', 'background: #222; color: #bada55');
          return false;
        }
      }
      else if(this.reportingPeriod.dailySales.length > 0 && this.reportingPeriod.licenseeId == this.reportingPeriod.dailySales[0].licenseeId) {
        console.log("reportingPeriod.licenseeId and dailySales.licenseeId are not zero AND equal");
        return true;
      }
      else {
        console.log(' %c setting reportingPeriod.licenseeId to dailySalesLicenseeID failed. Returning false...', 'background: #222; color: #bada55');
        return false;
      }
    } else {

      console.error(' %c reporting period is NULL!','background: #222; color: #bada55');
      return false;
    }
  }

  // Updates the search criteria for new months data for a Licensee
  setLicenseeSearch(data: LicenseeSearch) {
    this.licenseeSearch = data;
    this.licenseeSearchSubject.next(data);
  }

  // Returns Licensee's summary data for Licensee graph
  getSummaries(data: LicenseeSearch) {
    return this.http.get(
      `${apiUrl}/licensee/getsummaries?licenseeid=${data.licenseeid}&calendarMonth=${data.month}&year=${data.year}`,
      this.httpOptions
    );
  }

  // Sets Licensee's summary data to the observable
  setLicenseeSummariesData(data: Summary) {
    this.licenseeSummaryData = data;
    this.licenseeSummariesDataSubject.next(data);
  }

  // Updates the attachment data for a Licensee
  setLicenseeAttachment(data: any) {
    this.licenseeAttachmentSubject.next(data);
  }

  // Sets Licensee's summary data to the observable
  setLicenseeInfo(data: Licensee) {
    this.licenseeInfo = data;
    this.licenseeSubject.next(data);
    const search = new LicenseeSearch();
    search.licenseeid = data.licenseeId;
    this.setLicenseeSearch(search);
    this.setInternational(data.countryCode);
  }

  setInternational(countryCode: string) {
    let currency = { iso: '', symbol: '' };
    switch(countryCode) {
      case "US": this.isInternational = false; currency = { iso: "USD", symbol: "$" }; break;
      case "CA": this.isInternational = true; currency = { iso: "CAD", symbol: "$" }; break;
      case "GB": this.isInternational = true; currency = { iso: "GBP", symbol: "£" }; break;
      default: this.isInternational = false; currency = { iso: "USD", symbol: "$" }; break;
    }
    this.currency = currency;
  }

  deleteAttachment() {
    return this.http.get(`${apiUrl}/licensee/deleteattachment?reportingPeriodId=${this.reportingPeriod.reportingPeriodId}`, this.httpOptions);
  }

  deleteOneAttachment(body: Attachments,  licenseeId: Number) {
    return this.http.delete(`${apiUrl}/licensee/v2/deleteattachment?reportingPeriodId=${this.reportingPeriod.reportingPeriodId}&licenseeid=${licenseeId}`,
        {body, ...this.httpOptions}).pipe();
  }

  postAttachments(body: Array<Attachments>) {
    return this.http.post(`${apiUrl}/licensee/v2/addtodatabase?licenseeid=${this.licenseeSearch.licenseeid}`, body, this.httpOptions).pipe();
  }

  // Post to DB for Licensee's data on Save or Submit
  postData(body: ReportingPeriod) {
    body.dailySales.map(sale => {
      sale.licenseeId = this.licenseeSearch.licenseeid;
    });
    body = this.populateEmptyReportingPeriodData(body);
    body.thirdPartySales.map(sale => {
      sale.licenseeId = this.licenseeSearch.licenseeid;
    });
    return this.http.post(`${apiUrl}/licensee?licenseeid=${this.licenseeSearch.licenseeid}&isAdjustment=${body.isAdjustment}`, body, this.httpOptions).pipe();
  }

  updateData(body: ReportingPeriod) {
    body.dailySales.map(sale => {
      sale.licenseeId = this.licenseeSearch.licenseeid;
    });
    body = this.populateEmptyReportingPeriodData(body);
    body.thirdPartySales.map(sale => {
      sale.licenseeId = this.licenseeSearch.licenseeid;
    });
    return this.http.put(`${apiUrl}/licensee?licenseeid=${this.licenseeSearch.licenseeid}&isAdjustment=${body.isAdjustment}`, body, this.httpOptions).pipe();
  }

  populateEmptyReportingPeriodData(rp: ReportingPeriod): ReportingPeriod {
    if(rp.thirdPartySales == null || rp.thirdPartySales.length == 0 ) {
      rp.thirdPartySales = new Array<ThirdPartySales>(
        new ThirdPartySales(this.licenseeInfo.licenseeId, 1),
        new ThirdPartySales(this.licenseeInfo.licenseeId, 2),
        new ThirdPartySales(this.licenseeInfo.licenseeId, 3),
        new ThirdPartySales(this.licenseeInfo.licenseeId, 4),
        new ThirdPartySales(this.licenseeInfo.licenseeId, 5)
      );
    }
    return rp;
  }

  clearUploadData() {
    this.uploadComplete = false;
  }

  // Get S3 signed url
  getUploadUrl(fileName: string, licenseeid: number) {
    return this.http.get(`${apiUrl}/licensee/v2/uploadattachment?fileNames=${fileName}&licenseeid=${licenseeid}`, this.httpOptions);
  }

  // Post the file to the S3 bucket
  uploadAttachmentToS3(signedURL: any, file: any) {
    return this.http.put(`${signedURL}`, file, { headers: { } });
  }

  getDownloadUrl(fileName: string, licenseeid: number) {
    return this.http.get(`${apiUrl}/shared/downloadurl?fileName=${fileName}&licenseeid=${licenseeid}`, this.httpOptions);
  }

  downloadAttachmentFromS3(url: any, fileName: string) {
    const options: any = { responseType: "blob" };
    // Process the file downloaded
    this.http.get(url, options).subscribe((res: any) => {
      saveFile(res, fileName);
    });
  }

  postValidationIssues(body: ReportingPeriod) {
    return this.http.post(`${apiUrl}/validation/putvalidation?licenseeid=${this.licenseeSearch.licenseeid}&isAdjustment=${body.isAdjustment}`, body, this.httpOptions).pipe();
  }
  /*************** CFAInfo ***************/

  // Call to CFA endpoint to return Licensees for Impersonate Component
  //TODO Implement so that a single licensee object is returned
  getLicensee(uniqueId: Guid) {
    return this.http.get(`${apiUrl}/licensee/getinfo?uid=${uniqueId}`, this.httpOptions);
  }

  // Returns the submission counts for the CFA graph
  getSubmissionCounts(date: CurrentDate) {
    return this.http.get(
      `${apiUrl}/cfa/getsubmissioncounts?calendarMonth=${date.month}&year=${date.year}`,
      this.httpOptions
    );
  }

  setSubmissionReport(data: Array<SubmissionReport>) {
    this.allSubmissionReports = data;
    this.submissionReportSubject.next(data);
  }

  // Updates the search criteria for new months data for a Licensee
  setSubmissionReportDate(date: CurrentDate) {
    this.currentDate = date;
    this.submissionReportDateSubject.next(date);
  }

  setSelectedSubmissionReport(data: Array<SubmissionReport>) {
    this.selectedSubmissionStatus = data;
    this.selectedSubmissionReportSubject.next(data);
  }

  setDataValidationLog(report: SubmissionReport) {
    this.dataValidationLogSubject.next(report);
  }

  setReadOnlyAdmin(data: boolean) {
    this.adminReadOnlySubject.next(data);
  };

  /*************** SharedInterface ***************/

  // Post Submission Status to the DB
  postSubmissionStatus(data: LicenseeSearch) {
    console.log("Licensee Search In postSubmissionStatus", data);
    if (data.denialReason) {
      return this.http.get(
        `${apiUrl}/shared/postsubmissionstatus?licenseeid=${data.licenseeid}&calendarMonth=${data.month}&year=${
          data.year
        }&statusid=${data.statusid}&adjustmentDenialReason=${data.denialReason}`,
        this.httpOptions
      );
    } else {
      return this.http.get(
        `${apiUrl}/shared/postsubmissionstatus?licenseeid=${data.licenseeid}&calendarMonth=${data.month}&year=${
          data.year
        }&statusid=${data.statusid}`,
        this.httpOptions
      );
    } 
  }

  // Download attachments from S3
  // Returns any Licensee's attachment information from DB
  downloadAttachment(data: LicenseeSearch) {
    return this.http.get(
      `${apiUrl}/shared/downloadattachments?licenseeid=${data.licenseeid}&calendarMonth=${data.month}&year=${
        data.year
      }&statusid=${data.statusid}`,
      this.httpOptions
    );
  }

  // Return number of years
  getYears() {
    return this.http.get(`${apiUrl}/shared/getyears`, this.httpOptions);
  }

  getMessages(data: LicenseeSearch) {
    return this.http.get(
      `${apiUrl}/shared/getmessage?licenseeid=${data.licenseeid}&calendarMonth=${data.month}&year=${data.year}`,
      this.httpOptions
    );
  }

  setLicenseeMessages(messages: Array<SubmissionStatusMessage>) {
    this.licenseeMessages = messages;
    this.licenseeMessagesSubject.next(messages);
  }

  setAdditionalSalesChannels(channels: Array<AdditionalSalesChannel>) {
    this.additionalSalesChannels = channels;
    this.additionalSalesChannelListSubject.next(channels);
  }

  /*************** ExportFormat ***************/

   // Returns file name for the daily export
   downloadDailyReport() {
       const httpBlob = this.httpOptions;
       httpBlob['responseType'] = 'blob';
    return this.http.get(
      `${apiUrl}/export/dailyreport?ngsw-bypass=true`,
        httpBlob);
   }
  // Returns file name for the Oracle export
  downlaodExportFormat(date: CurrentDate) {
      const httpBlob = this.httpOptions;
      httpBlob['responseType'] = 'blob';
    return this.http.get(
      `${apiUrl}/export/exportformat?month=${date.month}&year=${date.year}`,
      httpBlob
    );
  }

  // Returns file name for the Adjusted sales
  downloadRevisedFormat(startDate: Date, endDate: Date){
      const httpBlob = this.httpOptions;
      httpBlob['responseType'] = 'blob';
    return this.http.get(
      `${apiUrl}/export/exportrevised?startDate=${startDate.toLocaleDateString('en-US')}&endDate=${endDate.toLocaleDateString('en-US')}`,
      httpBlob
    );
  }

  getTokenExchange(options: RequestOptions) {
    return this.http.post(options.url, options.body);
  }

  getAdjustmentDetails(reportingPeriodId: number, locationNumber: number){
      return this.http.get(
      `${apiUrl}/licensee/adjustment-details?reportingPeriodId=${reportingPeriodId}&locationNumber=${locationNumber}`, this.httpOptions
      );
  }

  determineIfReportingPeriodLocked(): boolean {
       let numberOfpreviousMonths = 1;
       let numberOfFutreMonths = 3;
       if(this.licenseeInfo.allowsAdjustments === false) {
          this.reportingPeriodLocked = false;
          return this.reportingPeriodLocked;
       }
       if(this.todaysDate.getDate() >= 11) {
           numberOfpreviousMonths = 0;
       }

       this.reportingPeriodLocked = this.getMonthDifference(new Date(this.todaysDate.getFullYear(),
                   this.todaysDate.getMonth() + numberOfFutreMonths),
               new Date(this.reportingPeriod.year,
                   this.reportingPeriod.calendarMonth - 1)) > 0 ||
           this.getMonthDifference(new Date(this.todaysDate.getFullYear(), this.todaysDate.getMonth() - numberOfpreviousMonths),
               new Date(this.reportingPeriod.year,
                   this.reportingPeriod.calendarMonth - 1)) < 0;
       return this.reportingPeriodLocked;
  }

  getMonthDifference(startDate: Date, endDate: Date) {
        return endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear());
  }

  // additional sales channels services

  getAdditionalSalesChannels() {
    return this.http.get(`${apiUrl}/shared/saleschannel`, this.httpOptions);
  }

  postAdditionalSalesChannels(channelNames: Array<string>){
    return this.http.post(`${apiUrl}/shared/saleschannel`, channelNames, this.httpOptions).pipe();
  }

  updateAdditionalSalesChannel(body: AdditionalSalesChannel){
    return this.http.put(`${apiUrl}/shared/saleschannel/update`, body, this.httpOptions).pipe();
  }
  getFeatureFlagStatus(flagName: string) {
    return this.http.get(`${apiUrl}/shared/feature-flag/?flag-name=${flagName}`, this.httpOptions);
  }

  // Modal Activity
  setIsModalActive(state: Boolean): void {
    this.isModalActiveSubject.next(state);
  }

  getIsModalActive(): Observable<Boolean> {
    return this.isModalActiveObservable;
  }
}

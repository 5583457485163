import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route, extract } from '@app/core';
import { CallbackComponent } from './callback.component';

const routes: Routes = [
  Route.withShell([
    //{ path: '', redirectTo: '/implicit/callback', pathMatch: 'full' },
    { path: 'implicit/callback', component: CallbackComponent, data: { title: extract('Callback') } }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CallbackRoutingModule { }

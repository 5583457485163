<header>
  <nav class="navbar navbar-expand-lg navbar-white bg-white ">
    <a class="navbar-brand" routerLink="/cfa" translate>
      <img src="../../assets/img/CFA_CSymbols/CircleCSymbol/Red/CFA_CSymbol_Circle_Red_PMS.png" height="50px">
    </a>
    <div *ngIf="!isReadOnlyAdmin && !cfaRoute" class="update-hours">
      <a href="https://ctrl.cfahome.com/DOP" class="update-hours-button" data-testid="update-hours-button">Update Restaurant Hours</a>
    </div>
    <div *ngIf="isReadOnlyAdmin" class="collapse navbar-collapse d-flex justify-content-center">
      <span class="read-only-text" data-testid="read-only-text">You are accessing this site in read-only mode.</span>
    </div>

    <button class="navbar-toggler" type="button" aria-controls="navbar-menu" aria-label="Toggle navigation" (click)="toggleMenu()"
      [attr.aria-expanded]="!menuHidden">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none justify-content-end" [ngbCollapse]="menuHidden">
      <div class="navbar-nav" [hidden]="!isAdmin">
        <a class="nav-item nav-link text-uppercase" routerLink="/cfa" routerLinkActive="active">
          <span translate>Home</span>
        </a>
      </div>
      <div class="navbar-nav ml-auto">
        <div class="row welcome">
          <div class='col-12 user-welcome d-flex align-items-center justify-content-end'>
            <a id="user-dropdown col-sm" class="nav-link">
              <span>Welcome, {{username}}!</span>
            </a>
            <div ngbDropdown placement="left-bottom">
              <a data-testid="lnk-dropdown-dots" class="btn text-danger dropdown-dots" ngbDropdownToggle>
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </a>
              <div ngbDropdownMenu aria-labelledby="user-dropdown">
                <h6 class="dropdown-header">
                  <span translate>Logged in as </span> <b>{{username}}</b></h6>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item" (click)="logout()" translate>
                  <i class="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </button>
              </div>
            </div>
          </div>
          <div class='col-12 user-welcome' [hidden]="cfaRoute">
            <a class="text-danger">
              <span class="location-info">
                {{cfaLocationNumber}} • {{cfaLocationName}}
              </span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </nav>
</header>

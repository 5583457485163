<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12 daily-header">
        <div class="date-form">
          <div class="form-inline float-right">
            <select data-testid="ddl-sales-month" class="form-control form-select form-control-sm" [(ngModel)]="salesMonth" (ngModelChange)="salesMonth = onModelChange($event); onDateSpinnerChange(salesMonth,salesYear);">
              <option *ngFor="let month of monthList, let i =index">
                {{month}}
              </option>
            </select>
            <select data-testid="ddl-sales-year" class="form-control form-select form-control-sm" [(ngModel)]="salesYear" (change)="onDateSpinnerChange(salesMonth,salesYear)">
              <option *ngFor="let year of yearList, let i =index" [selected]="year===currentDate.year">
                {{year}}
              </option>
            </select>
          </div>
        </div>
        <h5 data-testid="txt-pos-daily-sales" class="text-danger">POS Daily Sales</h5>
        <p class="small text-center">Daily POS Sales include all transactions entered or tendered at your point-of-sale system. All other sales not entered in your point-of-sale system should be entered in the sales channel section</p>
        <div *ngIf="allowsAdjustments && isReportingPeriodLocked && !licenseeService.reportingPeriod.exempt && !isLoading" class='text-center'>
        <button id="edit" class="mb-3" (click)="setAdjustment()" [disabled]="isEditDisabled" data-testid="edit-reporting-button">
          <i class="fas fa-pencil-alt"></i>
          <span>Edit Reporting Period</span>
        </button>
        </div>
        <div class="form-group row" *ngIf="licenseeService.reportingPeriod?.isAdjustment">
          <div class="col-sm-3 label-container">
            <label class="col-sm-form-label">Adjustment Reason:</label>
          </div>
          <div class="col-sm-9 input-container mb-2">
            <div class="col-sm-9 input-container">
              <p class="text-center"><span class="feedback" *ngIf="adjustmentReasonError && adjustmentReasonTouched">
                {{ invalidReason }} </span></p>
              <textarea class="form-control {{ adjustmentReasonError && adjustmentReasonTouched ? 'input-error' : '' }}"
                type="text" [(ngModel)]="adjustmentReason" (input)="updateAdjustmentReason()"
                (focus)="onAdjustmentReasonFocus()"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <p class="text-center"><span class="feedback" ng-show="day.salesAmount.$touched && day.salesAmount.$invalid">
        {{ invalidMessage }}
      </span></p> -->
    <div class="alert-container" [hidden]="!warningMessage">
      <ngb-alert class="warning" type="warning" [dismissible]="false"><strong>Warning!</strong> {{ invalidMessage }}</ngb-alert>
    </div>

    <div class="daily-sales">
      <app-loader [isLoading]="isLoading" class="text-center"></app-loader>
      <div class="container-90" *ngIf="dailySales !== undefined && !isLoading">
        <div class="row sales-input" *ngFor="let day of dailySales; let i = index">
          <div class="col-sm-3 text-end">
            <label class="col-sm-form-label text-end">{{currentDate.month}}/{{ i+1 }}</label>
          </div>
          <div class="col-sm-8">

            <input class="form-control text-end" type="text" [ngModel]="day.salesAmount | currency:currency.iso:'symbol-narrow':'1.2-2'"
              (ngModelChange)="updateSalesData();" (focus)="onFocus($event, day.salesDate, day.licenseeId)" (blur)="onBlur($event, day.salesDate, day.licenseeId)"
              (input)="setCorrectCursorPlacement($event)" [ngClass]="getInputClass(day.salesAmount)"
              [disabled]="(licenseeService.reportingPeriod != null && licenseeService.reportingPeriod.exempt) || day.isSunday || isReportingPeriodLocked
"
              required ng-pattern="/^-?[0-9][^\.]*$/">

          </div>
          <div class="col-sm-1 validation-check text-center">
            <i [ngClass]="getIconClass(day)" class="fas"></i>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

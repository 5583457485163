<div class='card'>
  <div class='card-body'>
    <div class='text-center'>
      <h5 data-testid="txt-presub-statuses" class='text-danger'>Submission Status</h5>
    </div>

    <div class='status-table text-center'>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="small"
                *ngFor="let header of headers"
                [style]="header.width ? 'width: ' + header.width: ''">
              <strong>{{header.title}}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let message of licenseeMessages">
            <td class="small">{{message.month}} / {{message.year}}</td>
            <td class="small">
              {{message.reportingPeriodSubmissionDate | date: 'MM-dd-yyyy'}}
              <span class="disabled" *ngIf="!message.reportingPeriodSubmissionDate">None</span>
            </td>
            <td class="small status" [ngClass]="getStatusClassName(message.statusId)">
              {{getStatusTitle(message.statusId)}}
              <i class="fas" [ngClass]="getStatusIconClass(message.statusId)"></i>
            </td>
            <td class="small" (click)="jumpToReport(message.month,message.year)">
              <i class="fas fa-edit"></i>
            </td>
            <td class="small">
              {{message.adjustmentSubmissionDate | date: 'MM-dd-yyyy'}}
              <span class="disabled" *ngIf="!message.adjustmentSubmissionDate">None</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route, extract } from '@app/core';
import { LicenseeComponent } from './licensee.component';
import { OktaAuthGuard } from '@okta/okta-angular';

const routes: Routes = [
  Route.withShell([
    { path: 'licensee', component: LicenseeComponent, canActivate: [OktaAuthGuard], data: { title: extract('Licensee') } }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LicenseeRoutingModule { }

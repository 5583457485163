import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { Routes, RouterModule } from '@angular/router';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LicenseeModule } from './licensee/licensee.module';
import { LicenseeService } from '@app/services/licensee.service';

import { LicenseeComponent } from './licensee/licensee.component';
import { LicenseeSummaryComponent } from './licensee-summary/licensee-summary.component';
import { LicenseeDailyComponent } from './licensee-daily/licensee-daily.component';
import { LicenseeMonthlyComponent } from './licensee-monthly/licensee-monthly.component';
import { LicenseeAdditionalComponent } from './licensee-additional/licensee-additional.component';
import { LicenseeUploadComponent } from './licensee-upload/licensee-upload.component';
import { LicenseeFiscalComponent } from './licensee-fiscal/licensee-fiscal.component';
import { CfaStatusComponent } from './cfa-status/cfa-status.component';
import { CfaReviewComponent } from './cfa-review/cfa-review.component';
import { CFAPendingComponent } from './cfa-pending/cfa-pending.component';
import { CfaApprovalComponent } from './cfa-approval/cfa-approval.component';
import { CfaLocationsComponent } from './cfa-locations/cfa-locations.component';
import { CfaOracleComponent } from './cfa-oracle/cfa-oracle.component';
import { CfaChannelsComponent } from './cfa-channels/cfa-channels.component';
import { CfaComponent } from './cfa/cfa.component';
import { CfaModule } from './cfa/cfa.module';
import { LicenseePreviousComponent } from './licensee-previous/licensee-previous.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CalendarModule} from 'primeng/calendar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';

import {
  OktaAuthModule,
  OktaCallbackComponent,
  OktaAuthGuard,
  OktaAuthService
} from '@okta/okta-angular';
import {oidc} from './okta.config';
import { CallbackModule } from './callback/callback.module';
import { ResponsiveModule } from 'ngx-responsive';
import { LicenseeContactComponent } from './licensee-contact/licensee-contact.component';
import { LicenseeDirectorComponent } from './licensee-director/licensee-director.component';
import { LicenseeAdminControlComponent } from './licensee-admin-control/licensee-admin-control.component';
import { LicenseeConfirmationComponent } from './licensee-confirmation/licensee-confirmation.component';
import {CfaAdjustmentApprovalComponent} from '@app/cfa-adjustment-approval/cfa-adjustment-approval.component';
import {CfaSuccessMessageModalComponent} from '@app/cfa-success-message-modal/cfa-success-message-modal.component';

const appRoutes: Routes = [
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'cfa',
    component: CfaComponent,
    canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'licensee',
    component: LicenseeComponent,
    canActivate: [ OktaAuthGuard ]
  }
];

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: false}),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    HomeModule,
    LoginModule,
    LicenseeModule,
    CallbackModule,
    CfaModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    OktaAuthModule.initAuth(oidc),
    ResponsiveModule.forRoot(),
    BrowserAnimationsModule,
    CalendarModule,
    ToastModule
  ],
  declarations: [
    AppComponent,
    LicenseeComponent,
    LicenseeSummaryComponent,
    LicenseeDailyComponent,
    LicenseeMonthlyComponent,
    LicenseeAdditionalComponent,
    LicenseeUploadComponent,
    LicenseeFiscalComponent,
    CfaStatusComponent,
    CfaReviewComponent,
    CFAPendingComponent,
    CfaApprovalComponent,
    CfaLocationsComponent,
    CfaAdjustmentApprovalComponent,
    CfaOracleComponent,
    CfaChannelsComponent,
    CfaComponent,
    LicenseePreviousComponent,
    LicenseeContactComponent,
    LicenseeDirectorComponent,
    LicenseeAdminControlComponent,
    LicenseeConfirmationComponent,
    CfaSuccessMessageModalComponent
  ],
  providers: [
    LicenseeService,
    DatePipe,
    OktaAuthGuard,
    OktaAuthService,
    MessageService,
    {provide: 'googleTagManagerId',  useValue: environment.gtmId}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

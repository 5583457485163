<div data-testid="card-contact-info" class='card'>
    <div class='card-body'>
        <div class='text-center'>
            <h5 class='text-danger'>Contact Information</h5>
        </div>

        <div class='container-90 text-center'>
            <p class="small">Please enter the name and email of the person submitting the report.</p>
        </div>

        <app-loader [isLoading]="isLoading" class="text-center"></app-loader>
        <div [hidden]="isLoading" class='contact-body text-center'>
            <div class="form-group row">
                <div class="col-sm-2 label-container">
                    <label class="col-sm-form-label">Name:</label>
                </div>
                <div class="col-sm-9 input-container">
                    <input data-testid="input-contact-name" class="form-control {{ nameError && nameTouched ? 'input-error' : '' }} contactName" type="text" maxlength="50" [(ngModel)]="name" (input)="updateContactInfo()" (focus)="onFocus(1)" pattern="/^[a-zA-Z- ]*$/" required
                           [disabled]=isReportingPeriodLocked>
                </div>
                <div class="col-sm-1 validation-check text-left">
                    <i *ngIf="!nameError" data-testid="chk-contact-name-success" class="fas fa-check-circle text-success"></i>
                    <i *ngIf="nameError && nameTouched" data-testid="chk-contact-name-missing" class="fas fa-times-circle text-danger"></i>
                </div>
            </div>
            
            <div class="form-group row">
                    <div class="col-sm-2 label-container">
                        <label class="col-sm-form-label">Email:</label>
                    </div>
                    <div class="col-sm-9 input-container">
                        <input data-testid="input-contact-email" class="form-control {{ emailError && emailTouched ? 'input-error' : '' }} contactEmail" type="text" maxlength="50" [(ngModel)]="email" (input)="updateContactInfo()"
                            (focus)="onFocus(2)" pattern="[a-zA-Z.\-_0-9]+@[a-zA-Z.\-0-9]+[a-zA-Z0-9]+\.[a-zA-Z]{1,9}$" required  [disabled]=isReportingPeriodLocked>
                    </div>
                    <div class="col-sm-1 validation-check text-left">
                        <i *ngIf="!emailError" data-testid="chk-contact-email-success" class="fas fa-check-circle text-success"></i>
                        <i *ngIf="emailError && emailTouched" data-testid="chk-contact-email-failure" class="fas fa-times-circle text-danger"></i>
                    </div>
                </div>
        </div>

    </div>
</div>

export class Licensee {
    licenseeId: number;
    companyId: number;
    accountNumber: number;
    cfaLocationName: string;
    cfaLocationNumber: string;
    emailAddress: string;
    percentage: number;
    hasthirdpartysales: boolean;
    countryCode: string;
    catering: boolean;
    giftCards: boolean;
    tempShutdown: boolean;
    allowsAdjustments: boolean;
    status: string;
    tempCloseReason: string;
}

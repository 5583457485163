import { Component, OnInit, OnDestroy } from "@angular/core";
import { SubscriptionLike } from "rxjs";
import { LicenseeSearch } from "@app/model/LicenseeSearch.model";
import { LicenseeService } from "../services/licensee.service";
import {SubmissionStatusMessage} from '@app/model/SubmissionStatusMessage.model';

@Component({
  selector: "app-licensee-previous",
  templateUrl: "./licensee-previous.component.html",
  styleUrls: ["./licensee-previous.component.scss"]
})
export class LicenseePreviousComponent implements OnInit, OnDestroy {
  private licenseeMessagesSubscription: SubscriptionLike;
  headers = [
    {title: 'Monthly Reporting Period'},
    {title: 'Date Submitted'},
    {title: 'Reporting Status', width: '112px'},
    {title: 'Edit'},
    {title: 'Adjustments'},
  ]
  statusMap = [
    {statusId: 1, iconClass: "fa-pencil-alt text-primary", title: 'Draft', className: 'draft'},
    {statusId: 2, iconClass: "fa-hourglass-end text-warning", title: 'In Review', className: 'inReview'},
    {statusId: 3, iconClass: "fa-thumbs-up text-success", title: 'Approved', className: 'approved'},
  ]

  licenseeMessages: Array<SubmissionStatusMessage>;
  _ID: number = this.licenseeService.licenseeInfo.licenseeId;
  constructor(private licenseeService: LicenseeService) {}

  ngOnInit() {
    this.licenseeMessagesSubscription = this.licenseeService.licenseeMessagesObservable
        .subscribe(response => this.licenseeMessages = response || []);
  }

  ngOnDestroy() {
    if (this.licenseeMessagesSubscription !== undefined) {
      this.licenseeMessagesSubscription.unsubscribe();
    }
  }

  getStatusIconClass(status: number) {
    return this.statusMap.find(({statusId})=> statusId === status).iconClass;
  }

  getStatusTitle(status: number){
    return this.statusMap.find(({statusId})=> statusId === status).title;
  }

  getStatusClassName(status: number){
    return this.statusMap.find(({statusId})=> statusId === status).className;
  }

  jumpToReport(month:number, year:number){
    if(month!==0 && year!==0){
      var licenseeSearch = new LicenseeSearch();
      licenseeSearch.licenseeid = this._ID;
      licenseeSearch.month = month;
      licenseeSearch.year = year;

      this.licenseeService.currentDate.month = month;
      this.licenseeService.currentDate.year = year;
      this.licenseeService.setLicenseeSearch(licenseeSearch);
    }
  }

}

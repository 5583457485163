import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core";
import { LicenseeService } from "../services/licensee.service";
import { CurrentDate } from "@app/model/CurrentDate.model";
import { SubscriptionLike } from "rxjs";
import * as c3 from "c3";
import { SubmissionReport } from "@app/model/SubmissionReport.model";

@Component({
  selector: "app-cfa-status",
  templateUrl: "./cfa-status.component.html",
  styleUrls: ["./cfa-status.component.scss"]
})
export class CfaStatusComponent implements OnInit, OnDestroy {
  //Retrieving variables from Licensee parent component
  @Input()
  isLoading: boolean; //Boolean to see if data has been returned

  private submissionReportSubscription: SubscriptionLike;
  allReports: Array<SubmissionReport> = [];
  unsubmittedReports: Array<SubmissionReport> = [];
  approvedReports: Array<SubmissionReport> = [];
  warningReports: Array<SubmissionReport> = [];

  @ViewChild("pieChart")
  private chartRef: any;

  private parentNativeElement: any;

  isDataAvail: boolean = false;

  pieChartData: Array<number> = [1, 1, 1];
  pieChartLabels = ["Approved", "Warnings", "Unsubmitted"];
  pieChartColors = ["#3FB1C8", "#994878", "#FFB549"];
  pieChartTitle: string = "";

  currentDate = new CurrentDate();
  currentYear = this.currentDate.year;
  statusYear: number;
  statusMonth: string;
  statusMonthNum: number;

  monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]; //List of months to build drop down
  yearList: any;

  constructor(private licenseeService: LicenseeService, element: ElementRef) {
    this.parentNativeElement = element.nativeElement;
  }

  ngOnInit() {
    this.setCurrentDate(this.licenseeService.currentDate.month, this.licenseeService.currentDate.year);
    this.yearList = this.getListOfYears();

    //ngModel Variables
    this.statusMonth = this.monthNumToName(this.currentDate.month);
    this.statusMonthNum = this.currentDate.month;
    this.statusYear = this.currentDate.year;

    //Subscription to ReportingPeriod Observable in parent
    this.submissionReportSubscription = this.licenseeService.submissionReportObservable.subscribe(payload => {
      if (payload === null) {
        this.allReports = [];
      } else {
        this.allReports = payload;
        this.licenseeService.allSubmissionReports = this.allReports;
        this.isDataAvail = true;
      }
      this.licenseeService.setSelectedSubmissionReport(this.allReports);
      this.divideStatuses();
    });
  }

  //Destroy Subscription to prevent memory leak
  ngOnDestroy() {
    if (this.submissionReportSubscription !== undefined) {
      this.submissionReportSubscription.unsubscribe();
    }
  }

  resetData() {
    this.warningReports = [];
    this.unsubmittedReports = [];
    this.approvedReports = [];
    this.licenseeService.selectedStatus = "";
  }

  //Get years
  getListOfYears() {
    this.licenseeService.getYears().subscribe(res => {
      this.yearList = res;
      this.licenseeService.reportingPeriodYears = this.yearList;
    });
  }

  //Set the current date
  setCurrentDate(month: number, year: number) {
    //Get current month and date for initial load
    this.currentDate.year = year;
    this.currentDate.month = month;
  }

  monthNumToName(monthNum: number) {
    return this.monthList[monthNum - 1] || "";
  }

  monthNameToNum(monthName: string) {
    var month = this.monthList.indexOf(monthName);
    return month + 1;
  }

  //Get the current date formatted for the pie chart header
  getFormattedDate() {
    return this.monthList[this.currentDate.month - 1] + " " + this.currentDate.year;
  }

  //Submitting month/year date form to call to DB for new data
  onDateSpinnerChange(month: string, year: number) {
   this.isDataAvail = false;
    var monthNumber = this.monthNameToNum(month);
    var yearNumber: number;

    if (typeof year === "string") {
      yearNumber = parseInt(year);
    } else {
      yearNumber = year;
    }
    console.log("monthNumber", monthNumber);
    console.log("yearNumber", yearNumber);
    this.setCurrentDate(monthNumber, yearNumber);
    this.licenseeService.selectedStatus = "";
    this.licenseeService.setSubmissionReportDate(this.currentDate);
  }

  divideStatuses() {
    this.resetData();
    if (this.allReports.length > 0) {
      for (var i = 0; i < this.allReports.length; i++) {
        if (this.allReports[i].reportingperiod.statusID === 1 || this.allReports[i].reportingperiod.statusID === 0) {
          this.unsubmittedReports.push(this.allReports[i]);
        } else if (this.allReports[i].reportingperiod.statusID === 2) {
          this.warningReports.push(this.allReports[i]);
        } else if (this.allReports[i].reportingperiod.statusID === 3) {
          this.approvedReports.push(this.allReports[i]);
        }
      }

      this.pieChartData = [this.approvedReports.length, this.warningReports.length, this.unsubmittedReports.length];
      this.isDataAvail = true;
      // console.log(this.pieChartData[0]);
      this.createChart(this.pieChartLabels, this.pieChartData, this.pieChartColors, this.pieChartTitle);
    } else {
      this.isDataAvail = false;
    }
  }

  createChart(legend: Array<string>, data: Array<number>, colors: Array<string>, title: string) {
    let chart = c3.generate({
      bindto: "#chart",
      data: {
        rows: [legend, data],
        type: "pie",
        onclick: (d, i) => {
          this.chartClick(d.id);
        }
      },
      pie: {
        label: {
          show: true
        }
      },
      size: {
        width: 400,
        height: 400
      },
      color: {
        pattern: colors
      },
      tooltip: {
        format: {
          value: function(value, ratio, id) {
            return value;
          }
        }
      }
    });
  }

  chartClick(status:string) {
    switch (status) {
      case "Approved":
        this.licenseeService.selectedStatus = "Approved";
        this.licenseeService.setSelectedSubmissionReport(this.approvedReports);
        break;
      case "Warnings":
        this.licenseeService.selectedStatus = "Warning";
        this.licenseeService.setSelectedSubmissionReport(this.warningReports);
        break;
      case "Unsubmitted":
        this.licenseeService.selectedStatus = "Unsubmitted";
        this.licenseeService.setSelectedSubmissionReport(this.unsubmittedReports);
        break;
    }
  }
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { LicenseeService } from "../services/licensee.service";
import { ReportingPeriod } from "@app/model/ReportingPeriod.model";
import { LicenseeSearch } from "@app/model/LicenseeSearch.model";
import { Summary } from "@app/model/Summary.model";
import { Subject, SubscriptionLike } from "rxjs";
import { debounceTime, count } from "rxjs/operators";
import { Attachments } from "@app/model/Attachments.model";
import { Router } from "@angular/router";
import { AuthenticationService } from '@app/core';
import {SubmissionStatusMessage} from '@app/model/SubmissionStatusMessage.model';
import {CfaSuccessMessageModalComponent} from '@app/cfa-success-message-modal/cfa-success-message-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

export class CurrencyConvert {
  
  /**
   * Converts a string number with syntax mistakes into a number object
   * @param numString String version of a number
   * @returns number. ex: 1234.56
   */
  getNumber(numString: any): number {
    return Number(this.getStrValue(numString));
  }

  /**
   * Convers a string number syntax mistakes into a currency string with a dollar symbol.
   * @param numString string version of a number
   * @returns currency string. ex: '$1,234.56'
   */
  getNumberString(numString: any): string {
    let strValue = this.getStrValue(numString);

    // Format strValue - add '$' and commas
    strValue = "$" + (strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","));

    return strValue;
  }

  private getStrValue(numString: any): string {
    // Format the number string to a properly formatted number and round to 2 decimal points
    let numValue = 0;
    numValue = numString.replace(/[^0-9.]+/g, '');
    if(numValue.toString().includes('.')) {
      let splitValues = numValue.toString().split('.');
      let decimals = "";
      for(let i = 1; (i < splitValues.length && i < 10); i++) {
        decimals = decimals.concat(splitValues[i]);
      }
      numValue = Number(splitValues[0].concat('.',decimals));
    }
    numValue = Math.round(numValue * 100) / 100;

    // Convert to string
    let strValue = numValue.toString();

    // Check if strValue has a decimal AND two decimal points afterwards. Add necessary 0's at the end
    if(!strValue.includes('.')) {
      strValue = strValue + ".00";
    } else {
      // Split the string at the dot.
      let splitValues = strValue.split(".");
      strValue = splitValues[0];
      let decimals = splitValues[1];

      while(decimals.length < 2) {
        decimals = decimals + "0";
      }
      strValue = strValue.concat('.', decimals.substring(0, 2));
    }

    return strValue;
  }
}

@Component({
  selector: "app-licensee",
  templateUrl: "./licensee.component.html",
  styleUrls: ["./licensee.component.scss"]
})
export class LicenseeComponent implements OnInit, OnDestroy {
  private licenseeSearchSubscription: SubscriptionLike;
  private _success = new Subject<string>();
  private _error = new Subject<string>();

  isLoading: boolean = true;
  isBtnLoading: boolean = false;
  isReadOnlyAdmin: Boolean = false;

  count = 0;

  //Licensee models
  reportingPeriod: ReportingPeriod;
  licenseeSummaryData: Summary;
  licenseeSearch = new LicenseeSearch();

  saveError: boolean = false;
  errorMessage: string;
  successMessage: string;
  isNewReportingPeriod = true;
  canEdit: boolean = true;
  saved: boolean = false;


  savedMessage: string = "Saved successfully.";
  failedMessage: string = "Saving new report failed. Please refresh the page and try again.";
  retryMessage: string = "An error has occurred. Please refresh the page and try again.";
  submitMessage: string = "Submitted for approval.";
  submitFailMessage: string = "Submitting new report failed. Please refresh the page and try again.";

  constructor(public licenseeService: LicenseeService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private modalService: NgbModal
              ) {  }

  ngOnInit() {
    if (this.licenseeService.licenseeInfo.licenseeId === 0 ||
      this.licenseeService.licenseeInfo.licenseeId === undefined) {
      this.router.navigate(['/cfa'], { replaceUrl: true });
    } else {
      this._success.subscribe(message => (this.successMessage = message));
      this._success.pipe(debounceTime(5000)).subscribe(() => (this.successMessage = null));

      this._error.subscribe(message => (this.errorMessage = message));
      this._error.pipe(debounceTime(5000)).subscribe(() => (this.errorMessage = null));

      this.licenseeService.getFeatureFlagStatus("ADDITIONAL_SALES_CHANNELS").subscribe((payload: any) => {
        if (payload.includes(this.licenseeService.licenseeInfo.cfaLocationNumber.toString())) {
          this.licenseeService.showAdditionalSalesChannels = true;
          this.licenseeService.monthlyGoodToSubmit = true;
        }
      })

      // Subscription to ReportingPeriod Observable in parent
      this.licenseeSearchSubscription = this.licenseeService.licenseeSearchObservable.subscribe(payload => {
        if (payload === null || payload.month === undefined || payload.year === undefined) {
          this.licenseeSearch.licenseeid = this.licenseeService.licenseeInfo.licenseeId;
          this.licenseeSearch.month = this.licenseeService.currentDate.month;
          this.licenseeSearch.year = this.licenseeService.currentDate.year;
        } else if (payload.licenseeid === undefined) {
          this.licenseeSearch.licenseeid = this.licenseeService.licenseeInfo.licenseeId;
        } else {
          this.licenseeSearch = payload;
        }
        this.updateData(this.licenseeSearch);
      });
    }
    this.licenseeService.adminReadOnlyObservable.subscribe(payload => {
      this.isReadOnlyAdmin = payload;
    })
  }

  //Destroy Subscription to prevent memory leak
  ngOnDestroy() {
    if (this.licenseeSearchSubscription !== undefined) {
      this.licenseeSearchSubscription.unsubscribe();
    }
  }

  updateData(licenseeSearch: LicenseeSearch) {
    this.count = 0;
    //Get all of the Licensee Daily sales data
    this.getReportingPeriod(licenseeSearch);
    // Get all of the Licensee summary data
    this.getSummaries(licenseeSearch);
    //Get attachment for the Licensee
    this.getLicenseeAttachments(licenseeSearch);
    //Get messages for the Licensee previous 3 month submissions
    this.getLicenseePreviousSubmissions(licenseeSearch);
  }

  getReportingPeriod(licenseeSearch: LicenseeSearch) {
    this.licenseeService.getData(licenseeSearch).subscribe((results: ReportingPeriod) => {
      this.isLoading = false;
      this.isBtnLoading = false;
      this.reportingPeriod = results;
      this.licenseeService.setReportingPeriod(results);
      this.licenseeService.reportingPeriod.isAdjustment = false;
      this.licenseeService.determineIfReportingPeriodLocked();
    });
  }

  showAdditionalSalesChannels():boolean {
    return this.licenseeService.showAdditionalSalesChannels;
  }

  getSummaries(licenseeSearch: LicenseeSearch) {
    this.licenseeService.getSummaries(licenseeSearch).subscribe((results: Summary) => {
      this.isLoading = false;
      this.licenseeSummaryData = results;
      this.licenseeService.setLicenseeSummariesData(results);
    });
  }

  getLicenseeAttachments(licenseeSearch: LicenseeSearch) {
    this.licenseeService.downloadAttachment(licenseeSearch).subscribe((results: Array<Attachments>) => {
      if(results.length > 0) {
        this.isLoading = false;
        this.licenseeService.setLicenseeAttachment(results);
      } else {
        this.licenseeService.setLicenseeAttachment(new Attachments());
      }
    });
  }

  getLicenseePreviousSubmissions(licenseeSearch: LicenseeSearch) {
    this.licenseeService.getMessages(licenseeSearch).subscribe((results: Array<SubmissionStatusMessage>) => {
      this.isLoading = false;
      results = results.filter(msg => msg.month !== 0);
      this.licenseeService.setLicenseeMessages(results);
    });
  }

  changeSuccessMessage(message: string) {
    this._success.next(`${message}`);
  }

  openSubmissionSuccessPopup(){
    const modalRef = this.modalService.open(CfaSuccessMessageModalComponent, {
      windowClass: 'cfa-success-message-modal'
    });
    modalRef.componentInstance.title = 'Submission Successful';
    modalRef.componentInstance.message = 'POS Monthly Sales Report has been submitted to the administrator for approval.';
  }

  changeErrorMessage(message: string) {
    this._error.next(`${message}`);
  }


  async saveReportingPeriod() {
    this.isBtnLoading = true;
    if (this.licenseeService.showAdditionalSalesChannels == true) {
      delete this.licenseeService.reportingPeriod.thirdPartySales;
      this.licenseeService.reportingPeriod.thirdPartySales = null;
    }

    if(this.licenseeService.checkReportingPeriodLicenseeId()) {
      try {
        if (this.licenseeService.reportingPeriod.reportingPeriodId === 0) {
          this.licenseeService.reportingPeriod.statusID = 1;

          let response = await this.licenseeService.postData(this.licenseeService.reportingPeriod).subscribe(
            data => {
              if (data > 0) {
                this.saved = true;
                console.log('POST Request is successful, your ReportingID is:' + data);
                this.licenseeService.reportingPeriod.reportingPeriodId = data as number;
                this.reportingPeriod = this.licenseeService.reportingPeriod;
                this.changeSuccessMessage(this.savedMessage);
                this.updateData(this.licenseeSearch);
              } else {
                this.saveError = true;
                this.isBtnLoading = false;
                this.changeErrorMessage(this.failedMessage);
                console.log("Response:", +data);
              }
            },
            error => {
              this.saveError = true;
              this.isBtnLoading = false;
              this.changeErrorMessage(this.retryMessage);
              console.log(error);
            }
          );
        } else {
          this.isBtnLoading = true;

          if(this.licenseeService.adminDeleteAttachment) {
            let response = this.licenseeService.deleteAttachment().subscribe();
          }

          let response = await this.licenseeService.updateData(this.licenseeService.reportingPeriod).subscribe(
            data => {
              this.saved = true;
              this.isLoading = false;
              this.changeSuccessMessage(this.savedMessage);
              this.updateData(this.licenseeSearch);
            },
            error => {
              this.saveError = true;
              this.isBtnLoading = false;
              this.changeErrorMessage(this.retryMessage);
              console.log(error);
            }
          );
        }
      } catch (error) {
        this.saveError = true;
        this.isBtnLoading = false;
        this.changeErrorMessage(this.retryMessage);
        console.log(error.stack);
      }
    } else {
      this.saveError = true;
      this.isBtnLoading = false;
      this.changeErrorMessage(this.retryMessage);
      console.error("There was an error where reportingPeriod.liencenseeID did not match up with dailySales.licenseeID");
    }
  }

  getButtonSaveStatus() {
    if (this.reportingPeriod !== undefined) {
      if (this.reportingPeriod.statusID === 2 ||
          this.reportingPeriod.statusID === 3) {
        return true;
      } else {
        return false;
      }
    }
  }

  isSubmitButtonDisabled() {
    if(this.licenseeService.reportingPeriodLocked 
    || this.isReadOnlyAdmin){
      return true;
    }
    
    if(this.authenticationService.credentials.isAdmin) {
      return false;
    }

    return !this.allGoodToSubmit();
  }


  allGoodToSubmit():boolean{
    return this.licenseeService.dailyGoodToSubmit &&
    this.monthlyGoodToSubmit() &&
    this.licenseeService.uploadGoodToSubmit &&
    this.licenseeService.contactGoodToSubmit &&
    this.licenseeService.directorGoodToSubmit &&
    this.licenseeService.confirmationGoodToSubmit &&
    !this.licenseeService.isMonthlySale75kWarning &&
    this.adjustmentGoodToSubmit()
  }

  monthlyGoodToSubmit():boolean {
    return this.licenseeService.monthlyGoodToSubmit || this.licenseeService.showAdditionalSalesChannels
  }

  adjustmentGoodToSubmit():boolean {
    return !(this.licenseeService.reportingPeriod?.isAdjustment 
    && !this.licenseeService.adJustmentGoodToSubmit);
  }

  async submitReportingPeriod() {
    this.isBtnLoading = true;
    if (this.licenseeService.showAdditionalSalesChannels == true) {
      delete this.licenseeService.reportingPeriod.thirdPartySales;
      this.licenseeService.reportingPeriod.thirdPartySales = null;
    }
    console.log("submitted report", this.licenseeService.reportingPeriod);
    if(this.licenseeService.checkReportingPeriodLicenseeId()) {
      // Submit data to DB
      try {
        if (this.licenseeService.reportingPeriod.reportingPeriodId === 0) {
          // Save data first
          this.licenseeService.reportingPeriod.statusID = 1;
          console.log("licenseeService.reportingPeriod", this.licenseeService.reportingPeriod);
          let response = await this.licenseeService.postData(this.licenseeService.reportingPeriod).subscribe(
            data => {
              if (data > 0) {
                this.saved = true;
                //Display alert for success
                this.changeSuccessMessage(this.savedMessage);
                this.licenseeService.reportingPeriod.reportingPeriodId = data as number;
                //Update submission status
                this.licenseeSearch.statusid = 2;
                this.licenseeService.postSubmissionStatus(this.licenseeSearch).subscribe(response => {
                  if (response && this.licenseeService.reportingPeriod.reportingPeriodId != 0) {
                    console.log("Submission Post:", response);
                    this.licenseeService
                      .postValidationIssues(this.licenseeService.reportingPeriod)
                      .subscribe(validations => {
                        console.log("Validations:", validations);
                      });
                    //Refresh page
                    this.updateData(this.licenseeSearch);
                  } else {
                    //Display alert for error
                    this.changeErrorMessage(this.failedMessage);
                    console.log("Response:", +data);
                  }
                });
              } else {
                this.saveError = true;
                this.isBtnLoading = false;
                //Display alert for error
                this.changeErrorMessage(this.failedMessage);
                console.log("Response:", +data);
              }
            },
            error => {
              this.saveError = true;
              this.isBtnLoading = false;
              this.changeErrorMessage(this.retryMessage);
              console.log(error);
            }
          );
        } else {
          this.isBtnLoading = true;

          if(this.licenseeService.adminDeleteAttachment) {
            let response = this.licenseeService.deleteAttachment().subscribe();
          }

          let response = await this.licenseeService.updateData(this.licenseeService.reportingPeriod).subscribe(
            data => {
              this.saved = true;
              this.isLoading = false;
              this.openSubmissionSuccessPopup();

              this.licenseeSearch.statusid = 2;
              this.licenseeService.postSubmissionStatus(this.licenseeSearch).subscribe(response => {
                if (response && this.licenseeService.reportingPeriod.reportingPeriodId != 0) {
                  console.log("Submission Post:", response);
                  this.licenseeService
                    .postValidationIssues(this.licenseeService.reportingPeriod)
                    .subscribe(validations => {
                      console.log("Validations:", validations);
                    });
                  //Refresh page
                  this.updateData(this.licenseeSearch);
                } else {
                  //Display alert for error
                  this.changeErrorMessage(this.failedMessage);
                  console.log("Response after submit only:", response);
                }
              });
            },
            error => {
              this.saveError = true;
              this.changeErrorMessage(this.retryMessage);
              console.log(error);
            }
          );
        }
      } catch (error) {
        this.saveError = true;
        this.changeErrorMessage(this.retryMessage);
        console.log(error);
      }
    } else {
      this.saveError = true;
      this.isBtnLoading = false;
      this.changeErrorMessage(this.retryMessage);
      console.error("There was an error where reportingPeriod.liencenseeID did not match up with dailySales.licenseeID");
    }
  }
}

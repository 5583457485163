<div data-testid="card-confirmation"  class='card'>
	<div class='card-body'>
		<div class='text-center'>
			<h5 class='text-danger'>Confirmation</h5>
		</div>

		<div class='container-90 text-center'>
			<p class="small">Please confirm before submitting.</p>
		</div>

    	<div class='confirmation-body text-center'>
			<div class="form-group row">
				<div class="col-sm-12 label-container">
					<label class="col-sm-12 form-label position-relative">
						<input data-testid="chkb-correct-month" class="confirmation-checkbox" [ngModel]="dateConfirmation" (ngModelChange)="dateConfirmationClicked($event)" type="checkbox"/>
						I have selected the correct month and year ({{ month }}, {{year}}) for my sales entries.
					</label>
				</div>

				<div class="col-sm-12 label-container">
					<label class="col-sm-12 form-label position-relative">
						<input data-testid="chkb-provided-partner" class="confirmation-checkbox" [ngModel]="partnerConfirmation" (ngModelChange)="partnerConfirmationClicked($event)" type="checkbox"/>
						I have provided my Partner (Aramark, Compass, Sodexo, etc. ) with the same sales amount.
					</label>
				</div>
			</div>

		</div>
	</div>
</div>

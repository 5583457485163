<ngb-alert
  class="success-alert"
  *ngIf="successMessage"
  type="success"
  (close)="successMessage = null"
  ><strong>Success!</strong> {{ successMessage }}</ngb-alert
>
<ngb-alert
  class="error-alert"
  *ngIf="errorMessage"
  type="danger"
  (close)="errorMessage = null"
  ><strong>Error!</strong> {{ errorMessage }}</ngb-alert
>

<div data-testid="sales-channels-card" class="card" id="sales-channels-card">
  <div class="card-body">
    <div class="text-center">
      <h5 data-testid="txt-sales-chan-header" class="text-danger">
        Monthly Breakdown of Sales Channels
      </h5>
    </div>
    <div class="text-center channels-subtitle">
      <h5 data-testid="txt-sales-chan-header">
        Add additional sales channels for Licensees to add to their reporting
        period
      </h5>
    </div>
    <div
      class="new-sales-chan container"
      data-testid="new-sales-chan-container"
    >
      <!-- Existing Sales Channels -->
      <div
        class="new-chan-form row"
        *ngFor="
          let chanItem of existingSalesChannels;
          let i = index;
          trackBy: trackByFn
        "
        data-testid="existing-chan-form-row"
      >
        <div class="add-remove col-sm-2" data-testid="new-add-remove-icons-col">
          <button
            class="add-icons"
            (click)="addItemLine()"
            data-testid="new-add-icon-span"
            [disabled]="isReadOnlyAdmin"
          >
            <i
              class="fas fa-regular fa-plus add-icon"
              data-testid="new-add-icon"
            ></i>
        </button>
        </div>
        <div class="col-sm-6" data-testid="new-chan-input-col">
          <input
            class="form-control new-input"
            type="text"
            [(ngModel)]="existingSalesChannels[i].name"
            [disabled]="true"
            #input
          />
        </div>
        <div class="col-sm-4 activate-buttons">
          <button
            class="btn btn-primary text-white activation-button"
            [ngClass]="{ 'btn-success': chanItem.isActive }"
            (click)="activateChannel(i)"
            [disabled]="isReadOnlyAdmin"
            data-testid="activate-button"
          >
            Activate
          </button>
          <button
            class="btn btn-primary text-white activation-button"
            [ngClass]="{ 'btn-danger': !chanItem.isActive }"
            (click)="deactivateChannel(i)"
            [disabled]="isReadOnlyAdmin"
            data-testid="deactivate-button"
          >
            Deactivate
          </button>
        </div>
      </div>

      <!-- New Sales Channels -->
      <div
        class="new-chan-form row"
        *ngFor="
          let newChanItem of newSalesChannels;
          let i = index;
          trackBy: trackByFn
        "
        data-testid="new-chan-form-row"
      >
        <div class="add-remove col-sm-2" data-testid="new-add-remove-icons-col">
          <span
            class="add-icons"
            (click)="removeItemLine(i)"
            data-testid="new-remove-icon-span"
          >
            <i
              class="fas fa-regular fa-minus minus-icon"
              data-testid="new-minus-icon"
            ></i>
          </span>
          <span
            class="add-icons"
            (click)="addItemLine()"
            data-testid="new-add-icon-span"
          >
            <i
              class="fas fa-regular fa-plus add-icon"
              data-testid="new-add-icon"
            ></i>
          </span>
        </div>
        <div class="col-sm-6" data-testid="new-chan-input-col">
          <input
            class="form-control new-input"
            type="text"
            [(ngModel)]="newSalesChannels[i]"
            (keydown)="onKeyDown($event, index)"
            data-testid="new-chan-input"
            #input
          />
        </div>
        <div class="col-sm-4 activate-buttons"></div>
      </div>
      <div class="form-buttons">
        <button
          class="btn btn-danger text-white btn-submit"
          data-testid="btn-submit-channel"
          (click)="submitNewSalesChannels()"
          [disabled]="getChannelButtonStatus()"
        >
          SUBMIT
        </button>
      </div>
    </div>
  </div>
</div>

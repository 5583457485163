<div class='card min-card-340'>
  <div class='card-body'>
    <div class='text-center'>
      <h5 data-testid="txt-export-to-oracle" class='text-danger'>Export to Oracle</h5>
    </div>

    <div class="export-container">
      <div class='export-margin container-95 text-center'>
        <p class="small">Administrator, click the ‘Export’ button below to download all locations’ sales data for the month to Oracle. Click 'Daily' to download the daily report.</p>
      </div>
      <div class="export-margin container-90 text-center">
        <button data-testid="btn-export-month" class="btn btn-danger btn-oracle text-white" (click)="downloadExportFile()" [disabled]="!isRevisedComplete">
          <app-btn-loader [isLoading]="!isExportComplete"></app-btn-loader>
          <i class="fas fa-download" [hidden]="!isExportComplete"></i>
          <span>EXPORT MONTH</span>
        </button>

        <button data-testid="btn-daily-export-to-oracle" class="btn btn-danger btn-oracle text-white" (click)="downloadDailyFile()" [disabled]="!isDailyComplete">
          <app-btn-loader [isLoading]="!isDailyComplete"></app-btn-loader>
          <i class="fas fa-download" [hidden]="!isDailyComplete"></i>
          <span>DAILY</span>
        </button>

        <hr>

        <div class='export-margin container-95 text-center'>
          <p class="small">Please select a date range.</p>
        </div>

        <div class="calendar-box">
          <p-calendar [style]="{'width':'100%'}" [inputStyle]="{'width':'calc(100% - 2.5em)'}" [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [showIcon]="true"></p-calendar>
        </div>

        <button data-testid="btn-export-adjust-oracle" class="btn btn-primary btn-adj text-white" (click)="downloadAdjFile()" 
          [disabled]="!isExportComplete || rangeDates == undefined || (rangeDates != undefined && (rangeDates.length !=2 || (rangeDates.length == 2 && rangeDates[1] == null)))">
          <app-btn-loader [isLoading]="!isRevisedComplete"></app-btn-loader>
          <i class="fas fa-download" [hidden]="!isRevisedComplete"></i>
          <span>EXPORT ADJUSTMENTS</span>
        </button>
      </div>
    </div>


  </div>
</div>

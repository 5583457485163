import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CfaRoutingModule } from './cfa-routing.module';

@NgModule({
  imports: [
    CommonModule,
    CfaRoutingModule
  ],
})
export class CfaModule { }

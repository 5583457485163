<div class="card" data-testid="masc-card">
  <div class="card-body" data-testid="masc-card-body">
    <div class="additional-sales" data-testid="additional-sales-container">
      <h5 class="text-center text-danger" data-testid="masc-header">Monthly Breakdown of Sales Channels</h5>
      <p class="small text-center" data-testid="masc-subtitle">
        If you have additional sales channels, select them from the drop down
        and enter the monthly total for each.
      </p>
      <div class="add-sales-channels container" data-testid="add-sales-chan-container">
        <div class="channel-form row" *ngFor="let channelItem of selectedSalesChannels | keyvalue" data-testid="channel-form-row">
          <div class="channel-item-container row" *ngIf="!channelsToDelete[channelItem.key]">
            <div class="add-remove col-sm-3" data-testid="add-remove-icons-col">
              <span class="add-icons" (click)="removeItemLine(channelItem.key)" data-testid="remove-icon-span">
                <i class="fas fa-regular fa-minus minus-icon" data-testid="minus-icon"></i>
              </span>
              <span class="add-icons" (click)="addItemLine()" data-testid="add-icon-span">
                <i class="fas fa-regular fa-plus add-icon" data-testid="add-icon"></i>
              </span>
            </div>
            <div class="col-sm-4" data-testid="sales-channel-col">
              <select
                data-testid="sales-channel"
                id="sales-channel"
                class="form-control form-select add-input"
                [(ngModel)]="channelItem.value.additionalSalesChannelId"
                (ngModelChange)="updateAdditionalSalesChannelData($event, channelItem.key);" 
              >
                <option *ngFor="let channel of channelList; let r = index" [value]="channel.id" class="channel-option"  data-testid="channel-option">
                  {{ channel.name }}
                </option>
              </select>
            </div>  
            <div class="col-sm-4" data-testid="chan-sales-amount-col">
              <input
                data-testid="chan-sales-amount-input"
                class="form-control add-input text-end"
                type="text"
                [ngModel]="channelItem.value.salesAmount | currency:currency.iso:'symbol-narrow':'1.2-2'" 
                (ngModelChange)="updateAdditionalSalesChannelData(null, null); set50kWarning($event, channelItem.key);"
                (input)="setCorrectCursorPlacement($event)" 
                (blur)="onBlur($event, channelItem.key)"
                (keydown)="onKeyDown($event, channelItem.key)"
                (keyup)="onBlur($event, channelItem.key)"
                #salesReference
                required ng-pattern="/^-?[0-9][^\.]*$/"
              />
              <button class="input-warning-indicator small"
                      trigger="manual"
                      #warningPopup="ngbPopover"
                      [autoClose]="false"
                      [ngClass]="{'d-none': (warning50kState[channelItem.key] !== WARNING_50K_STATES.MORE_THAN_50K)}"
                      placement="top"
                      [ngbPopover]="warningMessage"
                      popoverClass="warning-50k-popover"
                      >
                <div>
                  <i class="fas fa-exclamation-triangle"></i> <span>Warning</span>
                </div>
   
                <ng-template #warningMessage>
                  
                  <div class="warning-50k small">
                    <div class="message-container">
                      <div class="message">
                        <i class="fas fa-exclamation-triangle"></i>
                        Sales amount exceeds over $50k, are you sure you want to proceed?
                        <div class="action-button-container">
                          <button class="action-button no"  (click)="closeWarningPopupAndReset(currentWarningIndex)">
                            <i class="fas fa-times"></i> NO
                          </button>
                          <button class="action-button yes" (click)="closeWarningPopupAndIgnore(currentWarningIndex)">
                            <i class="fas fa-check"></i> YES
                          </button>
                        </div>
                      </div>
                      <button class="close-button">
                        <i class="fas fa-times" (click)="closeWarningPopup(currentWarningIndex)"></i>
                      </button>
                    </div>
                  </div>
                </ng-template>
              </button>
            </div>
            <div class="col-sm-1 validation-check text-left">
              <i data-testid="chk-third-party-success" 
                  [ngClass]="getValidationClass(selectedSalesChannels[channelItem.key].salesAmount, channelItem.key)" class="fas"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

export class AdditionalSalesChannel {
  id: number;
  name: string;
  isActive: boolean;
  modifiedTimestamp?: string;
  submittedTimestamp?: string;
}

export const defaultSalesChannel: AdditionalSalesChannel = {
id: 0,
isActive: false,
modifiedTimestamp: "",
name: "",
submittedTimestamp: "",
}
export class AdditionalSalesChannelDataInsertParams{

    additionalSalesChannelId: number;
    salesAmount: number;
    id?: number;
    reportingPeriodId?: number;
    salesChannelName?: string;
    submissionTimeStamp?: string;

    constructor(additionalSalesChannelId: number, salesAmount: number) {
        this.additionalSalesChannelId = additionalSalesChannelId;
        this.salesAmount = salesAmount;
    }
}

export const defaultSalesChannelData: AdditionalSalesChannelDataInsertParams = {
    additionalSalesChannelId: 0,
    salesAmount: 0,
}
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  AdditionalSalesChannel
} from "@app/model/AdditionalSalesChannel.model";
import { LicenseeService } from "@app/services/licensee.service";
import { cloneDeep } from "lodash";
import { debounceTime, Subject } from "rxjs";

@Component({
  selector: "app-cfa-channels",
  templateUrl: "./cfa-channels.component.html",
  styleUrls: ["./cfa-channels.component.scss"],
})
export class CfaChannelsComponent implements OnInit, OnDestroy {
  @Input()
  isLoading: boolean;
  @Input()
  isReadOnlyAdmin: boolean;

  // local variables
  newSalesChannels: Array<string> = [];
  existingSalesChannels: Array<AdditionalSalesChannel>;
  existingSalesChannelsReference: Array<AdditionalSalesChannel>;
  updatedSalesChannels: Array<AdditionalSalesChannel>;
  existingChannelNames: Array<string>;

  private _success = new Subject<string>();
  private _error = new Subject<string>();

  errorMessage: string;
  successMessage: string;

  @ViewChildren("input", { read: ElementRef }) inputs: QueryList<ElementRef>;

  constructor(
    public licenseeService: LicenseeService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._success.subscribe((message) => (this.successMessage = message));
    this._success
      .pipe(debounceTime(6000))
      .subscribe(() => (this.successMessage = null));

    this._error.subscribe((message) => (this.errorMessage = message));
    this._error
      .pipe(debounceTime(6000))
      .subscribe(() => (this.errorMessage = null));

    this.updatedSalesChannels = [];
    this.updateSalesChannelsLists();
  }

  ngOnDestroy(): void {}

  updateSalesChannelsLists() {
    this.licenseeService
      .getAdditionalSalesChannels()
      .subscribe((payload: Array<AdditionalSalesChannel>) => {
        if (payload.length) {
          payload.sort((a, b) => (a.id > b.id) ? 1 : -1);
          this.existingSalesChannels = cloneDeep(payload);
          this.existingSalesChannelsReference = cloneDeep(payload);
        } else {
          this.existingSalesChannels = [];
          this.existingSalesChannelsReference = [];
        }
        this.updatedSalesChannels = [];
      });
  }

  addItemLine() {
    this.newSalesChannels.push("");
    this.changeDetectorRef.detectChanges();
    this.inputs.last.nativeElement.focus();
  }

  removeItemLine(index: number) {
    if (this.newSalesChannels.length > 0) {
      this.newSalesChannels.splice(index, 1);
    }
  }

  onKeyDown(event: any, index: number) {
    if (event.key === "Enter") {
      this.addItemLine();
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  activateChannel(index: number) {
    this.existingSalesChannels[index].isActive = true;
    this.getUpdatedSalesChannels();
  }

  deactivateChannel(index: number) {
    this.existingSalesChannels[index].isActive = false;
    this.getUpdatedSalesChannels();
  }

  getUpdatedSalesChannels(): boolean {
    this.updatedSalesChannels = [];
    if (this.existingSalesChannels) {
      for (let i = 0; i < this.existingSalesChannels.length; i++) {
        if (
          this.existingSalesChannels[i].isActive !=
          this.existingSalesChannelsReference[i].isActive
        ) {
          this.updatedSalesChannels.push(this.existingSalesChannels[i]);
        }
      }
    }
    if (this.updatedSalesChannels && this.updatedSalesChannels.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getValidEntries() {
    const capitalNewSalesChannels = this.newSalesChannels.map(
      (channel) => (channel = this.capitalize(channel))
    );
    return capitalNewSalesChannels.filter(
      (channel) => channel != "" && typeof channel != "undefined"
    );
  }

  getChannelButtonStatus() {
    if (this.getValidEntries().length == 0 && !this.getUpdatedSalesChannels()) {
      return true;
    }
    return false;
  }

  submitNewSalesChannels() {
    const validEntries = this.getValidEntries();
    let isUpdateError = false;
    // Put Updated Activation Channels
    if (this.getUpdatedSalesChannels()) {
      this.updatedSalesChannels.forEach((channel) => {
        const updatedChannel = {
          id: channel.id,
          name: channel.name,
          isActive: channel.isActive,
        };
        this.licenseeService
          .updateAdditionalSalesChannel(updatedChannel)
          .subscribe({
            next: (res) => {
              this.changeSuccessMessage(
                "Channels active status adjusted successfuly."
              );
            },
            error: (error) => {
              console.error(error);
              this.changeErrorMessage(
                "There was an error adjusting the active status of these channels."
              );
              this.successMessage = null;
              isUpdateError = true;
            },
          });
      });
    }

    // Post New Channels
    if (validEntries.length > 0) {
      // Get existing sales channels
      this.licenseeService
        .getAdditionalSalesChannels()
        .subscribe((payload: Array<AdditionalSalesChannel>) => {
          // Check for duplicates
          this.existingChannelNames = payload.map((channel) => channel.name);
          const duplicateChannels = this.getDuplicates(
            this.existingChannelNames,
            validEntries
          );
          if (duplicateChannels.length > 0) {
            this.changeErrorMessage(
              `The sales channels: ${duplicateChannels
                .map((channel) => '"' + channel + '"')
                .join(", ")} already exist. No new channels were created.`
            );
          } else {
            // Post New Channels
            this.licenseeService
              .postAdditionalSalesChannels(this.getValidEntries())
              .subscribe((response) => {
                // Response is currently null
                this.changeSuccessMessage(
                  "New channels submitted successfuly."
                );
                this.resetNewSalesChannels();
                this.updateSalesChannelsLists();
              }),
              (error: any) => {
                console.error(error);
                this.changeErrorMessage(
                  "There was an error updating channels. Please refresh and try again."
                );
              };
          }
        });
    }
  }

  changeSuccessMessage(message: string) {
    this._success.next(`${message}`);
  }

  changeErrorMessage(message: string) {
    this._error.next(`${message}`);
  }

  resetNewSalesChannels() {
    this.newSalesChannels = [""];
  }

  capitalize(name: string) {
    if (name != "") {
      return name[0].toUpperCase() + name.slice(1);
    }
  }

  getDuplicates(channelList1: Array<string>, channelList2: Array<string>) {
    if (channelList1.length && channelList2.length) {
      const lowerList1 = channelList1.map((channel) => channel.toLowerCase());
      const lowerList2 = channelList2.map((channel) => channel.toLowerCase());
      const duplicates = lowerList1.filter((item) => lowerList2.includes(item));
      return duplicates.map((item) => this.capitalize(item));
    } else {
      return [];
    }
  }
}
